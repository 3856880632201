/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/jsx-props-no-spreading */
import { IconButton } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Control, UseFormSetValue, useController } from 'react-hook-form';
import { FiXCircle } from 'react-icons/fi';

import useFetch from '../../core/hooks/useFetch';
import useItemLister from '../../core/hooks/useItemLister';
import useModal from '../../core/hooks/useModal';
import { ImageInterface } from '../../core/models';
import ImageGrid from '../ImageGrid/ImageGrid';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

interface IProps {
  className?: string;
  setValue: UseFormSetValue<any>;
  control: Control<any, any>;
  path: string;
  required?: string | boolean;
}

function RHFImageField({ className, setValue, control, path, required }: IProps) {
  const openRef = useRef(null);
  const { field } = useController({
    name: path,
    control,
    rules: {
      required,
    },
  });
  const { value = '', ...rest } = field;

  const { modal, open, close, isOpen } = useModal();
  const { result, doFetch } = useFetch(`${BASE_URL}/image/${field.value}`);
  const [image, setImage] = useState<ImageInterface | null>(null);
  const { items, Lister, setIsActive } = useItemLister({
    endpointSingle: 'image',
    endpointPlural: 'images',
    filters: ['title', 'alt'],
  });

  useEffect(() => {
    setIsActive(isOpen);
  }, [isOpen]);

  const openModal = useCallback(() => {
    if (!openRef.current) return;
    open(openRef.current);
  }, []);

  useEffect(() => {
    if (!field.value) return setImage(null);
    doFetch();
  }, [field.value]);

  useEffect(() => {
    setImage(result?.data);
  }, [result]);

  const handleChange = useCallback(
    (id: string) => {
      setValue(path, id, { shouldDirty: true });
    },
    [path],
  );

  return (
    <div className={`${className || ''}`}>
      <button className="image-btn" type="button" ref={openRef} onClick={openModal}>
        {image ? (
          <img
            style={{
              width: '100px',
              height: '100px',
              objectFit: 'contain',
            }}
            src={`${BASE_URL}/uploads/${
              image.mimetype === 'image/svg+xml' ? image.uri : image.sizes?.thumb?.uri || image.uri
            }`}
            alt={image.alt}
          />
        ) : (
          'Choose an image'
        )}
      </button>
      {!image ? null : (
        <IconButton
          colorScheme="red"
          size="sm"
          aria-label="Delete image"
          fontSize={20}
          icon={<FiXCircle />}
          onClick={() => handleChange('')}
          mx={5}
        />
      )}
      {modal({
        children: (
          <ImageGrid callback={handleChange} closeModal={close} items={items} Lister={Lister} />
        ),
      })}
      <input type="text" value={value} readOnly hidden {...rest} />
    </div>
  );
}

export default RHFImageField;
