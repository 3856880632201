import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import MemberClassForm from '../../Components/MemberClassForm/MemberClassForm';

function NewMemberClass() {
  const navigate = useNavigate();

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        <h1 className="h3">Create a new class</h1>
        <Box my={10}>
          <MemberClassForm />
        </Box>
      </Box>
    </section>
  );
}

export default NewMemberClass;
