import { Button, Container } from '@chakra-ui/react';
import { useCallback } from 'react';

import CustomCheckbox from '../../common/CustomCheckbox/CustomCheckbox';
import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import ErrorMessage from '../../common/ErrorMessage/ErrorMessage';
import { useUserContext } from '../../core/context/user.context';
import { isValidEmail, isValidPassword } from '../../core/helpers';
import { useFormCheckbox } from '../../core/hooks/useFormCheckbox';
import { useFormInput } from '../../core/hooks/useFormInput';

function LoginForm() {
  const email = useFormInput('', (value) => isValidEmail(value));
  const password = useFormInput('', (value) => isValidPassword(value));
  const remember = useFormCheckbox(false);
  const { login, loginLoading, loginError } = useUserContext();

  const handleSubmit = useCallback(() => {
    login(email, password, remember);
  }, [email, password, remember]);

  return (
    <CustomForm onSubmit={handleSubmit} inputs={[email, password]}>
      <Container maxW="xl" my={8}>
        <CustomInput
          id="login-email"
          type="text"
          name="email"
          placeholder="Enter your email address"
          label="Email address"
          input={email}
          emptyMessage="Please fill in your email"
          invalidMessage="Please fill in a valid email address"
        />
        <CustomInput
          id="login-password"
          type="password"
          name="password"
          placeholder="Enter your password"
          label="Password"
          input={password}
          link={{ text: 'Forgot your password?', href: '/forgot-password' }}
          emptyMessage="Please fill in your password"
          invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
        />
        <CustomCheckbox id="remember" name="remember" label="Remember me" input={remember} />
        <Button
          isLoading={loginLoading}
          loadingText="Submitting"
          colorScheme="messenger"
          type="submit"
        >
          Log In
        </Button>
        <ErrorMessage error={loginError} />
      </Container>
    </CustomForm>
  );
}

export default LoginForm;
