/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import UserForm from '../../Components/UserForm/UserForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import InviteUser from '../InviteUser/InviteUser';

const USER_URL = `${process.env.REACT_APP_API_URL}/user`;

function User() {
  const { id } = useParams();
  const { user: currentUser } = useUserContext();
  const navigate = useNavigate();
  const {
    result,
    doFetch: fetchUser,
    loading,
    error: userError,
  } = useFetch(
    `${USER_URL}/${id}?populate=member_classes&populate=industries&populate=expertises&populate=community_roles&populate=time_slots&populate=life_questions&populate=pg_life_questions&populate=subscriptions`,
  );
  const user = useMemo(() => result?.data, [result]);
  if (user?.keywords) delete user.keywords;

  useEffect(() => {
    if (id === 'new') return;
    fetchUser({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : userError ? (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>No user with the id:{id} found!</AlertTitle>
          </Alert>
        ) : !user ? (
          <>
            <h1 className="h3">Invite a new user</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <InviteUser />
            </Box>
          </>
        ) : (
          <>
            <h1 className="h3">{user.email}</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <UserForm user={user} fetchUser={fetchUser} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default User;
