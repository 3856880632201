/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner, Tag } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const FORM = {
  endpoint: 'brainsnack',
  itemPath: 'brainsnack',
  backPath: '/playground?tab=3',
  schema: [
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
      required: true,
    },
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'body',
      type: 'pgMarkdown',
      label: 'Body',
      required: true,
    },
    {
      name: 'video_url',
      type: 'string',
      label: 'Video URL',
      required: false,
    },
  ],
};

function EditBrainsnack() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    doFetch: getBrainsnack,
    result,
    error,
    loading,
  } = useFetch(`${process.env.REACT_APP_API_URL}/brainsnack/${id}`);
  const brainsnack = useMemo(() => result?.data, [result]);

  const { token } = useUserContext();

  useEffect(() => {
    if (id === 'new') return;
    getBrainsnack({
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  }, [id]);

  const values = useMemo(() => {
    if (result) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { created, _id, updated, ...data } = result.data;
      return data;
    }
    return {};
  }, [result]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the brainsnack with id: {id}</AlertTitle>
          </Alert>
        ) : !brainsnack ? (
          <>
            <h1 className="h3">Create new Brainsnack</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm {...FORM} />
            </Box>
          </>
        ) : null}
        {!brainsnack ? null : (
          <>
            <h1 className="h3">{brainsnack.title}</h1>
            <Tag mt={2} colorScheme="facebook">
              {brainsnack._id}
            </Tag>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={values} id={brainsnack._id} fetchItem={getBrainsnack} {...FORM} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default EditBrainsnack;
