import { Box, Button, Heading } from '@chakra-ui/react';

import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import { isEmpty } from '../../core/helpers';
import { useFormInput } from '../../core/hooks/useFormInput';

interface IProps {
  onSubmit: (data: { text: string; via: string; related: string }) => void;
}

function TweetForm({ onSubmit }: IProps) {
  const text = useFormInput('', (value) => !isEmpty(value));
  const via = useFormInput('', (value) => !isEmpty(value));
  const related = useFormInput('', (value) => !isEmpty(value));

  const data = {
    text: text.value,
    via: via.value,
    related: related.value,
  };

  return (
    <Box p={5}>
      <Heading mb={5}>Add Tweet</Heading>
      <CustomForm onSubmit={() => onSubmit(data)} inputs={[text, via, related]}>
        <CustomInput
          input={text}
          type="textarea"
          label="Text"
          name="text"
          id="tweet-text"
          placeholder="Tweet text"
          emptyMessage="Field is required"
        />
        <CustomInput
          input={via}
          label="Via"
          name="via"
          id="via"
          placeholder="Via"
          emptyMessage="Field is required"
        />
        <CustomInput
          input={related}
          label="Related"
          name="related"
          id="related"
          placeholder="Related"
          emptyMessage="Field is required"
        />
        <Button colorScheme="messenger" type="submit">
          Add
        </Button>
      </CustomForm>
    </Box>
  );
}

export default TweetForm;
