/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, IconButton } from '@chakra-ui/react';

import CustomForm from '../../common/CustomForm/CustomForm';
import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { CustomInputInteface } from '../../core/models';

interface Props {
  item: any;
  children: React.ReactNode;
  updateLoading: boolean;
  createLoading: boolean;
  disabled?: boolean;
  inputs?: CustomInputInteface[];
  updateItem: () => void;
  createItem: () => void;
  publishItem: () => void;
  deleteItem: () => void;
  onClose: () => void;
  confirmDelete: () => void;
  deleteLoading: boolean;
  isOpen: boolean;
  withPublish?: boolean;
  noDelete?: boolean;
}

function FormContainer({
  item,
  children,
  updateLoading,
  createLoading,
  disabled,
  inputs,
  updateItem,
  createItem,
  publishItem,
  deleteItem,
  isOpen,
  onClose,
  confirmDelete,
  deleteLoading,
  withPublish,
  noDelete,
}: Props) {
  return (
    <>
      <CustomForm onSubmit={item ? updateItem : createItem} inputs={inputs || []}>
        <Box position="sticky" top="20px" zIndex={10} userSelect="none" pointerEvents="none">
          <Flex justifyContent="flex-end" mb={5} userSelect="none" pointerEvents="none">
            {!withPublish || !item ? null : (
              <Button
                colorScheme={item?.published ? 'messenger' : 'gray'}
                type="button"
                shadow="lg"
                isDisabled={updateLoading || createLoading || deleteLoading}
                isLoading={updateLoading || createLoading}
                onClick={publishItem}
                userSelect="auto"
                pointerEvents="all"
              >
                {item?.published
                  ? 'Published, click to unpublish'
                  : 'Unpublished, click to publish'}
              </Button>
            )}
            <Button
              colorScheme="messenger"
              type="submit"
              shadow="lg"
              ml={5}
              isDisabled={updateLoading || createLoading || disabled || deleteLoading}
              isLoading={updateLoading || createLoading}
              userSelect="auto"
              pointerEvents="all"
            >
              Save
            </Button>
          </Flex>
          {!item?.updated ? null : (
            <Flex justifyContent="flex-end" mb={5} userSelect="text" pointerEvents="all">
              Last updated:{' '}
              {new Date(item.updated).toLocaleString('en-gb', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Flex>
          )}
          {!item || noDelete ? null : (
            <Flex justifyContent="flex-end" mb={5} userSelect="none" pointerEvents="none">
              <IconButton
                colorScheme="red"
                size="sm"
                aria-label="Delete item"
                shadow="lg"
                icon={<DeleteIcon />}
                onClick={deleteItem}
                disabled={deleteLoading || updateLoading || createLoading}
                isLoading={deleteLoading}
                userSelect="auto"
                pointerEvents="all"
              />
            </Flex>
          )}
        </Box>
        {children}
      </CustomForm>
      <DeleteDialog isOpen={isOpen} onClose={onClose} confirmDelete={confirmDelete} />
    </>
  );
}

export default FormContainer;
