/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner, Tag } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const MODULE_URL = `${process.env.REACT_APP_API_URL}/pg_module`;

const FORM = {
  endpoint: 'pg_module',
  itemPath: 'module',
  backPath: '/playground',
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
      required: true,
    },
    {
      name: 'body',
      type: 'object',
      schema: [
        {
          name: 'backgroundImage',
          type: 'imgFileName',
          label: 'Background Image',
        },
        {
          name: 'nodeImage',
          type: 'imgFileName',
          label: 'Node Image',
        },
        {
          name: 'enabled',
          type: 'boolean',
          label: 'Enabled',
        },
        {
          name: 'locked',
          type: 'boolean',
          label: 'Locked',
        },
        {
          name: 'summary',
          type: 'textarea',
          label: 'Summary',
        },
        {
          name: 'summaryAudio',
          type: 'string',
          label: 'Summary Audio',
        },
        {
          name: 'unlockConditions',
          type: 'array',
          label: 'Unlock Conditions',
          schema: [
            {
              type: 'modulePart',
              required: true,
            },
            {
              name: 'timeSinceCompletionInHours',
              type: 'number',
              label: 'Time Since Completion In Hours',
            },
          ],
        },
        {
          name: 'parts',
          type: 'array',
          label: 'Parts',
          schema: [
            {
              name: 'id',
              type: 'string',
              label: 'ID',
              required: true,
            },
            {
              name: 'title',
              type: 'string',
              label: 'Title',
            },
            {
              name: 'nodeImage',
              label: 'Node Image',
              type: 'imgFileName',
            },
            {
              name: 'weight',
              type: 'number',
              label: 'Completion Weight',
            },
            {
              name: 'weightTotal',
              type: 'number',
              label: 'Weight in total requirement',
            },
            {
              name: 'optional',
              type: 'boolean',
              label: 'Optional stamp visual',
            },
            {
              name: 'assetType',
              type: 'select',
              label: 'Asset Type',
              required: true,
              options: [
                {
                  value: 'sequence',
                  label: 'Sequence',
                },
                {
                  value: 'brainsnack',
                  label: 'Brainsnack',
                },
              ],
            },
            {
              name: 'assetSlug',
              type: 'string',
              label: 'Asset Slug',
              required: true,
            },
            {
              name: 'tag',
              type: 'select',
              label: 'Tag',
              required: true,
              options: [
                {
                  value: 'solo',
                  label: 'Solo Mission',
                },
                {
                  value: 'social',
                  label: 'Social Mission',
                },
                {
                  value: 'read',
                  label: 'Read Brainsnack',
                },
                {
                  value: 'watch',
                  label: 'Watch Video',
                },
                {
                  value: 'mission',
                  label: 'Mission',
                },
                {
                  value: 'plan',
                  label: 'Plan Social Mission',
                },
              ],
            },
            {
              name: 'expectedTimeInMinutes',
              type: 'number',
              label: 'Expected Time In Minutes',
            },
            {
              name: 'iconId',
              type: 'string',
              label: 'Icon ID',
            },
            {
              name: 'positionX',
              type: 'number',
              label: 'Position X',
            },
            {
              name: 'positionY',
              type: 'number',
              label: 'Position Y',
            },
            {
              name: 'unlockConditions',
              type: 'array',
              label: 'Unlock Conditions',
              schema: [
                {
                  type: 'modulePart',
                  required: true,
                },
                {
                  name: 'onReservationCreated',
                  type: 'boolean',
                  label: 'Unlock when social mission reservation is created',
                },
                {
                  name: 'timeSinceCompletionInHours',
                  type: 'number',
                  label: 'Time Since Completion In Hours',
                },
              ],
            },
            {
              name: 'showConditions',
              type: 'array',
              label: 'Show Conditions',
              schema: [
                {
                  type: 'modulePart',
                  required: true,
                },
              ],
            },
            {
              name: 'connection',
              label: 'Connection',
              type: 'object',
              collapsible: true,
              schema: [
                {
                  name: 'modulePart',
                  label: 'module part',
                  type: 'modulePart',
                },
                // {
                //   name: 'modulePart',
                //   type: 'modulePart',
                // },
              ],
            },
            {
              name: 'forceUnlocked',
              type: 'boolean',
              label: 'Always show this mission as unlocked',
            },
            {
              name: 'hideWhenLocked',
              type: 'boolean',
              label: 'Hide When Locked',
            },
          ],
        },
      ],
    },
  ],
};

function Module() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUserContext();
  const { result, doFetch, loading, error } = useFetch(`${MODULE_URL}/${id}`);
  const module = useMemo(() => result?.data, [result]);

  useEffect(() => {
    if (id === 'new') return;
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  const values = useMemo(() => {
    if (module) {
      const { created, user_id, _id, updated, ...data } = module;
      return data;
    }
    return {};
  }, [module]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the module with id: {id}</AlertTitle>
          </Alert>
        ) : !module ? (
          <>
            <h1 className="h3">Create new module</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm {...FORM} />
            </Box>
          </>
        ) : null}
        {!module ? null : (
          <>
            <h1 className="h3">{module.title}</h1>
            <Tag mt={2} colorScheme="facebook">
              {module._id}
            </Tag>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={values} id={module._id} fetchItem={doFetch} {...FORM} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default Module;
