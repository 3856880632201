/* eslint-disable import/no-cycle */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Flex, Select } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import {
  Control,
  FormState,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  useFieldArray,
} from 'react-hook-form';

import ComponentItem from './ComponentItem';

interface IProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  unregister: UseFormUnregister<any>;
  field: any;
  getFieldState: UseFormGetFieldState<any>;
  getValues: UseFormGetValues<any>;
  formState: FormState<any>;
  setValue: UseFormSetValue<any>;
  path?: string;
  trigger: any;
}

function ComponentField({
  control,
  register,
  unregister,
  field,
  getFieldState,
  getValues,
  formState,
  setValue,
  path,
  trigger,
}: IProps) {
  const [selected, setSelected] = useState<any>('');
  const image = useMemo(() => {
    if (!selected) return null;
    const { img } = JSON.parse(selected);
    return img;
  }, [selected]);
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `${path}`,
  });

  const addComponent = useCallback(() => {
    if (!selected) return;
    const { name } = JSON.parse(selected);
    append({
      component: name,
    });
  }, [selected]);

  return (
    <div>
      <Select value={selected} onChange={(e) => setSelected(e.target.value)} my={5}>
        <option value="">Select a component</option>
        {field.components.map((el: any) => (
          <option value={JSON.stringify({ name: el.name, img: el.img })} key={el.name}>
            {el.label}
          </option>
        ))}
      </Select>
      <Flex flexWrap="wrap" alignItems="flex-start" gap={5}>
        <Button type="button" onClick={addComponent} colorScheme="messenger">
          Add
        </Button>
        {!image ? null : (
          <img src={image} alt={selected.name} style={{ width: '200px', marginLeft: 'auto' }} />
        )}
      </Flex>
      <Box
        bg="white"
        p={5}
        mt={5}
        _notLast={{
          mb: 10,
        }}
        borderRadius={6}
        boxShadow="0 0 8px rgba(0,0,0,0.2)"
        flex="1 1 auto"
      >
        {!fields.length ? (
          <div>No components added</div>
        ) : (
          <ul>
            {fields?.map((item: any, index) => (
              <ComponentItem
                item={item}
                index={index}
                fields={fields}
                field={field}
                move={move}
                remove={remove}
                control={control}
                register={register}
                unregister={unregister}
                formState={formState}
                setValue={setValue}
                getFieldState={getFieldState}
                getValues={getValues}
                path={path}
                key={item.id}
                trigger={trigger}
              />
            ))}
          </ul>
        )}
      </Box>
    </div>
  );
}

export default ComponentField;
