/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Heading,
  Icon,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Control, UseFormSetValue, useController } from 'react-hook-form';
import { FiChevronDown, FiXCircle } from 'react-icons/fi';

import TableHeader from '../../common/TableHeader/TableHeader';
import useFetch from '../../core/hooks/useFetch';
import useItemLister from '../../core/hooks/useItemLister';
import useModal from '../../core/hooks/useModal';

const MODULE_URL = `${process.env.REACT_APP_API_URL}/pg_module`;

interface IProps {
  className?: string;
  setValue: UseFormSetValue<any>;
  control: Control<any, any>;
  path: string;
  required?: string | boolean;
}

function ModulePartField({ className, setValue, control, path, required }: IProps) {
  const openRef = useRef(null);
  const newPath = useMemo(() => path.replace('.undefined', ''), [path]);
  const { field: moduleSlug } = useController({
    name: `${newPath}.moduleSlug`,
    control,
    rules: {
      required: required ? `${newPath}.moduleSlug` : false,
    },
  });
  const { field: modulePart } = useController({
    name: `${newPath}.partId`,
    control,
    rules: {
      required: required ? `${newPath}.partId` : false,
    },
  });
  const { value = '', ...rest } = moduleSlug;

  const { result, doFetch } = useFetch(`${MODULE_URL}?slug=${value}`);

  useEffect(() => {
    if (!value) return;
    doFetch();
  }, [value]);

  const options = useMemo(() => {
    if (!result?.data?.body?.parts || !value) return [];
    return result.data.body.parts
      .map((el: any) => el.id)
      .map((el: string) => (
        <option value={el} key={el}>
          {el}
        </option>
      ));
  }, [result, value]);

  const { modal, open, close, isOpen } = useModal();
  const [selected, setSelected] = useState<any>(null);
  const { items, Lister, setIsActive } = useItemLister({
    endpointSingle: 'pg_module',
    endpointPlural: 'pg_modules',
    filters: ['slug'],
  });

  useEffect(() => {
    setIsActive(isOpen);
  }, [isOpen]);

  const openModal = useCallback(() => {
    if (!openRef.current) return;
    open(openRef.current);
  }, []);

  useEffect(() => {
    if (!value) return setSelected(null);
    setSelected(value);
  }, [value]);

  const handleChange = useCallback(
    (slug: any) => {
      setValue(`${newPath}.partId`, '', { shouldDirty: true });
      setValue(`${newPath}.moduleSlug`, slug, { shouldDirty: true });
      close();
    },
    [newPath],
  );

  return (
    <>
      <Box
        _notLast={{
          mb: 5,
        }}
      >
        <Heading mb={1} fontSize={18} fontWeight={500}>
          Module Slug
        </Heading>
        <div className={`input__wrapper${className || ''}`}>
          <Button
            className="ref-button"
            type="button"
            ref={openRef}
            onClick={openModal}
            maxWidth={400}
            width="100%"
            justifyContent="flex-start"
          >
            <Text isTruncated lineHeight={1.5}>
              {selected?.label || selected || 'Choose a module'}
            </Text>
          </Button>
          {!selected ? null : (
            <IconButton
              colorScheme="red"
              size="sm"
              aria-label="Delete image"
              fontSize={20}
              icon={<FiXCircle />}
              onClick={() => {
                setValue(`${newPath}.partId`, '', { shouldDirty: true });
                setValue(`${newPath}.moduleSlug`, '', { shouldDirty: true });
              }}
              mx={5}
            />
          )}
          {modal({
            children: (
              <Box p={5}>
                {Lister({
                  children: (
                    <Box p={5}>
                      <TableContainer fontSize="1rem" boxShadow="xl">
                        <Table variant="striped" colorScheme="facebook">
                          <TableHeader
                            cols={[
                              {
                                label: 'Slug',
                              },
                            ]}
                          />
                          <Tbody>
                            {items?.map((item: any) => (
                              <Tr key={item._id}>
                                <Td p={2} verticalAlign="middle">
                                  <button
                                    style={{
                                      padding: '6px',
                                      maxWidth: '400px',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                    }}
                                    type="button"
                                    onClick={() => handleChange(item.slug)}
                                  >
                                    {item.slug}
                                  </button>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Box>
                  ),
                })}
              </Box>
            ),
          })}
          <input type="text" value={value} readOnly hidden {...rest} />
        </div>
      </Box>
      <Box
        _notLast={{
          mb: 5,
        }}
      >
        <Heading mb={1} fontSize={18} fontWeight={500}>
          Part ID
        </Heading>
        <div className="input__wrapper">
          <select className={`input__field input__field--select${className || ''}`} {...modulePart}>
            <option value="" key="placeholder">
              Choose Part ID
            </option>
            {options}
          </select>
          <Icon className="input__icon icon" as={FiChevronDown} />
        </div>
      </Box>
    </>
  );
}

export default ModulePartField;
