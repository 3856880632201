import { useCallback, useRef, useState } from 'react';

import Modal from '../../common/Modal/Modal';

interface Props {
  children: JSX.Element;
  side?: 'center' | 'right';
}

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openBtnRef = useRef<{ element: HTMLButtonElement | null }>({ element: null });
  const closeCallback = useRef({
    f: () => {},
  });

  const open = useCallback((ref: HTMLButtonElement) => {
    openBtnRef.current.element = ref;
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const modal = useCallback(
    ({ children, side }: Props) => (
      <Modal
        isOpen={isOpen}
        close={close}
        side={side}
        openBtn={openBtnRef.current.element}
        closeCallback={closeCallback}
      >
        {children}
      </Modal>
    ),

    [isOpen],
  );

  return { isOpen, open, close, modal, openBtnRef, closeCallback };
};

export default useModal;
