/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Control, UseFormSetValue, useController } from 'react-hook-form';
import { FiXCircle } from 'react-icons/fi';

import TableHeader from '../../common/TableHeader/TableHeader';
import useItemLister from '../../core/hooks/useItemLister';
import useModal from '../../core/hooks/useModal';

const ENTITY: any = {
  journey: {
    cols: [
      {
        label: 'Title',
      },
      {
        label: 'Slug',
      },
    ],
    filters: ['title', 'slug'],
    endpointSingle: 'journey',
    endpointPlural: 'journeys?populate=pg_modules',
    label: 'Choose an journey',
  },
  article: {
    cols: [
      {
        label: 'Title',
      },
      {
        label: 'Slug',
      },
    ],
    filters: ['title', 'slug'],
    endpointSingle: 'article',
    endpointPlural: 'articles',
    label: 'Choose an article',
  },
  case: {
    cols: [
      {
        label: 'Title',
      },
      {
        label: 'Slug',
      },
    ],
    filters: ['title', 'slug'],
    endpointSingle: 'case',
    endpointPlural: 'cases',
    label: 'Choose a case',
  },
  member: {
    cols: [
      {
        label: 'Name',
      },
    ],
    filters: ['name'],
    endpointSingle: 'users/member',
    endpointPlural: 'users/members',
    label: 'Choose a member',
  },
  user: {
    cols: [
      {
        label: 'Name',
      },
      {
        label: 'Email',
      },
    ],
    filters: ['name', 'email'],
    endpointSingle: 'user',
    endpointPlural: 'users',
    label: 'Choose a user',
  },
  worldUser: {
    cols: [
      {
        label: 'Name',
      },
      {
        label: 'Email',
      },
    ],
    filters: ['name', 'email'],
    endpointSingle: 'user',
    endpointPlural: 'users?roles=WORLD',
    label: 'Choose a WORLD user',
  },
  subscription: {
    cols: [
      {
        label: 'Name',
      },
      {
        label: 'Description',
      },
    ],
    filters: ['name', 'description'],
    endpointSingle: 'subscription',
    endpointPlural: 'subscriptions',
    label: 'Choose a subscription',
  },
  tag: {
    cols: [
      {
        label: 'Name',
      },
    ],
    filters: ['name'],
    endpointSingle: 'tag',
    endpointPlural: 'tags',
    label: 'Choose a category',
  },
  page: {
    cols: [
      {
        label: 'title',
      },
      {
        label: 'slug',
      },
    ],
    filters: ['title', 'slug'],
    endpointSingle: 'page',
    endpointPlural: 'pages',
    label: 'Choose the parent page',
  },
  module: {
    cols: [
      {
        label: 'Slug',
      },
    ],
    filters: ['slug'],
    endpointSingle: 'pg_module',
    endpointPlural: 'pg_modules',
    label: 'Choose a module',
  },
  sequence: {
    cols: [
      {
        label: 'Slug',
      },
    ],
    filters: ['slug'],
    endpointSingle: 'pg_sequence',
    endpointPlural: 'pg_sequences?type=social',
    label: 'Choose a sequence',
  },
  sequence_all: {
    cols: [
      {
        label: 'Slug',
      },
    ],
    filters: ['slug'],
    endpointSingle: 'pg_sequence',
    endpointPlural: 'pg_sequences',
    label: 'Choose a sequence',
  },
};

interface IProps {
  entity: string;
  className?: string;
  setValue: UseFormSetValue<any>;
  control: Control<any, any>;
  field: any;
  path: string;
  required?: string | boolean;
  trigger?: any;
  style?: any;
}

function RHFRefField({
  entity,
  className,
  setValue,
  field: schemaField,
  control,
  path,
  required,
  trigger,
  style = {},
}: IProps) {
  const openRef = useRef(null);
  const { field }: any = useController({
    name: path,
    control,
    rules: {
      required,
    },
  });
  const { value = '', ...rest } = field;

  const { modal, open, close, isOpen } = useModal();
  const [selected, setSelected] = useState<any>(null);
  const { items, Lister, setIsActive, changeSearch } = useItemLister({
    endpointSingle: ENTITY[entity].endpointSingle,
    endpointPlural: ENTITY[entity].endpointPlural + (schemaField?.query || ''),
    filters: ENTITY[entity].filters,
  });

  useEffect(() => {
    setIsActive(isOpen);
    changeSearch('');
  }, [isOpen]);

  const openModal = useCallback(() => {
    if (!openRef.current) return;
    open(openRef.current);
  }, []);

  useEffect(() => {
    if (!field.value) return setSelected(null);
    setSelected(field.value);
  }, [field.value]);

  const handleChange = useCallback(
    (item: any) => {
      if (schemaField.changing) {
        schemaField.changing.forEach((el: string) => {
          trigger(`${path.replace(`.${schemaField.name}`, '')}.${el}`);
        });
      }
      setValue(path, item, { shouldDirty: true });
      close();
    },
    [path],
  );

  return (
    <div style={style} className={`${className || ''}`}>
      <Button
        type="button"
        ref={openRef}
        onClick={openModal}
        maxWidth={400}
        width="100%"
        justifyContent="flex-start"
      >
        <Text isTruncated lineHeight={1.5}>
          {entity === 'module' ||
          entity === 'sequence' ||
          entity === 'sequence_all' ||
          entity === 'page'
            ? selected || ENTITY[entity].label
            : entity === 'journey'
            ? selected?.journey
            : selected?.label || ENTITY[entity].label}
        </Text>
      </Button>
      {!selected ? null : (
        <IconButton
          colorScheme="red"
          size="sm"
          aria-label="Delete image"
          fontSize={20}
          icon={<FiXCircle />}
          onClick={() => setValue(path, '', { shouldDirty: true })}
          mx={5}
        />
      )}
      {modal({
        children: (
          <Box p={5}>
            {Lister({
              children: (
                <Box p={5}>
                  <TableContainer fontSize="1rem" boxShadow="xl">
                    <Table variant="striped" colorScheme="facebook">
                      <TableHeader cols={ENTITY[entity].cols} />
                      <Tbody>
                        {items?.map((item: any) => (
                          <Tr key={item._id}>
                            <Td p={2} verticalAlign="middle">
                              <button
                                style={{
                                  padding: '6px',
                                  maxWidth: '400px',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                type="button"
                                onClick={() =>
                                  handleChange(
                                    entity === 'article'
                                      ? {
                                          label: item.title,
                                          article: item._id,
                                        }
                                      : entity === 'subscription'
                                      ? {
                                          label: item.name,
                                          subscription: item._id,
                                        }
                                      : entity === 'case'
                                      ? {
                                          label: item.title,
                                          case: item._id,
                                        }
                                      : entity === 'member' ||
                                        entity === 'user' ||
                                        entity === 'worldUser'
                                      ? {
                                          label: item.name,
                                          user_id: item._id,
                                        }
                                      : entity === 'tag'
                                      ? {
                                          label: item.name,
                                          tag: item.name,
                                        }
                                      : entity === 'module' ||
                                        entity === 'sequence' ||
                                        entity === 'sequence_all' ||
                                        entity === 'page'
                                      ? item.slug
                                      : entity === 'journey'
                                      ? {
                                          journey: item.slug,
                                          pg_modules: item.pg_modules,
                                        }
                                      : '',
                                  )
                                }
                              >
                                {entity === 'article' || entity === 'case' || entity === 'page'
                                  ? item.title
                                  : entity === 'journey'
                                  ? item.title
                                  : entity === 'member' ||
                                    entity === 'user' ||
                                    entity === 'worldUser' ||
                                    entity === 'subscription'
                                  ? item.name
                                  : entity === 'tag'
                                  ? item.name
                                  : entity === 'module' ||
                                    entity === 'sequence' ||
                                    entity === 'sequence_all'
                                  ? item.slug
                                  : ''}
                              </button>
                            </Td>
                            {entity !== 'user' && entity !== 'worldUser' ? null : (
                              <Td p={2} verticalAlign="middle">
                                <button
                                  style={{
                                    padding: '6px',
                                    maxWidth: '400px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                  }}
                                  type="button"
                                  onClick={() =>
                                    handleChange({
                                      label: item.name,
                                      user_id: item._id,
                                    })
                                  }
                                >
                                  {item.email}
                                </button>
                              </Td>
                            )}
                            {entity !== 'subscription' ? null : (
                              <Td p={2} verticalAlign="middle">
                                {item.description}
                              </Td>
                            )}
                            {entity !== 'article' &&
                            entity !== 'case' &&
                            entity !== 'subscription' &&
                            entity !== 'journey' &&
                            entity !== 'page' ? null : (
                              <Td p={2} verticalAlign="middle">
                                <button
                                  style={{
                                    padding: '6px',
                                    maxWidth: '400px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                  }}
                                  type="button"
                                  onClick={() =>
                                    handleChange(
                                      entity === 'article'
                                        ? {
                                            label: item.title,
                                            article: item._id,
                                          }
                                        : entity === 'journey'
                                        ? {
                                            journey: item.slug,
                                            pg_modules: item.pg_modules,
                                          }
                                        : entity === 'page'
                                        ? item.slug
                                        : {
                                            label: item.title,
                                            case: item._id,
                                          },
                                    )
                                  }
                                >
                                  {item.slug}
                                </button>
                              </Td>
                            )}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              ),
            })}
          </Box>
        ),
      })}
      <input type="text" value={value} readOnly hidden {...rest} />
    </div>
  );
}

export default RHFRefField;
