/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner, Tag } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { CCasesShowcase, CContact, CMarkdown, CResult } from '../../core/components';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const FORM = {
  endpoint: 'case',
  itemPath: 'case',
  backPath: '/content?tab=12',
  withPublish: true,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
      required: true,
    },
    {
      name: 'img',
      type: 'img',
      label: 'Image',
    },
    {
      name: 'summary',
      type: 'textarea',
      label: 'Summary',
    },
    {
      name: 'body',
      type: 'markdown',
      label: 'Body',
    },
    {
      name: 'tags',
      type: 'tagsField',
      label: 'Tags',
      endpointSingle: 'tag',
      endpointPlural: 'tags',
      placeHolder: 'Select tags',
    },
    {
      name: 'page_builder',
      type: 'component',
      label: 'Page Builder',
      components: [CResult, CMarkdown, CCasesShowcase, CContact],
    },
    {
      name: 'seo',
      type: 'object',
      label: 'SEO',
      schema: [
        {
          name: 'title',
          type: 'string',
          label: 'Title',
        },
        {
          name: 'description',
          type: 'textarea',
          label: 'Description',
        },
      ],
    },
  ],
};

function EditCase() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    doFetch: getCase,
    result,
    error,
    loading,
  } = useFetch(`${process.env.REACT_APP_API_URL}/case/${id}?populate=tags`);
  const item = useMemo(() => result?.data, [result]);

  const { token } = useUserContext();

  useEffect(() => {
    if (id === 'new') return;
    getCase({
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  }, [id]);

  const values = useMemo(() => {
    if (result) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { created, _id, updated, ...data } = result.data;
      return data;
    }
    return {};
  }, [result]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the case with id: {id}</AlertTitle>
          </Alert>
        ) : !item ? (
          <>
            <h1 className="h3">Create new Case</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm {...FORM} />
            </Box>
          </>
        ) : null}
        {!item ? null : (
          <>
            <h1 className="h3">{item.title}</h1>
            <Tag mt={2} colorScheme="facebook">
              {item._id}
            </Tag>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={values} id={item._id} fetchItem={getCase} {...FORM} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default EditCase;
