import { createContext, useContext, useLayoutEffect, useState } from 'react';

import { BREAKPOINT } from '../constants';
import { debounce } from '../helpers';

interface ScreenSize {
  width: number;
  height: number;
  isMobile: boolean;
}

interface Props {
  children: JSX.Element;
}

const initialValue: ScreenSize = {
  width: 0,
  height: 0,
  isMobile: window.innerWidth < BREAKPOINT,
};

const ScreenSizeContext = createContext<ScreenSize>(initialValue);

export const useScreenSizeContext = () => useContext(ScreenSizeContext);

export function ScreenSizeContextProvider({ children }: Props) {
  const [size, setSize] = useState<ScreenSize>(initialValue);

  useLayoutEffect(() => {
    const handleResize = debounce(() => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < BREAKPOINT,
      });
    }, 200);
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    if (size.width > 0) {
      document.documentElement.style.setProperty('--vw', `${size.width * 0.01}px`);
      document.documentElement.style.setProperty('--vh', `${size.height * 0.01}px`);
    }
  }, [size]);

  return <ScreenSizeContext.Provider value={size}>{children}</ScreenSizeContext.Provider>;
}
