/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Tag } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const FORM = {
  endpoint: 'time_slot',
  itemPath: 'time_slot',
  backPath: '/playground?tab=4',
  schema: [
    {
      name: 'slot',
      type: 'time-slot',
      label: 'Time slot',
      required: true,
    },
  ],
};

function EditTimeSlot() {
  const params = useParams();
  const navigate = useNavigate();
  const {
    doFetch: getTimeSlot,
    result,
    error,
  } = useFetch(`${process.env.REACT_APP_API_URL}/time_slot/${params.id}`);

  const { token } = useUserContext();

  useEffect(() => {
    getTimeSlot({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  }, []);

  useEffect(() => {
    if (error) {
      navigate('/playground?tab=4');
    }
  }, [error]);

  const values = useMemo(() => {
    if (result) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { created, _id, updated, ...data } = result.data;
      if (Object.keys(data).includes('slot')) {
        const split = data.slot.split(' ');
        data.day = split[0];
        data.from = split[1];
        data.to = split[3];
        data.slot = undefined;
      }
      return data;
    }
    return {};
  }, [result]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {result && (
          <>
            <h1 className="h3">Edit time slot</h1>
            <Tag mt={2} colorScheme="facebook">
              {result.data._id}
            </Tag>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={values} id={result.data._id} fetchItem={getTimeSlot} {...FORM} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default EditTimeSlot;
