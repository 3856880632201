/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import useFetch from '../hooks/useFetch';
import { User } from '../models';
import { CustomCheckboxInteface, CustomInputInteface } from '../models/inputs';

interface UserInterface {
  user: User;
  loginLoading: boolean;
  loginError: any;
  login: (
    email: CustomInputInteface,
    password: CustomInputInteface,
    remember: CustomCheckboxInteface,
  ) => void;
  token: string;
  logout: () => void;
  getUser: () => void;
  isAdmin: boolean;
  updateToken: (token: string) => void;
}

const initialValue = {
  user: {
    email: '',
    token: '',
    role: 0,
    _id: '',
  },
  loginLoading: false,
  loginError: '',
  token: '',
  login() {},
  logout() {},
  getUser() {},
  isAdmin: false,
  updateToken() {},
};

const UserContext = createContext<UserInterface>(initialValue);

export const useUserContext = () => useContext(UserContext);

interface Props {
  children: JSX.Element;
}

const BASE_URL = process.env.REACT_APP_API_URL;
const AUTH_TOKEN = 'authenticationToken';

export function UserContextProvider({ children }: Props) {
  const {
    loading: loginLoading,
    doFetch: doLoginFetch,
    error: loginError,
  } = useFetch(`${BASE_URL}/user/login`);
  const { doFetch: doUserFetch, error: userError } = useFetch(
    `${BASE_URL}/user?populate=member_classes&populate=industries&populate=expertises&populate=community_roles&populate=time_slots&populate=life_questions`,
  );
  const { doFetch: doLogoutFetch } = useFetch(`${BASE_URL}/user/logout`);
  const [authToken, setAuthToken] = useState(() => {
    const localToken = localStorage.getItem(AUTH_TOKEN);
    const sessionToken = sessionStorage.getItem(AUTH_TOKEN);

    if (!localToken && !sessionToken) return '';
    if (localToken) return JSON.parse(localToken);
    if (sessionToken) return JSON.parse(sessionToken);
  });
  const [user, setUser] = useState<User>({
    email: '',
    token: authToken,
    role: 0,
    _id: '',
  });
  const isAdmin = useMemo(() => user.roles?.includes('CMS') || false, [user]);

  const setSessionStorageToken = useCallback((token: string) => {
    sessionStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
    setAuthToken(token);
  }, []);

  const setLocalSessionToken = useCallback((token: string) => {
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
    setAuthToken(token);
  }, []);

  const removeAuthToken = useCallback(() => {
    sessionStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_TOKEN);
    setUser({
      email: '',
      token: '',
      role: 0,
      _id: '',
    });
  }, []);

  const getUser = useCallback(async () => {
    const res = await doUserFetch({
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (res?.data) {
      setUser({ ...res.data, token: authToken });
    }
  }, [authToken]);

  useEffect(() => {
    if (!authToken) return;
    getUser();
  }, [authToken]);

  const login = useCallback(
    async (
      email: CustomInputInteface,
      password: CustomInputInteface,
      remember: CustomCheckboxInteface,
    ) => {
      const res = await doLoginFetch({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email.value,
          password: password.value,
          source: 'CMS',
        }),
      });
      if (res?.data.token) {
        if (remember.checked) {
          setLocalSessionToken(res.data.token);
        } else {
          setSessionStorageToken(res.data.token);
        }
      }
    },
    [],
  );

  const logout = useCallback(async () => {
    if (authToken) {
      doLogoutFetch({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      removeAuthToken();
      setUser(initialValue.user);
    }
  }, [authToken]);

  useEffect(() => {
    if (!userError) return;
    logout();
  }, [userError]);

  const updateToken = (token: string) => {
    const localToken = localStorage.getItem(AUTH_TOKEN);
    if (localToken) {
      return setLocalSessionToken(token);
    }
    const sessionToken = sessionStorage.getItem(AUTH_TOKEN);
    if (sessionToken) {
      return setSessionStorageToken(token);
    }
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      loginLoading,
      loginError,
      userError,
      isAdmin,
      getUser,
      updateToken,
      token: authToken,
    }),
    [user, loginError, loginLoading],
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
