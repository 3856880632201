/* eslint-disable react/jsx-props-no-spreading */
import { Alert, AlertIcon, AlertTitle, Box, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';

import {
  CAnthropicAI,
  CArticleShowcase,
  CCasesShowcase,
  CContact,
  CEvents,
  CHomeBanner,
  CImage,
  CIntroduction,
  CMindstudio,
  COpenAI,
  CPartnersSlider,
  CPrograms,
  CPromo,
  CQuoteSlider,
  CSimpleSlider,
} from '../../core/components';
import useFetch from '../../core/hooks/useFetch';
import RHForm from '../RHForm/RHForm';

const HOME_PAGE_URL = `${process.env.REACT_APP_API_URL}/page?slug=home-page`;

function HomePageForm() {
  const { result, doFetch, loading, error } = useFetch(`${HOME_PAGE_URL}`);

  const FORM = useMemo(
    () => ({
      endpoint: 'page',
      id: result?.data._id,
      backPath: '/website',
      schema: [
        {
          name: 'body',
          type: 'object',
          schema: [
            CHomeBanner,
            {
              name: 'pageBuilder',
              type: 'component',
              label: 'Page Builder',
              components: [
                CIntroduction,
                CMindstudio,
                COpenAI,
                CAnthropicAI,
                CSimpleSlider,
                CPrograms,
                CCasesShowcase,
                CArticleShowcase,
                CPartnersSlider,
                CContact,
                CQuoteSlider,
                CPromo,
                CImage,
                CEvents,
              ],
            },
          ],
        },
      ],
    }),
    [result],
  );

  useEffect(() => {
    doFetch();
  }, []);

  return (
    <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
      {!loading ? null : <Spinner />}
      {!error ? null : (
        <Alert status="error" my={10}>
          <AlertIcon />
          <AlertTitle>Could not load home page data</AlertTitle>
        </Alert>
      )}
      {!result ? null : (
        <RHForm
          itemValues={{ body: result.data?.body }}
          updated={result.data?.updated}
          fetchItem={doFetch}
          noDelete
          {...FORM}
        />
      )}
    </Box>
  );
}

export default HomePageForm;
