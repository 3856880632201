/* eslint-disable react/jsx-props-no-spreading */
import RHForm from '../../Components/RHForm/RHForm';
import countries from '../../assets/country-list.json';

const COUNTRY_LIST = countries.map((el: { code: string; name: string }) => ({
  value: el.code,
  label: el.name,
}));

const FORM = {
  endpoint: 'users/members',
  itemPath: 'profile',
  backPath: '/content?tab=2',
  pureEndpoint: true,
  schema: [
    {
      name: 'firstname',
      type: 'string',
      label: 'First name',
      required: true,
    },
    {
      name: 'lastname',
      type: 'string',
      label: 'Last name',
      required: true,
    },
    {
      name: 'country_code',
      type: 'select',
      label: 'Country',
      options: COUNTRY_LIST,
    },
    {
      name: 'pronouns',
      type: 'select',
      label: 'Pronouns',
      options: [
        { value: 'She/her/hers', label: 'She/her/hers' },
        { value: 'He/him/his', label: 'He/him/his' },
        { value: 'They/them/theirs', label: 'They/them/theirs' },
        { value: 'Other', label: 'Other' },
      ],
    },
    {
      name: 'title',
      type: 'string',
      label: 'Title',
    },
    {
      name: 'company.name',
      type: 'string',
      label: 'Company name',
    },
    {
      name: 'company.url',
      type: 'string',
      label: 'Company url',
    },
    {
      name: 'industries',
      type: 'tagsField',
      label: 'Industries',
      endpointSingle: 'industry',
      endpointPlural: 'industries',
      placeHolder: 'Select industries',
    },
    {
      name: 'expertises',
      type: 'tagsField',
      label: 'Expertises',
      endpointSingle: 'expertise',
      endpointPlural: 'expertises',
      placeHolder: 'Select expertises',
    },
    {
      name: 'social.linkedin',
      type: 'string',
      label: 'LinkedIn profile',
    },
    {
      name: 'bio',
      type: 'textarea',
      label: 'Bio',
    },
    {
      name: 'world_private',
      type: 'boolean',
      label: 'WORLD Private',
    },
    {
      name: 'platform_private',
      type: 'boolean',
      label: 'Platform Private',
    },
  ],
};

function NewMember() {
  return <RHForm {...FORM} />;
}

export default NewMember;
