import { useEffect, useRef, useState } from 'react';

import { isEmpty as isValueEmpty } from '../helpers';
import { CustomInputInteface } from '../models/inputs';

export const useFormInput = (
  initialValue: any,
  validate: (newValue: any) => boolean = () => true,
): CustomInputInteface => {
  const [value, setValue] = useState<any>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(validate(value));
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const ref = useRef(null);

  useEffect(() => {
    setValue(initialValue);
    setShowError(false);
    setIsEmpty(isValueEmpty(initialValue));
    setIsValid(validate(initialValue));
  }, [initialValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setShowError(false);
    const newValue = e.target.value;
    setValue(newValue);
    setIsEmpty(isValueEmpty(newValue));
    setIsValid(validate(newValue));
  };

  return { value, onChange, isValid, isEmpty, showError, setShowError, setValue, ref };
};
