/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Spinner,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FiDownload, FiFilter, FiSearch } from 'react-icons/fi';

import CustomCheckbox from '../../common/CustomCheckbox/CustomCheckbox';
import Pager from '../../common/Pager/Pager';
import { useFormCheckbox } from '../../core/hooks/useFormCheckbox';

interface Props {
  children: JSX.Element;
  items: any;
  loading: boolean;
  error: Error;
  isOpen: boolean;
  search: string;
  page: string;
  total: number;
  removed?: number;
  active?: number;
  pager: {
    page: number;
    total: number;
  };
  withDownload?: boolean;
  changeSearch: (value: string) => void;
  closeModal: () => void;
  confirmDelete: () => void;
  handleDownload: () => void;
  filters?: string[];
  filterInputs?: boolean;
  minHeight?: number;
  onFilter?: (onlyExisting: boolean) => void;
  showTotal?: boolean;
}

function ItemLister({
  children,
  items,
  loading,
  error,
  isOpen,
  search,
  page,
  pager,
  withDownload,
  changeSearch,
  closeModal,
  confirmDelete,
  handleDownload,
  total,
  removed,
  active,
  filters,
  minHeight = 726,
  filterInputs,
  showTotal = true,
  onFilter = () => {},
}: Props) {
  const cancelRef = useRef(null);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const hideRemovedProfile = useFormCheckbox(false);

  useEffect(() => {
    onFilter(hideRemovedProfile.checked);
  }, [hideRemovedProfile]);

  return (
    <Box style={{ minHeight: `${minHeight}px` }}>
      <Flex alignItems="center" justifyContent="space-between">
        {!filters ? null : (
          <InputGroup alignItems="center" my={5} width={300}>
            <Input
              placeholder="Search"
              value={search}
              onChange={(e) => changeSearch(e.target.value)}
            />
            <InputRightAddon>
              <FiSearch />
            </InputRightAddon>
          </InputGroup>
        )}
        {filterInputs && (
          <Tooltip label="Filters">
            <IconButton
              colorScheme="messenger"
              size="sm"
              aria-label="Filters"
              ml={4}
              mr={4}
              icon={<FiFilter />}
              onClick={() => setFilterOpen((pre: boolean) => !pre)}
            />
          </Tooltip>
        )}
        {filterOpen && (
          <CustomCheckbox
            label="Hide removed users"
            name="hide-removed-users"
            id="hide-removed-users"
            input={hideRemovedProfile}
            style={{
              flexShrink: 0,
              marginBottom: 7,
            }}
          />
        )}
        <Flex ml="auto" gap={active || removed ? 3 : 0}>
          {!withDownload ? null : (
            <Tooltip label="Download CSV">
              <IconButton
                colorScheme="messenger"
                size="sm"
                ml="auto"
                mr={5}
                aria-label="Download CSV"
                icon={<FiDownload />}
                onClick={handleDownload}
              />
            </Tooltip>
          )}
          {showTotal && <div>Total: {total}</div>}
          {active && <div>Active: {active}</div>}
          {removed && <div>Removed: {removed}</div>}
        </Flex>
      </Flex>
      {loading ? (
        <Spinner my={5} />
      ) : error ? (
        <Alert status="error" my={10}>
          <AlertIcon />
          <AlertTitle>{error.message}</AlertTitle>
        </Alert>
      ) : !items ? null : (
        <>
          {children}
          {items?.length && pager?.total > 1 ? <Pager total={pager.total} current={+page} /> : null}
        </>
      )}
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={closeModal}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="2rem" fontWeight="bold">
              Delete Item
            </AlertDialogHeader>

            <AlertDialogBody fontSize="1rem" mb={5}>
              Are you sure? You can&apos;t undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeModal}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default ItemLister;
