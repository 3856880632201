import { Box, Button, Flex, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TableActions from '../../common/TableActions/TableActions';
import TableHeader from '../../common/TableHeader/TableHeader';
import useItemLister from '../../core/hooks/useItemLister';
import { Expertise } from '../../core/models';

const FILTERS = ['name'];

const COLS: {
  label: string;
  sort?: string;
  isNumeric?: boolean;
}[] = [
  {
    label: 'Name',
    sort: 'name',
  },
  {
    label: 'Published',
    sort: 'published',
  },
  {
    label: 'Actions',
    isNumeric: true,
  },
];

function ExpertiseLister() {
  const navigate = useNavigate();
  const { items, activeId, deleteItem, deleteLoading, Lister, sort, changeSort, setIsActive } =
    useItemLister({
      endpointSingle: 'expertise',
      endpointPlural: 'expertises',
      filters: FILTERS,
    });

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  return (
    <>
      <Flex>
        <h1 className="h3">Expertise</h1>
        <Button
          colorScheme="messenger"
          type="button"
          shadow="lg"
          ml="auto"
          onClick={() => navigate(`/expertise/new`)}
        >
          Create New
        </Button>
      </Flex>
      {Lister({
        children: (
          <Box my={5}>
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader cols={COLS} sort={sort} changeSort={changeSort} />
                <Tbody>
                  {items?.map((expertise: Expertise) => (
                    <Tr key={expertise._id}>
                      <Td p={2} verticalAlign="middle">
                        <button
                          style={{ padding: '6px' }}
                          type="button"
                          onClick={() => navigate(`/expertise/${expertise._id}`)}
                        >
                          {expertise.name}
                        </button>
                      </Td>
                      <Td
                        p={2}
                        verticalAlign="middle"
                        style={{ color: expertise.published ? 'green' : '' }}
                      >
                        {expertise.published ? 'Published' : 'Unpublished'}
                      </Td>
                      <Td p={2} verticalAlign="middle">
                        <TableActions
                          item={expertise}
                          path="expertise"
                          activeId={activeId}
                          deleteItem={deleteItem}
                          deleteLoading={deleteLoading}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </>
  );
}

export default ExpertiseLister;
