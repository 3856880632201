import { Box, Button, Flex, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TableActions from '../../common/TableActions/TableActions';
import TableHeader from '../../common/TableHeader/TableHeader';
import useItemLister from '../../core/hooks/useItemLister';
import { IClass } from '../../core/models';

function MemberClassLister() {
  const navigate = useNavigate();
  const { items, deleteItem, deleteLoading, Lister, activeId, sort, changeSort, setIsActive } =
    useItemLister({
      endpointSingle: 'member_class',
      endpointPlural: 'member_classes',
      filters: ['name'],
    });

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" width="100%">
        <h1 className="h3">Classes</h1>
        <Button
          colorScheme="messenger"
          type="button"
          shadow="lg"
          ml="auto"
          onClick={() => navigate(`/class/new`)}
        >
          Create a new class
        </Button>
      </Flex>
      {Lister({
        children: (
          <Box my={5}>
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader
                  sort={sort}
                  changeSort={changeSort}
                  cols={[
                    {
                      label: 'Name',
                      sort: 'name',
                    },
                    {
                      label: 'Actions',
                      isNumeric: true,
                    },
                  ]}
                />
                <Tbody>
                  {items?.map((memberClass: IClass) => (
                    <Tr key={memberClass._id}>
                      <Td py={3} verticalAlign="middle">
                        <button
                          style={{ padding: '6px' }}
                          type="button"
                          onClick={() => navigate(`/class/${memberClass._id}`)}
                        >
                          {memberClass.name}
                        </button>
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        <TableActions
                          item={memberClass}
                          path="class"
                          activeId={activeId}
                          deleteItem={deleteItem}
                          deleteLoading={deleteLoading}
                          customEditUrl={`/class/${memberClass._id}`}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </Flex>
  );
}

export default MemberClassLister;
