/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { FiMail } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';

import AccountInvitationModal from '../../Components/AccountInvitationModal/AccountInvitationModal';
import RHForm from '../../Components/RHForm/RHForm';
import countries from '../../assets/country-list.json';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import NewMember from '../NewMember/NewMember';

const COUNTRY_LIST = countries.map((el: { code: string; name: string }) => ({
  value: el.code,
  label: el.name,
}));

const FORM = {
  endpoint: 'user',
  itemPath: 'user',
  backPath: '/content?tab=2',
  schema: [
    {
      name: 'profile_pic',
      type: 'img',
    },
    {
      name: 'firstname',
      type: 'string',
      label: 'First name',
      required: true,
    },
    {
      name: 'lastname',
      type: 'string',
      label: 'Last name',
      required: true,
    },
    {
      name: 'country_code',
      type: 'select',
      label: 'Country',
      options: COUNTRY_LIST,
    },
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
    },
    {
      name: 'pronouns',
      type: 'select',
      label: 'Pronouns',
      options: [
        { value: 'She/her/hers', label: 'She/her/hers' },
        { value: 'He/him/his', label: 'He/him/his' },
        { value: 'They/them/theirs', label: 'They/them/theirs' },
        { value: 'Other', label: 'Other' },
      ],
    },
    {
      name: 'bio',
      type: 'textarea',
      label: 'Bio',
    },
    {
      name: 'social.linkedin',
      type: 'string',
      label: 'LinkedIn profile',
    },
    {
      name: 'birth_location',
      type: 'location',
      label: 'Where were you born?',
    },
    {
      name: 'current_location',
      type: 'location',
      label: 'Where do you live?',
    },
    {
      name: 'phone',
      type: 'int',
      label: 'Phone number',
    },
    {
      name: 'phone_country_code',
      type: 'int',
      label: 'Phone Country Code',
    },
    {
      name: 'company.name',
      type: 'string',
      label: 'Company name',
    },
    {
      name: 'company.url',
      type: 'string',
      label: 'Company url',
    },
    {
      name: 'title',
      type: 'string',
      label: 'Title',
    },
    {
      name: 'member_classes',
      type: 'tagsField',
      label: 'Classes',
      endpointSingle: 'member_class',
      endpointPlural: 'member_classes',
      placeHolder: 'Select classes',
    },
    {
      name: 'industries',
      type: 'tagsField',
      label: 'Industries',
      endpointSingle: 'industry',
      endpointPlural: 'industries',
      placeHolder: 'Select industries',
    },
    {
      name: 'expertises',
      type: 'tagsField',
      label: 'Expertises',
      endpointSingle: 'expertise',
      endpointPlural: 'expertises',
      placeHolder: 'Select expertises',
    },
    {
      name: 'community_roles',
      type: 'tagsField',
      label: 'Roles',
      endpointSingle: 'community_role',
      endpointPlural: 'community_roles',
      placeHolder: 'Select roles',
    },
    {
      name: 'time_slots',
      type: 'tagsField',
      label: 'Time slots',
      endpointSingle: 'time_slot',
      endpointPlural: 'time_slots',
      placeHolder: 'Select time slots',
      customProperty: 'slot',
    },
    {
      name: 'life_questions',
      type: 'lifeQuestions',
      label: 'Life questions',
    },
    {
      name: 'seo',
      type: 'object',
      label: 'SEO',
      schema: [
        {
          name: 'title',
          type: 'string',
          label: 'Title',
        },
        {
          name: 'metadesc',
          type: 'textarea',
          label: 'Description',
        },
      ],
    },
    {
      name: 'world_private',
      type: 'boolean',
      label: 'WORLD Private',
    },
    {
      name: 'platform_private',
      type: 'boolean',
      label: 'Platform Private',
    },
  ],
};

const USER_URL = `${process.env.REACT_APP_API_URL}/user`;

function ProfileForm() {
  const { id } = useParams();
  const { user: currentUser } = useUserContext();
  const navigate = useNavigate();
  const {
    result,
    doFetch: fetchUser,
    loading,
    error: userError,
  } = useFetch(
    `${USER_URL}/${id}?populate=member_classes&populate=industries&populate=expertises&populate=community_roles&populate=time_slots&populate=life_questions`,
  );
  const user = useMemo(() => result?.data, [result]);

  useEffect(() => {
    if (id === 'new') return;
    fetchUser({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  const { bg_color, last_login, published, _id, ...rest } = user || {};

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : userError ? (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>No profile with the id:{id} found!</AlertTitle>
          </Alert>
        ) : !user ? (
          <>
            <h1 className="h3">Create a new profile</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <NewMember />
            </Box>
          </>
        ) : (
          <>
            <AccountInvitationModal
              id={user?._id || ''}
              button={
                <Button
                  leftIcon={<FiMail />}
                  colorScheme="messenger"
                  size="sm"
                  aria-label="Send invitation"
                >
                  Send invitation
                </Button>
              }
            />
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={rest} id={user._id} fetchItem={fetchUser} {...FORM} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default ProfileForm;
