/* eslint-disable react/jsx-props-no-spreading */
import { Alert, AlertIcon, AlertTitle, Box, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';

import { CContact, CPageBanner, CPeopleShowcase, CResult, CTitle } from '../../core/components';
import useFetch from '../../core/hooks/useFetch';
import RHForm from '../RHForm/RHForm';

const ABOUT_US_PAGE_URL = `${process.env.REACT_APP_API_URL}/page?slug=about-us`;

function AboutUsPageForm() {
  const { result, doFetch, loading, error } = useFetch(`${ABOUT_US_PAGE_URL}`);

  const FORM = useMemo(
    () => ({
      endpoint: 'page',
      id: result?.data._id,
      backPath: '/website',
      schema: [
        {
          name: 'body',
          type: 'object',
          schema: [
            CPageBanner,
            {
              name: 'pageBuilder',
              type: 'component',
              label: 'Page Builder',
              components: [CContact, CTitle, CResult, CPeopleShowcase],
            },
          ],
        },
      ],
    }),
    [result],
  );

  useEffect(() => {
    doFetch();
  }, []);

  return (
    <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
      {!loading ? null : <Spinner />}
      {!error ? null : (
        <Alert status="error" my={10}>
          <AlertIcon />
          <AlertTitle>Could not load About Us page data</AlertTitle>
        </Alert>
      )}
      {!result ? null : (
        <RHForm
          itemValues={{ body: result.data?.body }}
          updated={result.data?.updated}
          fetchItem={doFetch}
          noDelete
          {...FORM}
        />
      )}
    </Box>
  );
}

export default AboutUsPageForm;
