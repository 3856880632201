import { Box, Button, useToast } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';

import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import { isValidEmail } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';

const BASE_URL = process.env.REACT_APP_API_URL;

function ForgotPassword() {
  const toast = useToast();
  const email = useFormInput('', (value) => isValidEmail(value));
  const { result, doFetch, loading, error } = useFetch(`${BASE_URL}/user/send-reset-password-link`);

  const handleSubmit = useCallback(() => {
    doFetch({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email.value,
      }),
    });
  }, [email]);

  useEffect(() => {
    if (result?.status !== 'success') return;
    toast({
      title: result.message,
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  }, [result]);

  useEffect(() => {
    if (!error) return;
    toast({
      title: error.message,
      position: 'top',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  }, [error]);

  return (
    <section>
      <h1 className="h3">Forgot your password?</h1>
      <h2 className="h4">Enter your e-mail to send a password reset link.</h2>
      <Box
        bg="white"
        p={5}
        borderRadius={6}
        boxShadow="lg"
        my={10}
        maxWidth={{ md: '100%', xl: 'calc(50% - 1.25rem)' }}
      >
        <CustomForm inputs={[email]} onSubmit={handleSubmit}>
          <CustomInput
            id="email"
            type="text"
            name="email"
            placeholder="Enter your email address"
            label="Email address"
            input={email}
            emptyMessage="Please fill in your email"
            invalidMessage="Please fill in a valid email address"
          />
          <Button
            isLoading={loading}
            isDisabled={loading}
            loadingText="Submitting"
            colorScheme="messenger"
            type="submit"
          >
            Send
          </Button>
        </CustomForm>
      </Box>
    </section>
  );
}

export default ForgotPassword;
