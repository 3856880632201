/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ImageInterface } from '../../core/models';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

interface Props {
  callback: any;
  closeModal: () => void;
  items: any;
  Lister: any;
}

function ImageGrid({ callback, closeModal, items, Lister }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleClick = useCallback((id: string) => {
    callback(id);
    closeModal();
  }, []);

  useEffect(
    () => () => {
      searchParams.delete('page');
      searchParams.delete('search');
      setSearchParams(searchParams, {
        replace: true,
      });
    },
    [],
  );

  return (
    <Box py={2} px={5} style={{ minHeight: '670px' }}>
      {Lister({
        children: (
          <Grid templateColumns="repeat(auto-fill, 157px)" gap={6} p={2}>
            {items?.map((el: ImageInterface) => (
              <GridItem
                display="flex"
                justifyContent="center"
                key={el._id}
                boxShadow="base"
                borderRadius={5}
                overflow="hidden"
                transition="250ms"
                _hover={{
                  boxShadow: 'xl',
                }}
              >
                <button
                  type="button"
                  onClick={() => handleClick(el._id)}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '5px',
                    width: '100%',
                  }}
                >
                  <img
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'contain',
                      marginBottom: '5px',
                    }}
                    src={`${BASE_URL}/uploads/${
                      el.mimetype === 'image/svg+xml' ? el.uri : el.sizes?.thumb?.uri || el.uri
                    }`}
                    alt={el.alt}
                  />
                  <Text fontSize="xs" color="#666">
                    {el.width}x{el.height}
                  </Text>
                  <Text noOfLines={2} fontSize="md" textAlign="center" wordBreak="break-word">
                    {el.title}
                  </Text>
                  <Text
                    noOfLines={2}
                    fontSize="xs"
                    textAlign="center"
                    wordBreak="break-all"
                    color="#666"
                  >
                    {el.alt}
                  </Text>
                </button>
              </GridItem>
            ))}
          </Grid>
        ),
      })}
    </Box>
  );
}

export default ImageGrid;
