import { useMemo } from 'react';

import InvitationLister from '../../Components/InvitationLister/InvitationLister';
import InvitationSettingsEditor from '../../Components/InvitationSettingsEditor/InvitationSettingsEditor';
import ItemTabs from '../../common/ItemTabs/ItemTabs';

function InvitationSettings() {
  const tabs = useMemo(
    () => [
      { label: 'Invitations', component: <InvitationLister /> },
      {
        label: 'Configuration',
        component: <InvitationSettingsEditor />,
      },
    ],
    [],
  );

  return (
    <section>
      <ItemTabs path="invitations" tabs={tabs} />
    </section>
  );
}

export default InvitationSettings;
