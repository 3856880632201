/* eslint-disable react/jsx-props-no-spreading */
import { Alert, AlertIcon, AlertTitle, Box, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';

import { CIntroduction } from '../../core/components';
import useFetch from '../../core/hooks/useFetch';
import RHForm from '../RHForm/RHForm';

const ARTICLE_PAGE_URL = `${process.env.REACT_APP_API_URL}/page?slug=blog`;

function ArticlePageForm() {
  const { result, doFetch, loading, error } = useFetch(`${ARTICLE_PAGE_URL}`);

  const FORM = useMemo(
    () => ({
      endpoint: 'page',
      id: result?.data._id,
      backPath: '/website',
      schema: [
        {
          name: 'body',
          type: 'object',
          schema: [
            {
              name: 'title',
              type: 'string',
              label: 'Title',
              required: true,
            },
            {
              name: 'desc',
              type: 'string',
              label: 'Description',
            },
            {
              name: 'featuredArticle1',
              type: 'ref',
              entity: 'article',
              label: 'Featured article 1',
            },
            {
              name: 'featuredCategory1',
              type: 'ref',
              entity: 'tag',
              label: 'Featured category 1',
            },
            {
              name: 'featuredCategory2',
              type: 'ref',
              entity: 'tag',
              label: 'Featured category 2',
            },
            {
              name: 'featuredArticle2',
              type: 'ref',
              entity: 'article',
              label: 'Featured article 2',
            },
            {
              name: 'featuredCategory3',
              type: 'ref',
              entity: 'tag',
              label: 'Featured category 3',
            },
            CIntroduction,
          ],
        },
      ],
    }),
    [result],
  );

  useEffect(() => {
    doFetch();
  }, []);

  return (
    <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
      {!loading ? null : <Spinner />}
      {!error ? null : (
        <Alert status="error" my={10}>
          <AlertIcon />
          <AlertTitle>Could not load article page data</AlertTitle>
        </Alert>
      )}
      {!result ? null : (
        <RHForm
          itemValues={{ body: result.data?.body }}
          updated={result.data?.updated}
          fetchItem={doFetch}
          noDelete
          {...FORM}
        />
      )}
    </Box>
  );
}

export default ArticlePageForm;
