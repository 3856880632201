import { Alert, AlertIcon, Box, Button, Spinner, useToast } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import countries from '../../assets/country-list.json';
import CustomCheckbox from '../../common/CustomCheckbox/CustomCheckbox';
import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import { isEmpty, isValidPassword } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import { useFormCheckbox } from '../../core/hooks/useFormCheckbox';
import { useFormInput } from '../../core/hooks/useFormInput';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
const WEBSITE_URL = `${process.env.REACT_APP_WEBSITE_URL}`;
const COUNTRY_LIST = countries.map((el: { code: string; name: string }) => ({
  value: el.code,
  label: el.name,
}));

interface Props {
  id: string;
}

function UserConfirmForm({ id }: Props) {
  const toast = useToast();
  const password = useFormInput('', (value) => isValidPassword(value));
  const repeatPassword = useFormInput('', (value) => !isEmpty(value) && value === password.value);
  const termsCheckbox = useFormCheckbox(false, (value) => value);
  const { result, doFetch, loading, error } = useFetch(`${BASE_URL}/user/register`);
  const {
    result: fetchedEmail,
    doFetch: doEmailFetch,
    loading: emailLoading,
    error: emailError,
  } = useFetch(`${BASE_URL}/user/invited-email/${id}`);
  const email = useFormInput(fetchedEmail?.data.email || '', (value) => !isEmpty(value));
  const firstname = useFormInput(fetchedEmail?.data.firstname || '', (value) => !isEmpty(value));
  const lastname = useFormInput(fetchedEmail?.data.lastname || '', (value) => !isEmpty(value));
  const country = useFormInput(fetchedEmail?.data.country || '', (value) => !isEmpty(value));

  const handleSubmit = useCallback(() => {
    if (!email) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const body: any = {
      email: email.value,
      password: password.value,
      firstname: firstname.value,
      lastname: lastname.value,
      country_code: country.value,
      hash: id,
      accepted_conditions: termsCheckbox.checked,
    };

    if (fetchedEmail?.data._id) {
      body.connected_id = fetchedEmail.data._id;
    }
    doFetch({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }, [firstname, email, lastname, password, id]);

  useEffect(() => {
    doEmailFetch();
  }, []);

  useEffect(() => {
    if (!result || result.status !== 'success') return;
    toast({
      title: result.message,
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  }, [result]);

  useEffect(() => {
    if (!error && !emailError) return;
    toast({
      title: error.message || emailError.message,
      position: 'top',
      status: 'error',
      duration: 10000,
      isClosable: true,
    });
  }, [error, emailError]);

  if (emailLoading) return <Spinner my={10} />;

  return result?.data ? (
    <>
      <Alert status="success" my={10} p={5} fontSize={24}>
        <AlertIcon boxSize={7} />
        Thank you! You are now one step closer to entering the CMS.
      </Alert>
      <Box mt={10}>
        <Link className="btn btn--dark" to="/login">
          Log In
        </Link>
      </Box>
    </>
  ) : (
    <Box bg="white" p={5} my={10} maxWidth={800} borderRadius={6} boxShadow="lg" flex="1 1 auto">
      <CustomForm
        inputs={[email, firstname, lastname, password, repeatPassword, termsCheckbox]}
        onSubmit={handleSubmit}
      >
        <CustomInput
          input={email}
          id="email"
          type="text"
          name="email"
          placeholder={emailLoading ? 'Loading...' : 'Email'}
          label="Your email"
          readOnly
        />
        <CustomInput
          input={firstname}
          id="firstname"
          type="text"
          name="firstname"
          placeholder="First name"
          label="What is your first name?"
        />
        <CustomInput
          input={lastname}
          id="lastname"
          type="text"
          name="lastname"
          placeholder="Last name"
          label="What is your last name?"
        />
        <CustomSelect
          id="country"
          name="country"
          placeholder="Country"
          label="Country"
          options={COUNTRY_LIST}
          input={country}
          emptyMessage="Country is required"
        />
        <CustomInput
          id="password"
          type="password"
          name="password"
          placeholder="Password"
          label="Your password"
          input={password}
          emptyMessage="Please fill in a password"
          invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
        />
        <CustomInput
          type="password"
          id="password-repeat"
          name="password-repeat"
          input={repeatPassword}
          placeholder="Repeat your password"
          label="Repeat your password"
          emptyMessage="It's required to repeat your password"
          invalidMessage="Your passwords don't match"
          autoComplete="off"
        />
        <CustomCheckbox
          id="terms-checkbox"
          name="terms-checkbox"
          input={termsCheckbox}
          label={
            <>
              I agree with the{' '}
              <a href={`${WEBSITE_URL}/privacy-policy`} target="_blank" rel="noreferrer">
                Privacy Statement
              </a>{' '}
              and{' '}
              <a href={`${WEBSITE_URL}/terms-and-conditions`} target="_blank" rel="noreferrer">
                Terms & Conditions.
              </a>
            </>
          }
          invalidMessage="You have to accept the privacy policy and terms of conditions"
        />
        <Button
          colorScheme="messenger"
          type="submit"
          shadow="lg"
          mt={10}
          isDisabled={loading || !email || emailError}
          isLoading={loading}
        >
          Create your account
        </Button>
      </CustomForm>
    </Box>
  );
}

export default UserConfirmForm;
