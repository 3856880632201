import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';

interface Props {
  error: {
    message: string;
  };
}

function ErrorMessage({ error }: Props) {
  return !error ? null : (
    <Alert status="error" mt={6}>
      <AlertIcon />
      <AlertDescription>{error.message}</AlertDescription>
    </Alert>
  );
}

export default ErrorMessage;
