import { Heading } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

import UserConfirmForm from '../../Components/UserConfirmForm/UserConfirmForm';

function UserInvited() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const hash = searchParams.get('h') || '';

  return (
    <section>
      <Heading fontSize={64}>Create your account</Heading>
      <UserConfirmForm id={hash} />
    </section>
  );
}

export default UserInvited;
