/* eslint-disable react/jsx-props-no-spreading */
import RHForm from '../RHForm/RHForm';

const FORM = {
  endpoint: 'vat',
  itemPath: 'vat',
  backPath: '/payment-transactions?tab=2',
  schema: [
    {
      name: 'country',
      type: 'string',
      label: 'Country name',
    },
    {
      name: 'code',
      type: 'string',
      label: 'Country code',
    },
    {
      name: 'vat_p',
      type: 'number',
      label: 'VAT %',
    },
  ],
};

function VATForm({ vat, fetchVAT }: any) {
  return <RHForm itemValues={vat} id={vat?._id} fetchItem={fetchVAT} {...FORM} />;
}

export default VATForm;
