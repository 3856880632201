/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { IconButton, Stack, Tooltip } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { FiMail } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import AccountInvitationModal from '../../Components/AccountInvitationModal/AccountInvitationModal';

interface Props {
  item: {
    _id: string;
  };
  customEditUrl?: string;
  showEditButton?: boolean;
  path: string;
  activeId: string | null;
  deleteItem(id: string): void;
  deleteLoading: boolean;
  invitationButton?: boolean;
  customActions?: {
    icon: ReactElement;
    onClick: any;
    label: string;
  }[];
}

function TableActions({
  item,
  path,
  customEditUrl,
  activeId,
  deleteItem,
  deleteLoading,
  customActions,
  showEditButton = true,
  invitationButton = false,
}: Props) {
  const navigate = useNavigate();
  return (
    <Stack spacing={3} direction="row" align="center" justify="flex-end" ml="auto">
      {showEditButton && (
        <Tooltip label={`Edit ${path}`}>
          <IconButton
            colorScheme="messenger"
            size="sm"
            aria-label={`Edit ${path}`}
            icon={<EditIcon />}
            onClick={() => navigate(customEditUrl || `/${path}/${item._id}`)}
          />
        </Tooltip>
      )}
      {customActions &&
        customActions.map((customAction) => (
          <Tooltip key={customAction.label} label={customAction.label}>
            <IconButton
              colorScheme="messenger"
              size="sm"
              aria-label={customAction.label}
              icon={customAction.icon}
              onClick={customAction.onClick}
            />
          </Tooltip>
        ))}
      {invitationButton && (
        <AccountInvitationModal
          id={item._id}
          button={
            <IconButton
              colorScheme="messenger"
              size="sm"
              aria-label="Send invitation"
              icon={<FiMail />}
            />
          }
        />
      )}
      <Tooltip label="Delete">
        <IconButton
          colorScheme="red"
          size="sm"
          aria-label={`Delete ${path}`}
          icon={<DeleteIcon />}
          onClick={() => deleteItem(item._id)}
          disabled={deleteLoading && item._id === activeId}
          isLoading={deleteLoading && item._id === activeId}
        />
      </Tooltip>
    </Stack>
  );
}

export default TableActions;
