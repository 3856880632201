/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import SubscriptionInput from '../SubscriptionInput/SubscriptionInput';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

interface IProps {
  path: string;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
}

function RHSubscriptions({ path, setValue, getValues }: IProps) {
  const [touched, setTouched] = useState(false);
  // Fetch by id ( subscriptions )
  const params = useParams();
  const { result, doFetch } = useFetch(`${BASE_URL}/user/subscriptions`);
  const { token } = useUserContext();
  const [subscriptions, setSubscription] = useState();

  useEffect(() => {
    if (Object.keys(params).length === 0) {
      doFetch({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else if (Object.keys(params).includes('id')) {
      doFetch({
        newUrl: `${BASE_URL}/user/${params.id}/subscriptions`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (result && result.data) {
      setSubscription(
        result.data.map((res: any) => ({
          ...res,
          subscription: res.subscription[0],
        })),
      );
    }
  }, [result]);

  const onChange = useCallback(
    (value: any) => {
      setValue(path, value, {
        shouldDirty: touched,
      });
    },
    [touched, path],
  );

  return Array.isArray(subscriptions) ? (
    <SubscriptionInput
      getValues={getValues}
      setTouched={setTouched}
      value={subscriptions}
      onChange={onChange}
    />
  ) : (
    <div />
  );
}

export default RHSubscriptions;
