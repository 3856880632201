import { Box, Button, Flex, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TableActions from '../../common/TableActions/TableActions';
import TableHeader from '../../common/TableHeader/TableHeader';
import useItemLister from '../../core/hooks/useItemLister';
import { Article } from '../../core/models';

const FILTERS = ['title', 'slug'];

const COLS: {
  label: string;
  sort?: string;
  isNumeric?: boolean;
}[] = [
  {
    label: 'Title',
    sort: 'title',
  },
  {
    label: 'Slug',
    sort: 'slug',
  },
  {
    label: 'CreatedAt',
    sort: 'created',
  },
  {
    label: 'Published',
    sort: 'published',
  },
  {
    label: 'Actions',
    isNumeric: true,
  },
];

function ArticleLister() {
  const navigate = useNavigate();
  const { items, activeId, deleteItem, deleteLoading, Lister, sort, changeSort, setIsActive } =
    useItemLister({
      endpointSingle: 'article',
      endpointPlural: 'articles',
      filters: FILTERS,
    });

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  return (
    <>
      <Flex>
        <h1 className="h3">Article</h1>
        <Button
          colorScheme="messenger"
          type="button"
          shadow="lg"
          ml="auto"
          onClick={() => navigate(`/article/new`)}
        >
          Create New
        </Button>
      </Flex>
      {Lister({
        children: (
          <Box my={5}>
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader cols={COLS} sort={sort} changeSort={changeSort} />
                <Tbody>
                  {items?.map((article: Article) => (
                    <Tr key={article._id}>
                      <Td p={2} verticalAlign="middle">
                        <button
                          style={{
                            padding: '6px',
                            maxWidth: '300px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          type="button"
                          onClick={() => navigate(`/article/${article._id}`)}
                        >
                          {article.title}
                        </button>
                      </Td>
                      <Td
                        p={2}
                        verticalAlign="middle"
                        style={{
                          padding: '6px',
                          maxWidth: '200px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {article.slug}
                      </Td>
                      <Td p={2} verticalAlign="middle">
                        {new Date(article.created).toLocaleString('en-gb', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </Td>
                      <Td
                        p={2}
                        verticalAlign="middle"
                        style={{ color: article.published ? 'green' : '' }}
                      >
                        {article.published ? 'Published' : 'Unpublished'}
                      </Td>
                      <Td p={2} verticalAlign="middle">
                        <TableActions
                          item={article}
                          path="article"
                          activeId={activeId}
                          deleteItem={deleteItem}
                          deleteLoading={deleteLoading}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </>
  );
}

export default ArticleLister;
