/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import UserForm from '../../Components/UserForm/UserForm';
import VATForm from '../../Components/VATForm/VATForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import InviteUser from '../InviteUser/InviteUser';

const USER_URL = `${process.env.REACT_APP_API_URL}/vat`;

function VAT() {
  const { id } = useParams();
  const { user: currentUser } = useUserContext();
  const navigate = useNavigate();
  const { result, doFetch: fetchVAT, loading, error: userError } = useFetch(`${USER_URL}/${id}`);
  const vat = useMemo(() => result?.data, [result]);

  useEffect(() => {
    if (id === 'new') return;
    fetchVAT({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : userError ? (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>No VAT rate with the id:{id} found!</AlertTitle>
          </Alert>
        ) : !vat ? (
          <>
            <h1 className="h3">Create a new VAT rate</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <VATForm vat={null} fetchVAT={fetchVAT} />
            </Box>
          </>
        ) : (
          <>
            <h1 className="h3">{vat.country}</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <VATForm vat={vat} fetchVAT={fetchVAT} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default VAT;
