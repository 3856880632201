/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner, Tag } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const FORM = {
  endpoint: 'member_class',
  itemPath: 'class',
  backPath: '/content?tab=10',
  schema: [
    {
      name: 'name',
      type: 'string',
      label: 'Name',
      required: true,
    },
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
      required: true,
    },
  ],
};

function EditMemberClass() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    doFetch: getMemberClass,
    result,
    error,
    loading,
  } = useFetch(`${process.env.REACT_APP_API_URL}/member_class/${id}`);
  const memberClass = useMemo(() => result?.data, [result]);

  const { token } = useUserContext();

  useEffect(() => {
    if (id === 'new') return;
    getMemberClass({
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  }, [id]);

  const values = useMemo(() => {
    if (result) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {
        created,
        _id,
        id: oldId,
        title,
        updated,
        summary,
        body,
        body_html,
        original_guid,
        original_link,
        original_sticky,
        location,
        program,
        ...data
      } = result.data;
      return data;
    }
    return {};
  }, [result]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box my={10}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the class with id: {id}</AlertTitle>
          </Alert>
        ) : !memberClass ? (
          <>
            <h1 className="h3">Create a new class</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm {...FORM} />
            </Box>
          </>
        ) : (
          <>
            <h1 className="h3">{memberClass.name}</h1>
            <Tag mt={2} colorScheme="facebook">
              {memberClass._id}
            </Tag>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm
                itemValues={values}
                id={memberClass._id}
                fetchItem={getMemberClass}
                withPublish
                {...FORM}
              />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default EditMemberClass;
