/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable react/jsx-props-no-spreading */
import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Flex, IconButton, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import Prompt from '../../common/Prompt/Prompt';
import useItemForm from '../../core/hooks/useItemForm';
import ArrayField from './ArrayField';
import Field from './Field';
import './RHForm.css';

interface IProps {
  schema: any[];
  endpoint: string;
  itemPath?: string;
  itemValues?: any;
  id?: string;
  onFormSubmit?: any;
  fetchItem?: () => void;
  backPath: string;
  noDelete?: boolean;
  withPrompt?: boolean;
  withPublish?: boolean;
  updated?: string;
  pureEndpoint?: boolean;
  resetOnSave?: boolean;
}

function RHForm({
  schema,
  endpoint,
  itemPath,
  itemValues,
  id,
  fetchItem,
  backPath,
  onFormSubmit,
  noDelete,
  withPublish = false,
  updated,
  withPrompt = true,
  pureEndpoint,
  resetOnSave,
}: IProps) {
  const toast = useToast();
  const {
    control,
    register,
    unregister,
    handleSubmit,
    getFieldState,
    formState,
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm({
    defaultValues: itemValues,
    mode: 'all',
  });

  const [body, setBody] = useState(itemValues || {});
  const {
    updateItem,
    updateLoading,
    createItem,
    createLoading,
    deleteItem,
    deleteLoading,
    publishItem,
    isOpen,
    onClose,
    confirmDelete,
  } = useItemForm({
    endpoint,
    pureEndpoint,
    itemPath,
    id,
    body: getValues(),
    item: itemValues,
    fetchItem,
    backPath,
  });

  useEffect(() => {
    if (Object.keys(itemValues || {}).includes('user')) {
      setValue('user', itemValues.user);
    }
    if (Object.keys(itemValues || {}).includes('subscription')) {
      setValue('subscription', itemValues.subscription);
    }
    if (Object.keys(itemValues || {}).includes('subscriptions')) {
      setValue('subscriptions', itemValues.subscriptions);
    }
  }, [itemValues]);

  useEffect(() => {
    if (!resetOnSave) return;
    reset({ ...itemValues });
  }, [itemValues]);

  const onSubmit = (data: any) => {
    if (onFormSubmit) {
      return onFormSubmit(data);
    }
    const keys = Object.keys(data);
    if (keys.includes('email')) {
      if (itemValues?.email === data.email) {
        data.email = undefined;
      }
    }
    if (keys.includes('day') && keys.includes('from') && keys.includes('to')) {
      data.slot = `${data.day} ${data.from} - ${data.to}`;
      data.day = undefined;
      data.from = undefined;
      data.to = undefined;
    }
    // TODO: Change this to clean solution
    if (keys.includes('paid_details')) {
      data.paid = true;
    }
    setBody(data);
    reset({}, { keepValues: true });
    return itemValues ? updateItem(data) : createItem(data);
  };

  function printErrorMessages(obj: any) {
    const messages: string[] = [];
    if (typeof obj !== 'object' || obj === null) {
      // eslint-disable-next-line no-console
      console.log('Invalid object');
      return;
    }
    function printMessages(o: any) {
      if (Array.isArray(o)) {
        return o.forEach((i: any) => {
          printMessages(i);
        });
      }
      if (typeof o === 'object' && o !== null) {
        Object.keys(o).forEach((key: any) => {
          if (o[key] instanceof Element) {
            return false;
          }
          if (key === 'message') {
            messages.push(o[key]);
          } else {
            printMessages(o[key]);
          }
        });
      }
    }

    printMessages(obj);
    return messages;
  }

  const onError = (e: any) => {
    toast({
      title: (
        <>
          <div>Fields with errors:</div>
          <ol>
            {printErrorMessages(e)?.map((message: string) => (
              <li key={message}>{message}</li>
            ))}
          </ol>
        </>
      ),
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <>
      <form
        style={
          endpoint.length === 0
            ? { display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'end' }
            : undefined
        }
        onSubmit={handleSubmit(onSubmit, onError)}
        className="rhf-form"
      >
        {endpoint.length > 0 && (
          <Flex
            flexDirection="column"
            position="sticky"
            top={5}
            zIndex={10}
            userSelect="none"
            pointerEvents="none"
          >
            <Flex justifyContent="flex-end">
              {!withPublish || !itemValues ? null : (
                <Button
                  colorScheme={itemValues?.published ? 'messenger' : 'gray'}
                  shadow="lg"
                  type="submit"
                  mb={5}
                  mr={5}
                  isDisabled={updateLoading || createLoading || deleteLoading || formState?.isDirty}
                  isLoading={updateLoading || createLoading}
                  onClick={publishItem}
                  userSelect="auto"
                  pointerEvents="all"
                >
                  {itemValues?.published
                    ? 'Published, click to unpublish'
                    : 'Unpublished, click to publish'}
                </Button>
              )}
              <Button
                colorScheme="messenger"
                shadow="lg"
                type="submit"
                mb={5}
                isLoading={updateLoading || createLoading}
                isDisabled={updateLoading || createLoading || !formState?.isDirty}
                userSelect="auto"
                pointerEvents="all"
              >
                Save
              </Button>
            </Flex>
            {!updated ? null : (
              <Flex justifyContent="flex-end" mb={5} userSelect="text" pointerEvents="all">
                Last updated:{' '}
                {new Date(updated).toLocaleString('en-gb', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Flex>
            )}
            {!itemValues || noDelete ? null : (
              <IconButton
                colorScheme="red"
                size="sm"
                aria-label="Delete item"
                shadow="lg"
                ml="auto"
                icon={<DeleteIcon />}
                onClick={deleteItem}
                disabled={deleteLoading || updateLoading || createLoading}
                isLoading={deleteLoading}
                userSelect="auto"
                pointerEvents="all"
              />
            )}
          </Flex>
        )}
        {schema.map((field: any) => {
          if (field.type === 'array')
            return (
              <ArrayField
                key={field.name}
                {...{
                  control,
                  register,
                  unregister,
                  formState,
                  field,
                  getFieldState,
                  getValues,
                  setValue,
                  path: '',
                  trigger,
                }}
              />
            );
          return (
            <Field
              key={`${field.name}${field.schema ? `-${field.schema.length}` : ''}`}
              {...{
                control,
                register,
                unregister,
                field,
                getFieldState,
                formState,
                getValues,
                setValue,
                path: '',
                isFilter: endpoint.length === 0,
                trigger,
              }}
            />
          );
        })}
        {endpoint.length === 0 && (
          <Button
            colorScheme="messenger"
            shadow="lg"
            type="submit"
            userSelect="auto"
            pointerEvents="all"
          >
            Apply
          </Button>
        )}
      </form>
      {endpoint.length > 0 && withPrompt && (
        <>
          <DeleteDialog isOpen={isOpen} onClose={onClose} confirmDelete={confirmDelete} />
          <Prompt active={formState?.isDirty} />
        </>
      )}
    </>
  );
}

export default RHForm;
