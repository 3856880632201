/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Control, UseFormSetValue, useController } from 'react-hook-form';

import TagField from '../../common/TagField/TagField';

interface IProps {
  path: string;
  setValue: UseFormSetValue<any>;
  control: Control<any, any>;
  field: any;
}

function RHFTagsField({ path, setValue, control, field }: IProps) {
  const [touched, setTouched] = useState(false);
  const { field: controllerField } = useController({
    name: path,
    control,
  });
  const [selectedTag, setSelectedTag] = useState(controllerField.value || []);

  useEffect(() => {
    setValue(
      path,
      selectedTag.map((el: any) => el._id),
      { shouldDirty: touched },
    );
  }, [selectedTag]);

  return (
    <TagField
      selectedTags={selectedTag}
      setSelectedTags={setSelectedTag}
      setTouched={setTouched}
      endpointSingle={field.endpointSingle}
      endpointPlural={field.endpointPlural}
      name={field.name}
      label={field.label}
      placeholder={field.placeholder}
      customProperty={field.customProperty}
    />
  );
}

export default RHFTagsField;
