import React, { useCallback, useEffect, useRef, useState } from 'react';

import './CustomUploadField.css';

interface Props {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  accept?: string;
}

function CustomUploadField({ file, setFile, accept }: Props) {
  const [isDragOver, setIsDragOver] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [readFile, setReadFile] = useState<string | ArrayBuffer | null>(null);

  useEffect(() => {
    if (!file) return setReadFile(null);
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      setReadFile(reader.result);
    };
  }, [file]);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragOver(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragOver(false);
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();

    if (e.dataTransfer.files.length && e.dataTransfer.files[0].type.startsWith('image/'))
      setFile(e.dataTransfer.files[0]);

    setIsDragOver(false);
  }, []);

  const handleClick = useCallback(() => {
    if (!inputRef.current) return;
    inputRef.current.click();
  }, [inputRef]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length && e.target.files[0].type.startsWith('image/'))
      setFile(e.target.files[0]);
  }, []);

  return (
    <div
      className={`upload${!isDragOver ? '' : ' upload--over'}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnd={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleClick}
      role="presentation"
    >
      {file && readFile ? (
        <>
          <div className="upload__thumbnail" style={{ backgroundImage: `url(${readFile})` }} />
          <span className="upload__name">{file.name}</span>
        </>
      ) : (
        <span className="upload__label">Drop a file or click to upload</span>
      )}
      <input
        ref={inputRef}
        className="upload__field"
        type="file"
        name="image"
        accept={accept}
        onChange={handleChange}
      />
    </div>
  );
}

export default CustomUploadField;
