import PageLister from '../../Components/PageLister/PageLister';
import WorldGeneralDataForm from '../../Components/WorldGeneralDataForm/WorldGeneralDataForm';
import WorldHomePageForm from '../../Components/WorldHomePageForm/WorldHomePageForm';
import ItemTabs from '../../common/ItemTabs/ItemTabs';

const TABS = [
  { label: 'General data', component: <WorldGeneralDataForm /> },
  { label: 'Home page', component: <WorldHomePageForm /> },
  { label: 'Page', component: <PageLister world /> },
];

function World() {
  return (
    <section>
      <ItemTabs path="world" tabs={TABS} />
    </section>
  );
}

export default World;
