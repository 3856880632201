import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface Props {
  path: string;
  tabs: {
    label: string;
    component: JSX.Element;
  }[];
}

function ItemTabs({ path, tabs }: Props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  useEffect(() => {
    if (!tab) return;
    if (+tab > tabs.length - 1) navigate(`/${path}`);
  }, [tab]);

  const handleTabsChange = useCallback((index: number) => {
    navigate(`/${path}?tab=${index}`);
  }, []);

  return (
    <Tabs
      variant="enclosed"
      colorScheme="gray"
      border="gray"
      index={tab ? +tab : 0}
      onChange={handleTabsChange}
      isLazy
    >
      <Flex overflowX="auto" p={1} style={{ borderColor: 'gray' }}>
        <TabList flexGrow={1}>
          {tabs.map((el) => (
            <Tab
              px={10}
              _selected={{ borderColor: 'gray', borderBottomColor: 'var(--surface-light)' }}
              key={el.label}
            >
              {el.label}
            </Tab>
          ))}
        </TabList>
      </Flex>
      <TabPanels>
        {tabs.map((el) => (
          <TabPanel my={5} key={el.label}>
            {el.component}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}

export default ItemTabs;
