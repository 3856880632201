import { Badge, Box, Button, Flex, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TableActions from '../../common/TableActions/TableActions';
import TableHeader from '../../common/TableHeader/TableHeader';
import useItemLister from '../../core/hooks/useItemLister';

const FILTERS = ['name'];

const COLS: {
  label: string;
  sort?: string;
  isNumeric?: boolean;
}[] = [
  {
    label: 'Name',
    sort: 'name',
  },
  {
    label: 'Code',
  },
  {
    label: 'Discount %',
  },
  {
    label: 'Runs',
  },
  {
    label: 'Active',
  },
];

function DiscountLister() {
  const navigate = useNavigate();
  const { items, activeId, deleteItem, deleteLoading, Lister, sort, changeSort, setIsActive } =
    useItemLister({
      endpointSingle: 'discount',
      endpointPlural: 'discounts',
      filters: FILTERS,
    });

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  return (
    <>
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
        <h1 className="h3">Discount Types</h1>
        <Flex gap={2}>
          <Button
            colorScheme="messenger"
            type="button"
            shadow="lg"
            ml="auto"
            onClick={() => navigate(`/discount/new`)}
          >
            Create New Discount Type
          </Button>
        </Flex>
      </Flex>
      {Lister({
        children: (
          <Box my={5}>
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader cols={COLS} sort={sort} changeSort={changeSort} />
                <Tbody>
                  {items?.map((discount: any) => (
                    <Tr key={discount._id}>
                      <Td p={2} verticalAlign="middle">
                        <button
                          style={{ padding: '6px' }}
                          type="button"
                          onClick={() => navigate(`/discount/${discount._id}`)}
                        >
                          {discount.name}
                        </button>
                      </Td>
                      <Td verticalAlign="middle">{discount.code}</Td>
                      <Td verticalAlign="middle">{discount.discount_p}%</Td>
                      <Td verticalAlign="middle">{discount.runs}</Td>
                      <Td verticalAlign="middle">{discount.active ? 'Yes' : 'No'}</Td>
                      <Td p={2} verticalAlign="middle">
                        <TableActions
                          item={discount}
                          path="discount"
                          activeId={activeId}
                          deleteItem={deleteItem}
                          deleteLoading={deleteLoading}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </>
  );
}

export default DiscountLister;
