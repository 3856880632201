import { Box, Button, Flex, useToast } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';

import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import { useUserContext } from '../../core/context/user.context';
import { isEmpty, isValidPassword } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

function PasswordForm() {
  const toast = useToast();
  const { user, updateToken } = useUserContext();
  const oldPassword = useFormInput('', (value) => isValidPassword(value));
  const newPassword = useFormInput('', (value) => isValidPassword(value));
  const repeatNewPassword = useFormInput(
    '',
    (value) => !isEmpty(value) && value === newPassword.value,
  );
  const { result, doFetch, loading, error } = useFetch(`${BASE_URL}/user/change-password`);

  const handleSubmit = useCallback(() => {
    doFetch({
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        old: oldPassword.value,
        new: newPassword.value,
      }),
    });
  }, [newPassword, oldPassword]);

  useEffect(() => {
    if (result?.status !== 'success') return;
    toast({
      title: result.message,
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    oldPassword.setValue('');
    newPassword.setValue('');
    repeatNewPassword.setValue('');
    updateToken(result.data);
  }, [result]);

  useEffect(() => {
    if (!error) return;
    toast({
      title: error.message,
      position: 'top',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  }, [error]);

  return (
    <CustomForm inputs={[oldPassword, newPassword, repeatNewPassword]} onSubmit={handleSubmit}>
      <Box bg="white" p={5} borderRadius={6} boxShadow="lg">
        <h1 className="h3" style={{ marginBottom: '20px' }}>
          Change password
        </h1>
        <Flex justifyContent="flex-end" mb={5}>
          <Button
            isLoading={loading}
            isDisabled={loading}
            loadingText="Submitting"
            colorScheme="messenger"
            type="submit"
          >
            Save
          </Button>
        </Flex>
        <CustomInput
          id="old-password"
          type="password"
          name="old-password"
          placeholder="Enter your old password"
          label="Old password"
          input={oldPassword}
          link={{ text: 'Forgot your password?', href: '/forgot-password' }}
          emptyMessage="Please fill in your password"
          invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
        />{' '}
        <CustomInput
          id="new-password"
          type="password"
          name="new-password"
          placeholder="Enter your new password"
          label="New password"
          input={newPassword}
          emptyMessage="Please fill in your new password"
          invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
        />
        <CustomInput
          id="repeat-new-password"
          type="password"
          name="repeat-new-password"
          placeholder="Repeat your new password"
          label="Repeat your new password"
          input={repeatNewPassword}
          emptyMessage="Please repeat your new password"
          invalidMessage="Your passwords don't match"
          autoComplete="off"
        />
      </Box>
    </CustomForm>
  );
}

export default PasswordForm;
