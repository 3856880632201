/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner, Tag } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const FORM = {
  endpoint: 'journey',
  itemPath: 'journey',
  backPath: '/playground?tab=2',
  withPublish: false,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'tag_line',
      type: 'string',
      label: 'Tag Line',
      required: false,
    },
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
      required: true,
    },
    {
      name: 'description',
      type: 'string',
      label: 'Description',
      required: true,
    },
    {
      name: 'pg_modules',
      type: 'tagsField',
      label: 'Modules',
      customProperty: 'title',
      endpointSingle: 'pg_module',
      endpointPlural: 'pg_modules',
      placeHolder: 'Select modules',
    },
    {
      name: 'unlockConditions',
      type: 'array',
      label: 'Unlock condition',
      schema: [
        {
          name: 'journeySlug',
          type: 'string',
          label: 'Journey slug',
          required: true,
        },
      ],
    },
  ],
};

function EditJourney() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    doFetch: getJourney,
    result,
    error,
    loading,
  } = useFetch(`${process.env.REACT_APP_API_URL}/journey/${id}?populate=pg_modules`);
  const item = useMemo(() => result?.data, [result]);

  const { token } = useUserContext();

  useEffect(() => {
    if (id === 'new') return;
    getJourney({
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  }, [id]);

  const values = useMemo(() => {
    if (result) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { created, _id, updated, ...data } = result.data;
      return data;
    }
    return {};
  }, [result]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the journey with id: {id}</AlertTitle>
          </Alert>
        ) : !item ? (
          <>
            <h1 className="h3">Create new journey</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm {...FORM} />
            </Box>
          </>
        ) : null}
        {!item ? null : (
          <>
            <h1 className="h3">{item.title}</h1>
            <Tag mt={2} colorScheme="facebook">
              {item._id}
            </Tag>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={values} id={item._id} fetchItem={getJourney} {...FORM} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default EditJourney;
