import ImageLister from '../../Components/ImageLister/ImageLister';
import ItemTabs from '../../common/ItemTabs/ItemTabs';

const TABS = [
  { label: 'Image', component: <ImageLister /> },
  { label: 'User-generated Media', component: <ImageLister userGeneratedOnly /> },
];

function MediaLibrary() {
  return (
    <section>
      <ItemTabs path="media" tabs={TABS} />
    </section>
  );
}

export default MediaLibrary;
