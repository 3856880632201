/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable react/jsx-props-no-spreading */
import countries from '../../assets/country-list.json';
import { isValidEmail } from '../../core/helpers';
import { User } from '../../core/models';
import RHForm from '../RHForm/RHForm';

const COUNTRY_LIST = countries.map((el: { code: string; name: string }) => ({
  value: el.code,
  label: el.name,
}));

interface IProps {
  user: User;
  fetchUser?: (options?: RequestInit) => void;
}

const FORM = {
  endpoint: 'user',
  itemPath: 'user',
  backPath: '/content?tab=1',
  schema: [
    {
      name: 'profile_pic',
      type: 'img',
    },
    {
      name: 'roles',
      type: 'multiSelect',
      label: 'Roles',
      options: [
        {
          value: 'CMS',
          label: 'CMS',
        },
        {
          value: 'WORLD',
          label: 'World',
        },
        {
          value: 'PLATFORM',
          label: 'Platform',
        },
      ],
    },
    {
      name: 'email',
      type: 'string',
      label: 'Email',
      readOnly: false,
      required: true,
      validate: (value: string) => (isValidEmail(value) ? true : 'email'),
    },
    {
      name: 'firstname',
      type: 'string',
      label: 'First name',
    },
    {
      name: 'lastname',
      type: 'string',
      label: 'Last name',
    },
    {
      name: 'country_code',
      type: 'select',
      label: 'Country',
      options: COUNTRY_LIST,
    },
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
    },
    {
      name: 'pronouns',
      type: 'select',
      label: 'Pronouns',
      options: [
        { value: 'She/her/hers', label: 'She/her/hers' },
        { value: 'He/him/his', label: 'He/him/his' },
        { value: 'They/them/theirs', label: 'They/them/theirs' },
        { value: 'Other', label: 'Other' },
      ],
    },
    {
      name: 'bio',
      type: 'textarea',
      label: 'Bio',
    },
    {
      name: 'social.linkedin',
      type: 'string',
      label: 'LinkedIn profile',
    },
    {
      name: 'birth_location',
      type: 'location',
      label: 'Where were you born?',
    },
    {
      name: 'current_location',
      type: 'location',
      label: 'Where do you live?',
    },
    {
      name: 'phone',
      type: 'int',
      label: 'Phone number',
    },
    {
      name: 'phone_country_code',
      type: 'int',
      label: 'Phone Country Code',
    },
    {
      name: 'company.name',
      type: 'string',
      label: 'Company name',
    },
    {
      name: 'company.url',
      type: 'string',
      label: 'Company url',
    },
    {
      name: 'title',
      type: 'string',
      label: 'Title',
    },
    {
      name: 'subscriptions',
      type: 'subscriptions',
    },
    {
      name: 'discounts_used',
      type: 'array',
      label: 'Discounts used',
      allowRawString: true,
      schema: [
        {
          name: '',
          type: 'string',
          label: 'Discount code',
        },
      ],
    },
    {
      name: 'member_classes',
      type: 'tagsField',
      label: 'Classes',
      endpointSingle: 'member_class',
      endpointPlural: 'member_classes',
      placeHolder: 'Select classes',
    },
    {
      name: 'industries',
      type: 'tagsField',
      label: 'Industries',
      endpointSingle: 'industry',
      endpointPlural: 'industries',
      placeHolder: 'Select industries',
    },
    {
      name: 'expertises',
      type: 'tagsField',
      label: 'Expertises',
      endpointSingle: 'expertise',
      endpointPlural: 'expertises',
      placeHolder: 'Select expertises',
    },
    {
      name: 'community_roles',
      type: 'tagsField',
      label: 'Roles',
      endpointSingle: 'community_role',
      endpointPlural: 'community_roles',
      placeHolder: 'Select roles',
    },
    {
      name: 'time_slots',
      type: 'tagsField',
      label: 'Time slots',
      endpointSingle: 'time_slot',
      endpointPlural: 'time_slots',
      placeHolder: 'Select time slots',
      customProperty: 'slot',
    },
    {
      name: 'life_questions',
      type: 'lifeQuestions',
      label: 'Life questions',
    },
    {
      name: 'pg_life_questions',
      type: 'pgLifeQuestions',
      label: 'Playground life questions',
    },
    {
      name: 'world_private',
      type: 'boolean',
      label: 'WORLD Private',
    },
    {
      name: 'platform_private',
      type: 'boolean',
      label: 'Platform Private',
    },
    {
      name: 'seo',
      type: 'object',
      label: 'SEO',
      schema: [
        {
          name: 'title',
          type: 'string',
          label: 'Title',
        },
        {
          name: 'metadesc',
          type: 'textarea',
          label: 'Description',
        },
      ],
    },
  ],
};

function UserForm({ user, fetchUser }: IProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { bg_color, last_login, published, _id, ...rest }: any = user;
  return <RHForm itemValues={rest} id={user._id} fetchItem={fetchUser} {...FORM} />;
}

export default UserForm;
