import { PASSWORD_REGEX } from './constants';

export function debounce<F extends (...args: Parameters<F>) => ReturnType<F>>(
  func: F,
  waitFor: number,
): (...args: Parameters<F>) => void {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<F>): void => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };
}

export function isEmpty(value: string | number) {
  if (Array.isArray(value) && !value.length) return true;
  if ((Array.isArray(value) && value.length) || typeof value === 'number') return false;
  return value.trim() === '';
}

export function isValidEmail(email: any) {
  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  return !!(email && regex.test(email));
}

export function isValidPassword(password: string) {
  // TODO: Change if needed
  return PASSWORD_REGEX.test(password);
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function range(start: number, end: number) {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
}

export function arrayMove(arr: [], fromIndex: number, toIndex: number) {
  if (fromIndex < 0 || toIndex < 0 || fromIndex >= arr.length || toIndex >= arr.length) {
    // eslint-disable-next-line no-console
    console.log('Invalid indexes in arrayMove');
    return;
  }
  const newArr = structuredClone(arr);
  const element = newArr[fromIndex];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, element);
  return newArr;
}

export function generateUEID() {
  let first = `${Math.random() * 46656 || 0}`;
  let second = `${Math.random() * 46656 || 0}`;
  first = `000${first.toString()}`.slice(-3);
  second = `000${second.toString()}`.slice(-3);

  return first + second;
}

export function compareArrays(a: [], b: []) {
  return a.length === b.length && a.every((element, index) => element === b[index]);
}
