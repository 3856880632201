import { Icon } from '@chakra-ui/icons';
import { Button, Tooltip } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { FiEye, FiEyeOff, FiInfo } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { CustomInputInteface } from '../../core/models/inputs';

interface Props {
  id: string;
  className?: string;
  link?: {
    text: string;
    href: string;
  };
  type?: string;
  name: string;
  info?: string;
  label?: string;
  icon?: JSX.Element;
  placeholder: string;
  emptyMessage?: string;
  autoComplete?: string;
  invalidMessage?: string;
  tooltip?: string;
  input: CustomInputInteface;
  readOnly?: boolean;
  rows?: number;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

function CustomInput({
  id,
  link,
  type,
  name,
  info,
  label,
  icon,
  placeholder,
  emptyMessage,
  className = '',
  autoComplete,
  invalidMessage,
  tooltip,
  input,
  readOnly,
  rows,
  disabled,
  onFocus = () => {},
  onBlur = () => {},
}: Props) {
  const [currentType, setCurrentType] = useState(type || 'text');
  const error = useMemo(
    () => (!input.showError ? '' : input.isEmpty ? 'empty' : !input.isValid ? 'invalid' : ''),
    [input.showError, input.isEmpty, input.isValid],
  );

  return (
    <div className={`input${!error ? '' : ' error'}${!icon ? '' : ' with-icon'} ${className}`}>
      <div className="input__header">
        {!label ? null : (
          <label className="input__label" htmlFor={id}>
            {label}
            {tooltip && (
              <Tooltip label={tooltip} placement="right">
                <Button background="transparent" _hover={{ bg: 'transparent' }} p={2}>
                  <FiInfo />
                </Button>
              </Tooltip>
            )}
          </label>
        )}
        {!link ? null : (
          <Link className="input__link" to={link.href}>
            {link.text}
          </Link>
        )}
      </div>
      <div className="input__wrapper">
        {type === 'textarea' ? (
          <textarea
            className="input__field"
            id={id}
            autoComplete={autoComplete || 'off'}
            name={name}
            rows={rows || 5}
            value={input.value}
            onChange={input.onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            onFocus={onFocus}
            onBlur={onBlur}
            ref={input.ref}
            disabled={disabled}
          />
        ) : (
          <input
            className={`input__field${type === 'password' ? ' input__field--password' : ''}`}
            id={id}
            autoComplete={autoComplete || 'off'}
            type={currentType}
            name={name}
            value={input.value}
            onChange={input.onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            onFocus={onFocus}
            onBlur={onBlur}
            ref={input.ref}
            disabled={disabled}
          />
        )}
        {type !== 'password' ? null : (
          <button
            className="input__btn"
            type="button"
            onClick={() => setCurrentType(currentType === 'password' ? 'text' : 'password')}
          >
            <span className="sr-only">Change input type</span>
            {currentType === 'password' ? (
              <Icon as={FiEye} width={6} height={6} />
            ) : (
              <Icon as={FiEyeOff} width={6} height={6} />
            )}
          </button>
        )}
        {!icon ? null : <span className="input__icon">{icon}</span>}
        {!info ? null : <div className="input__info caption">{info}</div>}
      </div>
      {!error ? null : (
        <div className="input__error">
          <span>
            {error === 'empty' ? emptyMessage : error === 'invalid' ? invalidMessage : ''}
          </span>
        </div>
      )}
    </div>
  );
}

export default CustomInput;
