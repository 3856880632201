import { Box, Button, Flex, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TableActions from '../../common/TableActions/TableActions';
import TableHeader from '../../common/TableHeader/TableHeader';
import useItemLister from '../../core/hooks/useItemLister';
import { IQuote, Sequence } from '../../core/models';

const FILTERS = ['slug', 'quote', 'author'];

const COLS: {
  label: string;
  sort?: string;
  isNumeric?: boolean;
}[] = [
  {
    label: 'Quote',
    sort: 'quote',
  },
  {
    label: 'Slug',
    sort: 'slug',
  },
  {
    label: 'Author',
    sort: 'author',
  },
  {
    label: 'Sequences',
  },
  {
    label: 'Actions',
    isNumeric: true,
  },
];

function QuoteList() {
  const navigate = useNavigate();
  const { items, deleteItem, deleteLoading, Lister, activeId, sort, changeSort, setIsActive } =
    useItemLister({
      endpointSingle: 'quote',
      endpointPlural: 'quotes',
      filters: FILTERS,
    });

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" width="100%">
        <h1 className="h3">Quotes</h1>
        <Button
          colorScheme="messenger"
          type="button"
          shadow="lg"
          ml="auto"
          onClick={() => navigate(`/new-quote`)}
        >
          Create a new quote
        </Button>
      </Flex>
      {Lister({
        children: (
          <Box my={5}>
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader sort={sort} changeSort={changeSort} cols={COLS} />
                <Tbody>
                  {items?.map((quote: IQuote) => (
                    <Tr key={quote._id}>
                      <Td py={3} verticalAlign="middle">
                        <button
                          style={{
                            padding: '6px',
                            maxWidth: '300px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          type="button"
                          onClick={() => navigate(`/quote/${quote._id}`)}
                        >
                          {quote.quote}
                        </button>
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        {quote.slug}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        {quote.author}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        {`${
                          quote?.sequences
                            ? quote.sequences.map((sequence: Sequence) => sequence.slug)
                            : ''
                        }`}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        <TableActions
                          item={quote}
                          path="quote"
                          activeId={activeId}
                          deleteItem={deleteItem}
                          deleteLoading={deleteLoading}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </Flex>
  );
}

export default QuoteList;
