import { Navigate, Outlet } from 'react-router-dom';

import { User } from '../core/models';

interface Props {
  user: User;
  redirectPath?: string;
  children?: JSX.Element;
}

function ProtectedRoute({ user, redirectPath, children }: Props) {
  if (!user.token) {
    return <Navigate to={redirectPath || '/'} replace />;
  }
  if (user.email) {
    return children || <Outlet />;
  }
  return null;
}

export default ProtectedRoute;
