/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';

const FORM = {
  endpoint: 'time_slot',
  itemPath: 'time-slot',
  backPath: '/playground?tab=4',
  schema: [
    {
      name: 'slot',
      type: 'time-slot',
      label: 'Time slot',
      required: true,
    },
  ],
};

function NewTimeSlot() {
  const navigate = useNavigate();

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        <h1 className="h3">Create a new time slot</h1>
        <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
          <RHForm {...FORM} />
        </Box>
      </Box>
    </section>
  );
}

export default NewTimeSlot;
