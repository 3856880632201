import { Box } from '@chakra-ui/react';
import { useState } from 'react';

import CustomInput from '../../common/CustomInput/CustomInput';
import CustomTextarea from '../../common/CustomTextarea/CustomTextarea';
import CustomUploadField from '../../common/CustomUploadField/CustomUploadField';
import { isEmpty } from '../../core/helpers';
import { useFormInput } from '../../core/hooks/useFormInput';
import useItemForm from '../../core/hooks/useItemForm';
import { ImageInterface } from '../../core/models';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

interface Props {
  image?: ImageInterface;
  fetchImage?: (options?: RequestInit) => void;
  createNew?: boolean;
}

function ImageForm({ image, fetchImage, createNew }: Props) {
  const title = useFormInput(image?.title || '', (value) => !isEmpty(value));
  const alt = useFormInput(image?.alt || '', (value) => !isEmpty(value));
  const copyright = useFormInput(image?.copyright || '', () => true);
  const blurredAmount = useFormInput(image?.blur_amount || 0, () => true);
  const [file, setFile] = useState<File | null>(null);
  const { Container } = useItemForm({
    endpoint: 'image',
    id: image?._id,
    body: image
      ? {
          title: title.value,
          alt: alt.value,
          blur_amount: blurredAmount.value,
          copyright: copyright.value,
        }
      : {
          img: file,
          title: title.value,
          alt: alt.value,
          copyright: copyright.value,
          is_public: true,
          blur_amount: blurredAmount.value,
        },
    fetchItem: fetchImage,
    disabled: !file && !image?.uri,
    inputs: [title, alt],
    item: image,
    backPath: '/media',
  });

  return (
    <Box>
      {Container({
        children: (
          <>
            {!image ? null : (
              <img
                style={{
                  width: '200px',
                  height: '200px',
                  objectFit: 'contain',
                  marginBottom: '40px',
                }}
                src={`${BASE_URL}/uploads/${image?.uri}`}
                alt={image?.alt}
              />
            )}
            {!createNew ? null : (
              <CustomUploadField file={file} setFile={setFile} accept="image/*" />
            )}
            <CustomInput
              input={title}
              label="Title"
              name="title"
              id="title"
              placeholder="Image title"
            />
            <CustomInput input={alt} label="Alt" name="alt" id="alt" placeholder="Image alt" />
            <CustomInput
              input={blurredAmount}
              label="Blurred amount"
              name="blurred_amount"
              id="blurred_amount"
              disabled={!!image}
              placeholder="Blurred amount"
              type="number"
            />
            <CustomTextarea
              input={copyright}
              label="Copyright"
              name="copyright"
              id="copyright"
              placeholder="Copyright"
            />
          </>
        ),
      })}
    </Box>
  );
}

export default ImageForm;
