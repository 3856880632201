/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import SubscriptionUserLister from '../../Components/SubscriptionUserLister/SubscriptionUserLister';
import TableHeader from '../../common/TableHeader/TableHeader';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import useItemLister from '../../core/hooks/useItemLister';
import useModal from '../../core/hooks/useModal';

const FREE_FORM = {
  endpoint: 'subscription',
  itemPath: 'subscription',
  backPath: '/playground?tab=3',
  withPublish: false,
  schema: [
    {
      name: 'name',
      type: 'string',
      label: 'Name',
      required: true,
    },
    {
      name: 'description',
      type: 'string',
      label: 'Description',
      required: false,
    },
    {
      name: 'default',
      type: 'boolean',
      label: 'Default subscription',
    },
    {
      name: 'overrideHomeSlug',
      type: 'string',
      label: 'Override Home Sequence Slug',
    },
    {
      name: 'journeys',
      label: 'Journeys',
      type: 'array',
      addAllBtn: true,
      addAllPath: '/journeys?populate=pg_modules',
      schema: [
        {
          name: 'journey',
          label: 'Journey',
          type: 'ref',
          entity: 'journey',
          changing: ['pg_modules'],
        },
        {
          name: 'pg_modules',
          label: 'Modules',
          type: 'multiSelect',
          dependency: 'journey',
        },
      ],
    },
    {
      name: 'linked',
      label: 'Linked subscriptions',
      type: 'array',
      schema: [
        {
          name: 'subscription',
          label: 'Linked subscription',
          type: 'ref',
          entity: 'subscription',
        },
      ],
    },
  ],
};

const PAID_FORM = {
  endpoint: 'subscription',
  itemPath: 'subscription',
  backPath: '/playground?tab=3',
  withPublish: false,
  schema: [
    {
      name: 'name',
      type: 'string',
      label: 'Name',
      required: true,
    },
    {
      name: 'description',
      type: 'string',
      label: 'Description',
      required: false,
    },
    {
      name: 'paid_details',
      type: 'object',
      label: 'Paid details',
      schema: [
        {
          name: 'amount',
          type: 'number',
          label: 'Amount ( in EUR incl. VAT )',
          required: false,
        },
        {
          name: 'interval',
          type: 'number',
          label: 'Interval',
          min: 1,
        },
        {
          name: 'intervalType',
          type: 'select',
          label: 'Interval type',
          options: [
            {
              label: 'Monthly',
              value: 'monthly',
            },
            {
              label: 'Yearly',
              value: 'yearly',
            },
          ],
        },
      ],
    },
    {
      name: 'required',
      label: 'Required free subscription',
      type: 'ref',
      entity: 'subscription',
      info: 'Free subscription that is a requirement to have for users to buy this subscription.',
      query: '?paid=null',
    },
    {
      name: 'journeys',
      label: 'Journeys',
      type: 'array',
      schema: [
        {
          name: 'journey',
          label: 'Journey',
          type: 'ref',
          entity: 'journey',
          changing: ['pg_modules'],
        },
        {
          name: 'pg_modules',
          label: 'Modules',
          type: 'multiSelect',
          dependency: 'journey',
        },
      ],
    },
    {
      name: 'linked',
      label: 'Linked subscriptions',
      type: 'array',
      info: 'Linked subscriptions are used to make users of other subscriptions visible to users that have this subscription.',
      schema: [
        {
          name: 'subscription',
          label: 'Linked subscription',
          type: 'ref',
          entity: 'subscription',
        },
      ],
    },
  ],
};

const SUBSCRIPTION_URL = `${process.env.REACT_APP_API_URL}/subscription`;

function Subscription() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: currentUser, token } = useUserContext();
  const { result, doFetch, loading, error } = useFetch(`${SUBSCRIPTION_URL}/${id}`);
  const subscription = useMemo(() => result?.data, [result]);
  const { modal, open, close, isOpen } = useModal();
  const openRef = useRef(null);
  const {
    doFetch: sendUserAddRequest,
    result: sendUserAddResult,
    error: sendUserAddError,
  } = useFetch(`${process.env.REACT_APP_API_URL}/subscriptions/user/assign-subscription`);
  const toast = useToast();
  const { items, Lister, setIsActive, changeSearch } = useItemLister({
    endpointSingle: 'user',
    endpointPlural: 'users?roles=WORLD',
    filters: ['name', 'email'],
  });

  useEffect(() => {
    setIsActive(isOpen);
    changeSearch('');
  }, [isOpen]);

  useEffect(() => {
    if (sendUserAddResult) {
      close();
      toast({
        status: 'success',
        title: sendUserAddResult.message,
        position: 'top',
      });
    }
  }, [sendUserAddResult]);

  useEffect(() => {
    if (error) {
      toast({
        status: 'error',
        title: error.message,
        position: 'top',
      });
    }
    if (sendUserAddError) {
      toast({
        status: 'error',
        title: sendUserAddError.message,
        position: 'top',
      });
    }
  }, [error, sendUserAddError]);

  const addUserToFreeSubscription = useCallback((_id: string) => {
    sendUserAddRequest({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: _id,
        subscription_id: id,
      }),
    });
  }, []);

  const openModal = useCallback(() => {
    if (!openRef.current) return;
    open(openRef.current);
  }, []);

  useEffect(() => {
    if (id === 'new-free') return;
    if (id === 'new-paid') return;
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  const values = useMemo(() => {
    if (result) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { _id, updated, ...data } = result.data;
      return data;
    }
    return {};
  }, [result]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the subscription with id: {id}</AlertTitle>
          </Alert>
        ) : !subscription ? (
          <>
            <h1 className="h3">Create new {id === 'new-free' ? 'free' : 'paid'} subscription</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm {...(id === 'new-free' ? FREE_FORM : PAID_FORM)} />
            </Box>
          </>
        ) : (
          <>
            <h1 className="h3">{subscription.name}</h1>
            <Flex
              flexDirection="column"
              bg="white"
              p={5}
              my={10}
              borderRadius={6}
              boxShadow="lg"
              flex="1 1 auto"
              gap={5}
            >
              {!subscription.paid && (
                <Button ref={openRef} onClick={openModal} alignSelf="flex-end">
                  Add users
                </Button>
              )}
              <RHForm
                itemValues={values}
                id={subscription._id}
                fetchItem={doFetch}
                {...(!subscription.paid ? FREE_FORM : PAID_FORM)}
              />
              <SubscriptionUserLister subscriptionId={id || ''} />
            </Flex>
          </>
        )}
      </Box>
      {modal({
        children: (
          <Box p={5}>
            {Lister({
              children: (
                <Box p={5}>
                  <TableContainer fontSize="1rem" boxShadow="xl">
                    <Table variant="striped" colorScheme="facebook">
                      <TableHeader
                        cols={[
                          {
                            label: 'Name',
                          },
                          {
                            label: 'Email',
                          },
                        ]}
                      />
                      <Tbody>
                        {items?.map((item: any) => (
                          <Tr key={item._id}>
                            <Td p={2} verticalAlign="middle">
                              <button
                                style={{
                                  padding: '6px',
                                  maxWidth: '400px',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                type="button"
                                onClick={() => addUserToFreeSubscription(item._id)}
                              >
                                {item.name}
                              </button>
                            </Td>
                            <Td p={2} verticalAlign="middle">
                              <button
                                style={{
                                  padding: '6px',
                                  maxWidth: '400px',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                type="button"
                                onClick={() => addUserToFreeSubscription(item._id)}
                              >
                                {item.email}
                              </button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              ),
            })}
          </Box>
        ),
      })}
    </section>
  );
}

export default Subscription;
