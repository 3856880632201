import { Box, Button, Heading } from '@chakra-ui/react';

import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import { isEmpty } from '../../core/helpers';
import { useFormInput } from '../../core/hooks/useFormInput';

interface IProps {
  onSubmit: (data: { source: string }) => void;
}

function VideoForm({ onSubmit }: IProps) {
  const source = useFormInput('', (value) => !isEmpty(value));

  const data = {
    source: source.value,
  };

  return (
    <Box p={5}>
      <Heading mb={5}>Add a Video</Heading>
      <CustomForm onSubmit={() => onSubmit(data)} inputs={[source]}>
        <CustomInput
          input={source}
          label="Source"
          name="source"
          id="video-source"
          placeholder="Video source"
          emptyMessage="Field is required"
        />
        <Button colorScheme="messenger" type="submit">
          Add
        </Button>
      </CustomForm>
    </Box>
  );
}

export default VideoForm;
