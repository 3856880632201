/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Spinner, Tag, Wrap, WrapItem } from '@chakra-ui/react';
import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { useFormInput } from '../../core/hooks/useFormInput';
import useItemLister from '../../core/hooks/useItemLister';
import { Tag as ITag } from '../../core/models';
import CustomInput from '../CustomInput/CustomInput';
import OutsideAlerter from '../OutsideAlerter/OutsideAlerter';
import './TagField.css';

interface Props {
  selectedTags: any[];
  setSelectedTags: React.Dispatch<SetStateAction<any[]>>;
  setTouched?: React.Dispatch<SetStateAction<boolean>>;
  endpointSingle: string;
  endpointPlural: string;
  name: string;
  customProperty?: string;
  label: string;
  placeholder: string;
}

function TagField({
  selectedTags,
  setSelectedTags,
  setTouched,
  endpointSingle,
  endpointPlural,
  customProperty = 'name',
  name,
  label,
  placeholder,
}: Props) {
  const {
    items: tags,
    changeSearch,
    setIsActive,
    loading,
  } = useItemLister({
    endpointSingle,
    endpointPlural,
    perPage: 20,
    fixedPage: 1,
    filters: [customProperty],
  });
  const [showOptions, setShowOptions] = useState(false);
  const search = useFormInput('', () => true);

  useEffect(() => {
    changeSearch(search.value);
  }, [search.value]);

  useEffect(() => {
    setIsActive(showOptions);
    changeSearch('');
    search.setValue('');
  }, [showOptions]);

  const addTag = useCallback((el: ITag) => {
    if (setTouched) setTouched(true);
    setSelectedTags((prevState) => [...prevState, el]);
  }, []);

  const tagOptions = useMemo(() => {
    if (!tags) return [];
    const filteredTags = tags.filter(
      (el: ITag) => !selectedTags.map((selectedTag: ITag) => selectedTag._id).includes(el._id),
    );
    return filteredTags.map((el: any) => (
      <button
        className="tag-field__option"
        type="button"
        key={el._id}
        onClick={() => {
          addTag(el);
          setShowOptions(false);
        }}
      >
        {el[customProperty]} <span className="tag-field__option-info">({el._id})</span>
      </button>
    ));
  }, [tags, selectedTags]);

  const handleTagClick = useCallback((id: string) => {
    if (setTouched) setTouched(true);
    setSelectedTags((prevState) => prevState.filter((el) => el !== id));
  }, []);

  return (
    <Box
      bg="white"
      p={5}
      _notLast={{
        mb: 5,
      }}
      borderRadius={6}
      boxShadow="0 0 8px rgba(0,0,0,0.2)"
      flex="1 1 auto"
      className="tag-field"
    >
      <div className="tag-field__container">
        <OutsideAlerter onClick={() => setShowOptions(false)}>
          <CustomInput
            input={search}
            id={name}
            name={name}
            label={label}
            onFocus={() => setShowOptions(true)}
            placeholder={placeholder}
          />
          {!showOptions ? null : loading ? (
            <div className="tag-field__options">
              <Box p={3}>
                <Spinner />
              </Box>
            </div>
          ) : (
            <div className="tag-field__options">{tagOptions}</div>
          )}
        </OutsideAlerter>
      </div>
      {!selectedTags.length ? (
        <Tag>None selected</Tag>
      ) : (
        <Wrap p={3} mx={-3}>
          {selectedTags.map((el) => (
            <WrapItem key={el._id}>
              <Button onClick={() => handleTagClick(el)}>{el[customProperty]}</Button>
            </WrapItem>
          ))}
        </Wrap>
      )}
    </Box>
  );
}

export default TagField;
