import { Box, Button } from '@chakra-ui/react';
import ReactRouterPrompt from 'react-router-prompt';

interface IProps {
  active: boolean;
}

function Prompt({ active }: IProps) {
  return (
    <ReactRouterPrompt
      when={(args) => {
        if (args) {
          return active && args.currentLocation.pathname !== args.nextLocation.pathname;
        }
        return active;
      }}
    >
      {({ isActive, onConfirm, onCancel }) =>
        isActive && (
          <div className="modal">
            <div className="modal__overflow">
              <div className="modal__container" role="dialog">
                <Box p={10}>
                  <h2 className="h3">You have unsaved changes!</h2>
                  <h3 className="h4">Are you sure you want to leave?</h3>
                  <Box mt={5}>
                    <Button colorScheme="facebook" type="button" onClick={onCancel}>
                      Stay
                    </Button>
                    <Button colorScheme="red" type="submit" ml={5} onClick={onConfirm}>
                      Leave
                    </Button>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        )
      }
    </ReactRouterPrompt>
  );
}

export default Prompt;
