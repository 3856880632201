/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import CustomInput from '../../common/CustomInput/CustomInput';
import { useUserContext } from '../../core/context/user.context';
import { isEmpty } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';

function MemberClassForm({
  isEditMode = false,
  ...data
}: {
  refetch?: () => void;
  _id?: string;
  name?: string;
  slug?: string;
  published?: boolean;
  isEditMode?: boolean;
}) {
  const name = useFormInput(data?.name || '', (v) => !isEmpty(v));
  const slug = useFormInput(data?.slug || '', (v) => !isEmpty(v));
  const [publishedValue, setPublishedValue] = useState(
    isEditMode ? (data.published ? 'Unpublish' : 'Publish') : '',
  );
  const {
    doFetch: create,
    result: createResult,
    loading: createLoading,
    error: createError,
  } = useFetch(`${process.env.REACT_APP_API_URL}/member_classes`);
  const {
    doFetch: edit,
    result: editResult,
    loading: editLoading,
    error: editError,
  } = useFetch(`${process.env.REACT_APP_API_URL}/member_class/${data?._id || ''}`);

  const { token } = useUserContext();
  const toast = useToast();

  useEffect(() => {
    if (createResult) {
      toast({
        title: createResult.message,
        position: 'top',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
    if (editResult) {
      toast({
        title: editResult.message,
        position: 'top',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [createResult, editResult]);
  useEffect(() => {
    if (createError) {
      toast({
        title: createError.message,
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    if (editError) {
      toast({
        title: editError.message,
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [createError, editError]);

  return (
    <>
      <Box bg="white" p={5} borderRadius={6} boxShadow="lg">
        <CustomInput
          id="name"
          placeholder="Name"
          name="name"
          type="text"
          input={name}
          label="Name"
        />
        <CustomInput
          id="slug"
          placeholder="Slug"
          name="slug"
          type="text"
          input={slug}
          label="Slug"
        />
      </Box>
      <Button
        onClick={() => {
          const obj = {
            name: name.value,
            slug: slug.value,
          };
          if (isEditMode) {
            edit({
              method: 'PATCH',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(obj),
            });
          } else {
            create({
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(obj),
            });
          }
        }}
        colorScheme="messenger"
        type="submit"
        shadow="lg"
        mt={10}
        isLoading={isEditMode ? editLoading : createLoading}
      >
        {isEditMode ? 'Edit class' : 'Create class'}
      </Button>
      {isEditMode && (
        <Button
          onClick={() => {
            edit({
              method: 'PATCH',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                published: !data.published,
              }),
            }).then((value: any) => {
              if (value.status === 'success') {
                setPublishedValue((p) => (p === 'Unpublish' ? 'Publish' : 'Unpublish'));
              }
            });
          }}
          colorScheme="messenger"
          shadow="lg"
          mt={10}
          isLoading={editLoading}
          ml={5}
        >
          {publishedValue}
        </Button>
      )}
    </>
  );
}

export default MemberClassForm;
