/* eslint-disable @typescript-eslint/no-explicit-any */
type TProps = {
  newPath: string;
  label: string;
  checkError: any;
  path: string;
  getValues: any;
};

export function VATField({ newPath, label, checkError, path, getValues }: TProps) {
  const vatPercentage = getValues(`${path}vat_p`);
  const amount = getValues(`${path}amount`);

  const calc = Number(amount + amount * (vatPercentage * 0.01)).toFixed(2);

  return (
    <>
      <label htmlFor={newPath}>{label}</label>
      <input
        className={`input__field${checkError() ? ' error' : ''}`}
        id={newPath}
        value={calc}
        readOnly
      />
    </>
  );
}
