import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LoginForm from '../../Components/LoginForm/LoginForm';
import { useUserContext } from '../../core/context/user.context';

function Login() {
  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.email) navigate('/');
  }, [user]);

  return (
    <section>
      <Box p={5}>
        <LoginForm />
      </Box>
    </section>
  );
}

export default Login;
