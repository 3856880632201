import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  List,
  ListItem,
  Spinner,
} from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ImageForm from '../../Components/ImageForm/ImageForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import { ImageInterface } from '../../core/models';

const IMAGE_URL = `${process.env.REACT_APP_API_URL}/image`;

function Image() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUserContext();
  const { result, doFetch, loading, error } = useFetch(`${IMAGE_URL}/${id}`);
  const image: ImageInterface = useMemo(() => result?.data, [result]);

  useEffect(() => {
    if (id === 'new') return;
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the image with id: {id}</AlertTitle>
          </Alert>
        ) : !image ? (
          <>
            <h1 className="h3">Upload new image</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <ImageForm createNew={id === 'new'} />
            </Box>
          </>
        ) : (
          <>
            <h1 className="h3">{image.title}</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <ImageForm image={image} fetchImage={doFetch} />
              {image.version < 2 ? null : (
                <List marginTop={6} fontSize={18}>
                  <ListItem>{`Height: ${image.height}px`}</ListItem>
                  <ListItem>{`Width: ${image.width}px`}</ListItem>
                  <ListItem>{`Filesize: ${(image.filesize / 1000).toFixed(0)}kB`}</ListItem>
                </List>
              )}
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default Image;
