/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  Spinner,
  Stat,
  StatHelpText,
  StatLabel,
} from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const FORM = {
  endpoint: 'article',
  itemPath: 'article',
  backPath: '/content?tab=6',
  withPublish: true,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
    },
    {
      name: 'authors',
      type: 'tagsField',
      label: 'Authors',
      endpointSingle: 'users/member',
      endpointPlural: 'users/members?community_roles=authors',
      placeHolder: 'Select author',
    },
    {
      name: 'img',
      type: 'img',
      labe: 'Image',
    },
    {
      name: 'summary',
      type: 'textarea',
      label: 'Summary',
    },
    {
      name: 'body',
      type: 'markdown',
      label: 'Body',
    },
    {
      name: 'tags',
      type: 'tagsField',
      label: 'Tags',
      endpointSingle: 'tag',
      endpointPlural: 'tags',
      placeHolder: 'Select tags',
    },
    {
      name: 'seo',
      type: 'object',
      label: 'SEO',
      schema: [
        {
          name: 'title',
          type: 'string',
          label: 'Title',
        },
        {
          name: 'description',
          type: 'textarea',
          label: 'Description',
        },
      ],
    },
  ],
};

const ARTICLE_URL = `${process.env.REACT_APP_API_URL}/article`;

function Arcticle() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUserContext();
  const { result, doFetch, loading, error } = useFetch(
    `${ARTICLE_URL}/${id}?populate=authors&populate=tags`,
  );
  const article = useMemo(() => result?.data, [result]);

  useEffect(() => {
    if (id === 'new') return;
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  const values = useMemo(() => {
    if (result) {
      const data = {
        title: result.data.title,
        slug: result.data.slug,
        authors: result.data.authors,
        img: result.data.img,
        summary: result.data.summary,
        body: result.data.body,
        tags: result.data.tags,
        seo: result.data.seo,
        published: result.data.published,
      };
      return data;
    }
    return {};
  }, [result]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the article with id: {id}</AlertTitle>
          </Alert>
        ) : !article ? (
          <>
            <h1 className="h3">Create new article</h1>
            <Flex my={10} gap={10} direction={{ base: 'column', '2xl': 'row' }}>
              <Box bg="white" p={5} borderRadius={6} boxShadow="lg" flex="1 1 auto">
                <RHForm {...FORM} />
              </Box>
              <Box bg="white" p={5} borderRadius={6} boxShadow="lg" flex="0 0 400px">
                <h2>Info</h2>
              </Box>
            </Flex>
          </>
        ) : (
          <>
            <h1 className="h3">{article.title}</h1>
            <div className="h4">{article.slug}</div>
            <Flex my={10} gap={10} direction={{ base: 'column', '2xl': 'row' }}>
              <Box bg="white" p={5} borderRadius={6} boxShadow="lg" flex="1 1 auto">
                <RHForm itemValues={values} id={article._id} fetchItem={doFetch} {...FORM} />
              </Box>
              <Box bg="white" p={5} borderRadius={6} boxShadow="lg" flex="0 0 400px">
                <h2>Info</h2>
                <Divider mt={1} mb={2} />
                <Stat>
                  <StatLabel>Author</StatLabel>
                  <StatHelpText>{article.author}</StatHelpText>
                </Stat>
              </Box>
            </Flex>
          </>
        )}
      </Box>
    </section>
  );
}

export default Arcticle;
