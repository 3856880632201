import { Button, Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import EmailConfigurationEditor from '../../Components/EmailConfigurationEditor/EmailConfigurationEditor';
import ItemTabs from '../../common/ItemTabs/ItemTabs';

function EmailSettings() {
  const tabs = useMemo(
    () => [
      {
        label: 'Configuration',
        component: <EmailConfigurationEditor />,
      },
    ],
    [],
  );

  return (
    <section>
      <ItemTabs path="email" tabs={tabs} />
    </section>
  );
}

export default EmailSettings;
