import { Box, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useFetch from '../../core/hooks/useFetch';

function EmailConfirm() {
  const { hash } = useParams();
  const { doFetch, loading, result } = useFetch(
    `${process.env.REACT_APP_API_URL}/user/change-email/${hash}`,
  );

  useEffect(() => {
    doFetch({
      method: 'POST',
    });
  }, [doFetch]);

  return (
    <section>
      <Box p={5}>
        <h1 className="h3">Email changed</h1>
        {!result ? (
          !loading ? null : (
            <Spinner mt={5} />
          )
        ) : (
          <Box mt={5}>
            <h2 className="h4">Your new email address is {result.data.email}</h2>
          </Box>
        )}
      </Box>
    </section>
  );
}

export default EmailConfirm;
