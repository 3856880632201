import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Heading, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import { ILog } from '../../core/models';

const BASE_URL = `${process.env.REACT_APP_API_URL}/log`;

function Log() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUserContext();
  const { result, doFetch, loading, error } = useFetch(`${BASE_URL}/${id}`);
  const data: ILog = useMemo(() => {
    if (!result?.data) return null;
    return result.data;
  }, [result]);

  useEffect(() => {
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  return (
    <Box>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      {!loading ? null : <Spinner />}
      {!error ? null : (
        <Alert status="error" my={10}>
          <AlertIcon />
          <AlertTitle>Could not load the log with id: {id}</AlertTitle>
        </Alert>
      )}
      {!data ? null : (
        <Box>
          <Heading>{data.source}</Heading>
          <Heading fontSize={18} opacity={0.8}>
            {new Date(data.created).toLocaleString('en-gb', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Heading>
          {!data.logs.length ? null : (
            <Box mt={10} p={5} backgroundColor="#2b3241" borderRadius={8} minHeight={600}>
              {data.logs.map((log) => (
                <Heading
                  fontSize={18}
                  mb={2}
                  fontWeight={500}
                  color={
                    log.status === 'success'
                      ? '#3eff3e'
                      : log.status === 'error'
                      ? '#ff4545'
                      : 'white'
                  }
                  fontFamily="'Inconsolata', sans-serif"
                  key={log.message}
                >
                  {log.message}
                </Heading>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default Log;
