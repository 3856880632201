/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import {
  CWorldAudience,
  CWorldCommitment,
  CWorldFAQ,
  CWorldImage,
  CWorldIntroduction,
  CWorldMission,
  CWorldOpenAI,
  CWorldPageBanner,
  CWorldProduct,
  CWorldResult,
  CWorldSupport,
  CWorldTestimonials,
  CWorldTitle,
} from '../../core/components';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const SIMPLE_FORM = {
  endpoint: 'page',
  itemPath: 'world-page',
  backPath: '/world?tab=2',
  withPublish: true,
  schema: [
    {
      name: 'world_page',
      type: 'boolean',
      label: '',
      hidden: true,
      defaultValue: true,
    },
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
      required: true,
    },
    {
      name: 'body',
      type: 'object',
      label: '',
      schema: [
        {
          name: 'seo',
          type: 'object',
          label: 'SEO',
          schema: [
            {
              name: 'title',
              type: 'string',
              label: 'Title',
            },
            {
              name: 'description',
              type: 'textarea',
              label: 'Description',
            },
            {
              name: 'canonical',
              type: 'string',
              label: 'Canonical',
            },
            {
              name: 'image_id',
              type: 'img',
              label: 'Image',
            },
          ],
        },
      ],
    },
  ],
};
const FULL_FORM = {
  endpoint: 'page',
  itemPath: 'world-page',
  backPath: '/world?tab=2',
  withPublish: true,
  schema: [
    {
      name: 'world_page',
      type: 'boolean',
      label: '',
      hidden: true,
      defaultValue: true,
    },
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
      required: true,
    },
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      options: [
        {
          label: 'Default',
          value: 'default',
        },
        {
          label: 'Simple',
          value: 'simple',
        },
      ],
      defaultValue: 'default',
      changing: ['body'],
    },
    {
      name: 'body',
      type: 'object',
      label: '',
      condition: ['type'],
      conditionValues: ['default'],
      schema: [
        CWorldPageBanner,
        {
          name: 'pageBuilder',
          type: 'component',
          label: 'Page Builder',
          components: [
            CWorldCommitment,
            CWorldMission,
            CWorldProduct,
            CWorldAudience,
            CWorldTestimonials,
            CWorldFAQ,
            CWorldTitle,
            CWorldResult,
            CWorldOpenAI,
            CWorldIntroduction,
            CWorldImage,
            CWorldSupport,
          ],
        },
        {
          name: 'seo',
          type: 'object',
          label: 'SEO',
          schema: [
            {
              name: 'title',
              type: 'string',
              label: 'Title',
            },
            {
              name: 'description',
              type: 'textarea',
              label: 'Description',
            },
            {
              name: 'canonical',
              type: 'string',
              label: 'Canonical',
            },
            {
              name: 'image_id',
              type: 'img',
              label: 'Image',
            },
          ],
        },
      ],
    },
    {
      name: 'body',
      type: 'object',
      label: '',
      condition: ['type'],
      conditionValues: ['simple'],
      schema: [
        {
          name: 'pageBuilder',
          type: 'component',
          label: 'Page Builder',
          components: [
            CWorldCommitment,
            CWorldMission,
            CWorldProduct,
            CWorldAudience,
            CWorldTestimonials,
            CWorldFAQ,
            CWorldTitle,
            CWorldResult,
            CWorldOpenAI,
            CWorldIntroduction,
            CWorldImage,
            CWorldSupport,
          ],
        },
        {
          name: 'seo',
          type: 'object',
          label: 'SEO',
          schema: [
            {
              name: 'title',
              type: 'string',
              label: 'Title',
            },
            {
              name: 'description',
              type: 'textarea',
              label: 'Description',
            },
            {
              name: 'canonical',
              type: 'string',
              label: 'Canonical',
            },
            {
              name: 'image_id',
              type: 'img',
              label: 'Image',
            },
          ],
        },
      ],
    },
  ],
};
const PAGE_URL = `${process.env.REACT_APP_API_URL}/page`;
const SIMPLE_PAGE_ARRAY = ['world-general-data', 'world-home-page'];

function WorldPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUserContext();
  const { result, doFetch, loading, error } = useFetch(`${PAGE_URL}/${id}`);
  const page = useMemo(() => result?.data, [result]);

  useEffect(() => {
    if (id === 'new') return;
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  const form = useMemo(
    () =>
      !result?.data?.slug || SIMPLE_PAGE_ARRAY.includes(result.data.slug) ? SIMPLE_FORM : FULL_FORM,
    [result?.data?.slug],
  );

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the page with id: {id}</AlertTitle>
          </Alert>
        ) : !page ? (
          <>
            <h1 className="h3">Create new page</h1>
            <Box bg="white" my={10} p={5} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm {...SIMPLE_FORM} />
            </Box>
          </>
        ) : (
          <>
            <h1 className="h3">{page.title}</h1>
            <div className="h4">{page.slug}</div>
            <Box bg="white" my={10} p={5} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={page} id={page._id} fetchItem={doFetch} {...form} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default WorldPage;
