/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-unsafe-optional-chaining */
import { CloseIcon, CopyIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Spinner,
  Switch,
  Tag,
  Text,
  useToast,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { encode } from 'js-base64';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import CustomInput from '../../common/CustomInput/CustomInput';
import OutsideAlerter from '../../common/OutsideAlerter/OutsideAlerter';
import '../../common/TagField/TagField.css';
import { useUserContext } from '../../core/context/user.context';
import { useFormInput } from '../../core/hooks/useFormInput';
import useItemLister from '../../core/hooks/useItemLister';
import { UserSubscription } from '../../core/models';

interface Props {
  onChange?: (value: UserSubscription[]) => void;
  value?: UserSubscription[];
  setTouched?: React.Dispatch<React.SetStateAction<boolean>>;
  getValues: UseFormGetValues<any>;
}

function SubscriptionInput({ onChange = () => {}, value = [], setTouched, getValues }: Props) {
  const { items, setIsActive, changeSearch, loading } = useItemLister({
    endpointSingle: 'subscription',
    endpointPlural: 'subscriptions?paid=null',
    perPage: 20,
    fixedPage: 1,
    filters: ['subscription'],
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const search = useFormInput('', () => true);
  const [selected, setSelected] = useState<UserSubscription[]>(value || []);
  const { user } = useUserContext();
  const toast = useToast();

  useEffect(() => {
    changeSearch(search.value);
  }, [search.value]);

  useEffect(() => {
    setIsActive(showOptions);
    changeSearch('');
    search.setValue('');
  }, [showOptions]);

  const addItem = useCallback(
    (el: any) => {
      if (setTouched) {
        setTouched(true);
      }
      setSelected((prevState) => [
        ...prevState,
        {
          subscription: {
            _id: el?._id,
            name: el?.name,
            paid: el?.paid,
          },
          ...(el?.paid
            ? {
                autoRenewal: true,
                paymentDetails: el?.paymentMethod,
                discountRuns: el?.discountRuns,
                discountCode: el?.discountCode,
              }
            : { nonExpiring: true }),
          active: true,
          status: {
            color: 'green',
            message: 'Active',
            date: new Date(),
          },
        },
      ]);
    },
    [items],
  );

  const tagOptions = useMemo(() => {
    if (!items) return [];
    const filteredItems =
      items.filter(
        (el: any) => !selected?.map((item) => item.subscription?._id).find((e) => e === el._id),
      ) || [];
    return filteredItems?.map((el: any) => (
      <button
        className="tag-field__option"
        type="button"
        key={el._id}
        onClick={() => {
          addItem(el);
          setShowOptions(false);
        }}
      >
        {el.name} <span className="tag-field__option-info">({el?._id})</span>
      </button>
    ));
  }, [items, selected]);

  const handleItemRemoval = useCallback((id: string) => {
    if (setTouched) setTouched(true);
    setSelected((prevState) => prevState.filter((el) => el.subscription?._id !== id));
  }, []);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  return (
    <Box
      bg="white"
      p={5}
      _notLast={{
        mb: 5,
      }}
      borderRadius={6}
      boxShadow="0 0 8px rgba(0,0,0,0.2)"
      flex="1 1 auto"
      className="tag-field"
    >
      <div className="tag-field__container">
        <OutsideAlerter onClick={() => setShowOptions(false)}>
          <CustomInput
            input={search}
            id="subscriptions"
            name="subscriptions"
            label="Subscriptions"
            onFocus={() => setShowOptions(true)}
            placeholder="Subscriptions"
          />
          {!showOptions ? null : loading ? (
            <div className="tag-field__options">
              <Box p={3}>
                <Spinner />
              </Box>
            </div>
          ) : (
            <div className="tag-field__options">{tagOptions}</div>
          )}
        </OutsideAlerter>
      </div>
      <Flex flexDirection="column">
        {selected?.map((el: any, i: number) => (
          <Box
            bg="white"
            p={5}
            _notLast={{
              mb: 5,
            }}
            borderRadius={6}
            boxShadow="0 0 8px rgba(0,0,0,0.2)"
            flex="1 1 auto"
            display="flex"
            flexDirection="column"
            gap={4}
            className="tag-field"
            key={el.subscription?._id}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Flex gap={3} alignItems="center" flexDirection="row">
                {el?.subscription?.paid ? (
                  <Badge variant="solid" colorScheme="yellow">
                    Paid
                  </Badge>
                ) : (
                  <Badge variant="solid" colorScheme="blackAlpha">
                    Free
                  </Badge>
                )}
                <Flex gap={2} alignItems="center" flexDirection="row">
                  <Text fontSize={18}>{el?.subscription?.name}</Text>
                  <Badge variant="solid" colorScheme={el?.status?.color}>
                    {el?.status?.message}
                  </Badge>
                  <Text fontSize="small">
                    {dayjs(el?.status?.date).format('DD-MM-YYYY HH:mm:ss')}
                  </Text>
                </Flex>
              </Flex>
              {!el.subscription?.paid && (
                <IconButton
                  onClick={() => handleItemRemoval(el.subscription?._id)}
                  size="xs"
                  aria-label="close"
                  icon={<CloseIcon />}
                />
              )}
            </Flex>
            {el.subscription?.paid && (
              <Flex gap={2}>
                <Text>Shopper reference: </Text>
                <Tag>{el.shopperReference}</Tag>
                <CopyIcon
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(el.shopperReference);
                    toast({
                      title: 'Copied shopper reference',
                      position: 'top',
                      status: 'success',
                      duration: 2000,
                      isClosable: true,
                    });
                  }}
                />
              </Flex>
            )}
            <FormControl display="flex" alignItems="center">
              <Switch
                onChange={(e) => {
                  if (setTouched) setTouched(true);
                  setSelected((prevState: any) => {
                    const newState = [...prevState];
                    const elIdx = newState.findIndex(
                      (pe: any) => pe.subscription?._id === el.subscription?._id,
                    );
                    if (elIdx < 0) return prevState;
                    newState[elIdx] = {
                      subscription: prevState[elIdx].subscription,
                      active: e.target.checked,
                      nonExpiring: prevState[elIdx].nonExpiring,
                      paymentDetails: prevState[elIdx].paymentDetails,
                      autoRenewal: prevState[elIdx].autoRenewal,
                      discountCode: prevState[elIdx]?.discountCode,
                      shopperReference: prevState[elIdx].shopperReference,
                      discountRuns: prevState[elIdx]?.discountRuns,
                      status: {
                        color: e.target.checked ? 'green' : 'red',
                        message: e.target.checked ? 'Active' : 'Inactive, set by admin',
                        date: new Date(),
                      },
                    };
                    return newState;
                  });
                }}
                isChecked={el?.active}
                size="lg"
                id={`active-switch-${i}`}
              />
              <FormLabel htmlFor={`active-switch-${i}`} mb="0" ml="2">
                Active
              </FormLabel>
            </FormControl>
            {!el?.subscription?.paid ? (
              <FormControl display="flex" alignItems="center">
                <Switch
                  onChange={(e) => {
                    if (setTouched) setTouched(true);
                    setSelected((prevState: any) => {
                      const newState = [...prevState];
                      const elIdx = newState.findIndex(
                        (pe: any) => pe.subscription?._id === el.subscription?._id,
                      );
                      if (elIdx < 0) return prevState;
                      newState[elIdx] = {
                        subscription: prevState[elIdx].subscription,
                        active: prevState[elIdx].active,
                        nonExpiring: e.target.checked,
                        status: prevState[elIdx].status,
                      };
                      return newState;
                    });
                  }}
                  isChecked={el?.nonExpiring}
                  size="lg"
                  id={`non-expiring-switch-${i}`}
                />
                <FormLabel htmlFor={`non-expiring-switch-${i}`} mb="0" ml="2">
                  Non-expiring
                </FormLabel>
              </FormControl>
            ) : (
              <FormControl display="flex" alignItems="center">
                <Switch
                  onChange={(e) => {
                    if (setTouched) setTouched(true);
                    setSelected((prevState: any) => {
                      const newState = [...prevState];
                      const elIdx = newState.findIndex(
                        (pe: any) => pe.subscription?._id === el.subscription?._id,
                      );
                      if (elIdx < 0) return prevState;
                      newState[elIdx] = {
                        subscription: prevState[elIdx].subscription,
                        active: prevState[elIdx].active,
                        autoRenewal: e.target.checked,
                        shopperReference: prevState[elIdx].shopperReference,
                        status: prevState[elIdx].status,
                        discountCode: prevState[elIdx]?.discountCode,
                        discountRuns: prevState[elIdx]?.discountRuns,
                        paymentDetails: prevState[elIdx].paymentDetails,
                      };
                      return newState;
                    });
                  }}
                  isChecked={el?.autoRenewal}
                  size="lg"
                  id={`auto-renewal-switch-${i}`}
                />
                <FormLabel htmlFor={`auto-renewal-switch-${i}`} mb="0" ml="2">
                  Auto Renewal
                </FormLabel>
              </FormControl>
            )}
            {el?.subscription?.paid && 'discountCode' in el && el?.discountCode.length > 0 && (
              <Box style={{ display: 'flex', flexDirection: 'column', gap: 20 }} width={500}>
                <Flex flexDirection="column" gap={2}>
                  <Text fontSize={19}>Discount details:</Text>
                  <Flex flexDirection="row" justifyContent="space-between">
                    <Text>Discount runs</Text>
                    <Text>
                      {el?.discountRuns} ({' '}
                      {el?.discountCode && el?.discountCode?.length > 0
                        ? el?.discountCode[0]?.runs - el?.discountRuns
                        : 0}{' '}
                      left )
                    </Text>
                  </Flex>
                  <Flex flexDirection="row" justifyContent="space-between">
                    <Text>Discount code</Text>
                    <Text>
                      {el?.discountCode && el?.discountCode.length > 0 && (
                        // <Input
                        //   onChange={(e) => {
                        //     if (setTouched) setTouched(true);
                        //     setSelected((prevState: any) => {
                        //       const newState = [...prevState];
                        //       const elIdx = newState.findIndex(
                        //         (pe: any) => pe.subscription?._id === el.subscription?._id,
                        //       );
                        //       if (elIdx < 0) return prevState;
                        //       newState[elIdx] = {
                        //         subscription: prevState[elIdx].subscription,
                        //         active: prevState[elIdx].active,
                        //         autoRenewal: prevState[elIdx].autoRenewal,
                        //         status: prevState[elIdx].status,
                        //         discountCode: prevState[elIdx]?.discountCode,
                        //         discountRuns: prevState[elIdx]?.discountRuns,
                        //         // newDiscountCode: e.target.value,
                        //         paymentDetails: prevState[elIdx].paymentDetails,
                        //       };
                        //       return newState;
                        //     });
                        //   }}
                        //   value={el?.newDiscountCode || el?.discountCode[0].code}
                        // />
                        <Text>{el.discountCode[0]?.code}</Text>
                      )}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            )}
            {el?.subscription?.paid && el?.paymentDetails && (
              <Box style={{ display: 'flex', flexDirection: 'column', gap: 20 }} width={500}>
                <Flex flexDirection="column" gap={2}>
                  <Text fontSize={19}>Payment details:</Text>
                  {el?.paymentDetails?.lastPayment && (
                    <Flex flexDirection="row" justifyContent="space-between">
                      <Text>Last payment</Text>
                      <Text>
                        {dayjs(el?.paymentDetails?.lastPayment).format('DD-MM-YYYY HH:mm:ss')}
                      </Text>
                    </Flex>
                  )}
                  {el?.paymentDetails?.nextPayment && (
                    <Flex flexDirection="row" justifyContent="space-between">
                      <Text>Next payment</Text>
                      <Text>
                        {dayjs(el?.paymentDetails?.nextPayment).format('DD-MM-YYYY HH:mm:ss')}
                      </Text>
                    </Flex>
                  )}
                  {el?.paymentDetails?.method && (
                    <Flex flexDirection="row" justifyContent="space-between">
                      <Text>Payment method</Text>
                      <Text>
                        {el?.paymentDetails?.method?.lastFour
                          ? `${el?.paymentDetails?.method?.brand.toUpperCase()} card ending in ${
                              el?.paymentDetails?.method?.lastFour
                            }`
                          : el?.paymentDetails?.method?.brand}
                      </Text>
                    </Flex>
                  )}
                  {el?.paymentDetails?.endDate && (
                    <Flex flexDirection="row" justifyContent="space-between">
                      <Text>End date</Text>
                      <Text>
                        {dayjs(el?.paymentDetails?.endDate).format('DD-MM-YYYY HH:mm:ss')}
                      </Text>
                    </Flex>
                  )}
                </Flex>
                {(pathname === '/profile' ||
                  (pathname.includes('user') && pathname.split('/').length === 3)) &&
                  user && (
                    <Button
                      onClick={() =>
                        navigate(
                          `/payment-transactions?subscription=${encode(
                            JSON.stringify({
                              subscription: el?.subscription?._id,
                              label: el?.subscription?.name,
                            }),
                          )}&user=${
                            pathname === '/profile'
                              ? encode(
                                  JSON.stringify({
                                    user_id: user._id,
                                    label: `${user.firstname} ${user.lastname}`,
                                  }),
                                )
                              : encode(
                                  JSON.stringify({
                                    user_id: pathname.split('/')[2],
                                    label: `${getValues()?.firstname} ${getValues()?.lastname}`,
                                  }),
                                )
                          }`,
                        )
                      }
                    >
                      View payment history
                    </Button>
                  )}
              </Box>
            )}
            {!el?.subscription?.paid && !el?.nonExpiring && (
              <FormControl>
                <FormLabel mb="0" ml="2">
                  Expiry date
                </FormLabel>
                <Input
                  onChange={(e) => {
                    if (setTouched) setTouched(true);
                    setSelected((prevState: any) => {
                      const newState = [...prevState];
                      const elIdx = newState.findIndex(
                        (pe: any) => pe.subscription?._id === el.subscription?._id,
                      );
                      if (elIdx < 0) return prevState;
                      newState[elIdx] = {
                        subscription: prevState[elIdx].subscription,
                        active: prevState[elIdx].active,
                        nonExpiring: prevState[elIdx].nonExpiring,
                        status: prevState[elIdx].status,
                        expirationDate: e.target.value,
                      };
                      return newState;
                    });
                  }}
                  placeholder="Select Date and Time"
                  size="md"
                  value={el?.expirationDate}
                  type="datetime-local"
                />
              </FormControl>
            )}
          </Box>
        ))}
      </Flex>
    </Box>
  );
}

export default SubscriptionInput;
