import { Outlet } from 'react-router-dom';

import Navigation from './Components/Navigation/Navigation';
import ScrollToTop from './Components/ScrollToTop';
import './style/base.css';
import './style/buttons.css';
import './style/inputs.css';
import './style/reset.css';
import './style/typography.css';
import './style/variables.css';

function App() {
  return (
    <div className="app">
      <ScrollToTop />
      <Navigation />
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
}

export default App;
