import { Checkbox, Td } from '@chakra-ui/react';
import { useCallback } from 'react';

interface IProps {
  id: string;
  bulkSelection: string[];
  setBultSelection: React.Dispatch<React.SetStateAction<string[]>>;
  disabled?: boolean;
}

function BulkCheckbox({ id, bulkSelection, setBultSelection, disabled = false }: IProps) {
  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setBultSelection((prevState) => [...prevState, id]);
    } else {
      setBultSelection((prevState) => prevState.filter((el) => el !== id));
    }
  }, []);

  return (
    <Td py={3} px={3} verticalAlign="middle" width={1}>
      <Checkbox
        defaultChecked={bulkSelection.includes(id)}
        size="lg"
        colorScheme="messenger"
        borderColor="blackAlpha.600"
        onChange={handleChange}
        width={1}
        disabled={disabled}
      >
        <span className="sr-only">Select item</span>
      </Checkbox>
    </Td>
  );
}

export default BulkCheckbox;
