import BrainsnackLister from '../../Components/BrainsnackLister/BrainsnackLister';
import CircleLister from '../../Components/CircleLister/CircleLister';
import DiscountLister from '../../Components/DiscountLister/DiscountLister';
import JourneyLister from '../../Components/JourneyLister/JourneyLister';
import ModuleLister from '../../Components/ModuleLister/ModuleLister';
import PgQuestionLister from '../../Components/PgQuestionLister/PgQuestionLister';
import QuoteList from '../../Components/QuoteLister/QuoteLister';
import ReservationsLister from '../../Components/ReservationsLister/ReservationsLister';
import SequenceLister from '../../Components/SequenceLister/SequenceLister';
import SlotsLister from '../../Components/SlotsLister/SlotsLister';
import SubscriptionLister from '../../Components/SubscriptionLister/SubscriptionLister';
import ItemTabs from '../../common/ItemTabs/ItemTabs';

const TABS = [
  { label: 'Module', component: <ModuleLister /> },
  { label: 'Sequence', component: <SequenceLister /> },
  { label: 'Journey', component: <JourneyLister /> },
  { label: 'Subscription', component: <SubscriptionLister /> },
  { label: 'Discount', component: <DiscountLister /> },
  { label: 'Questions', component: <PgQuestionLister /> },
  { label: 'Circles', component: <CircleLister /> },
  { label: 'Quote', component: <QuoteList /> },
  { label: 'Brainsnack', component: <BrainsnackLister /> },
  { label: 'Slots', component: <SlotsLister /> },
  { label: 'Reservations', component: <ReservationsLister /> },
];

function Playground() {
  return (
    <section>
      <ItemTabs path="playground" tabs={TABS} />
    </section>
  );
}

export default Playground;
