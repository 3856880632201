/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const FORM = {
  endpoint: 'industry',
  itemPath: 'industry',
  backPath: '/content?tab=9',
  withPublish: true,
  schema: [
    {
      name: 'name',
      type: 'string',
      label: 'Name',
      required: true,
    },
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
      required: true,
    },
  ],
};

const INDUSTRY_URL = `${process.env.REACT_APP_API_URL}/industry`;

function Industry() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUserContext();
  const { result, doFetch, loading, error } = useFetch(`${INDUSTRY_URL}/${id}`);
  const industry = useMemo(() => result?.data, [result]);

  useEffect(() => {
    if (id === 'new') return;
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  const values = useMemo(() => {
    if (result) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id: _, _id, updated, ...data } = result.data;
      return data;
    }
    return {};
  }, [result]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the industry with id: {id}</AlertTitle>
          </Alert>
        ) : !industry ? (
          <>
            <h1 className="h3">Create new industry</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm {...FORM} />
            </Box>
          </>
        ) : (
          <>
            <h1 className="h3">{industry.name}</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={values} id={industry._id} fetchItem={doFetch} {...FORM} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default Industry;
