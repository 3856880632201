/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner, Tag } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const SEQUENCE_URL = `${process.env.REACT_APP_API_URL}/pg_sequence`;

const TEXTAREA_ARRAY_SCHEMA = [
  {
    name: '',
    type: 'textarea',
  },
];

const STRING_ARRAY_SCHEMA = [
  {
    name: '',
    type: 'string',
  },
];

const ICON_ID_OPTIONS = [
  {
    label: 'Continue',
    value: 'buttonContinue',
  },

  {
    label: 'Shuffle',
    value: 'iconShuffle',
  },
  {
    label: 'Back',
    value: 'iconPageBack',
  },
  {
    label: 'Quit',
    value: 'iconQuit',
  },
  {
    label: 'Arrow right',
    value: 'iconToRight',
  },
  {
    label: 'Question mark',
    value: 'iconQuestion',
  },
  {
    label: 'Check',
    value: 'iconCheck',
  },
  {
    label: 'Cog',
    value: 'iconCog',
  },
  {
    label: 'Edit',
    value: 'iconEdit',
  },
  {
    label: 'Leave Call',
    value: 'iconLeaveCall',
  },
  {
    label: 'Play',
    value: 'iconPlay',
  },
  {
    label: 'Youtube',
    value: 'iconYouTube',
  },
  {
    label: 'Trashcan',
    value: 'iconRemove',
  },
  {
    label: 'User',
    value: 'iconUser',
  },
  {
    label: 'Photo camera',
    value: 'iconPhoto',
  },
  {
    label: 'Lock',
    value: 'iconLocked',
  },
  {
    label: 'Copy',
    value: 'iconCopy',
  },
  {
    label: 'Send message',
    value: 'iconSendMsg',
  },
];

const COMPARISON_OPTIONS = [
  {
    label: 'Equals =',
    value: 'equals',
  },
  {
    label: 'Not equals !=',
    value: 'not_equals',
  },
  {
    label: 'Greater or equal >=',
    value: 'greaterthan_or_equal',
  },
  {
    label: 'Smaller or equal <=',
    value: 'smallerthan_or_equal',
  },
  {
    label: 'Greater >',
    value: 'greater',
  },
  {
    label: 'Smaller <',
    value: 'smaller',
  },
  {
    label: 'Data Exists',
    value: 'exists',
  },
  {
    label: 'Data does not Exist',
    value: 'not_exist',
  },
];

const IMAGE_TREASURE_TYPES = [
  {
    label: 'Resilience Altar',
    value: 'resilience_altar',
  },
  {
    label: 'Passion Object',
    value: 'passion_object',
  },
];

const TEXT_TREASURE_TYPES = [
  {
    label: 'Courageous Conversation Learning',
    value: 'cc_learning',
  },
  {
    label: 'Sphere of Excellence',
    value: 'sphere_of_excellence',
  },
  {
    label: 'NMTP poem',
    value: 'NMTP poem',
  },
];

const SOE_ITEM_TYPES = [
  {
    label: 'Strength',
    value: 'strength',
  },
  {
    label: 'Value',
    value: 'value',
  },
  {
    label: 'Passion',
    value: 'passion',
  },
];

const DONE_CONDITION_OPTIONS = [
  {
    label: 'ReadyCheck Checked',
    value: 'readycheck',
  },
  {
    label: 'Button Clicked',
    value: 'button_clicked',
  },
  {
    label: 'Soe Item Canvas Edit',
    value: 'soe_item_canvas_edit',
  },
  {
    label: 'Step Time',
    value: 'step_time',
  },
  {
    label: 'Timer Passed',
    value: 'timer_time_passed',
  },
  {
    label: 'Timer Overtime Passed',
    value: 'timer_overtime_passed',
  },
  {
    label: 'Question Completed',
    value: 'question_completed',
  },
  {
    label: 'List Selection Completed',
    value: 'list_selection_completed',
  },
  {
    label: 'Brainsnack Completed (depricated, use Page Text Completed instead)',
    value: 'brainsnack_completed',
  },
  {
    label: 'Paged Text Completed',
    value: 'paged_text_completed',
  },
  {
    label: 'Droparea Filled (all)',
    value: 'droparea_filled',
  },
  {
    label: 'Droparea Filled (any)',
    value: 'droparea_filled_any',
  },
  {
    label: 'RoomNode Clicked',
    value: 'roomnode_clicked',
  },
  {
    label: 'User Text Typed',
    value: 'user_text_typed',
  },
  {
    label: 'User Text Placed',
    value: 'user_text_placed',
  },
  {
    label: 'Quote Closed',
    value: 'quote_closed',
  },
  {
    label: 'AI Controller Ready',
    value: 'ai_controller_ready',
  },
  {
    label: 'Image Picker Ready',
    value: 'image_picker_ready',
  },
  {
    label: 'Image Upload Ready',
    value: 'image_upload_ready',
  },
  {
    label: 'NMPT Profile Complete',
    value: 'nmtp_completed',
  },
  {
    label: 'Reservation Request Success',
    value: 'reservation_request_success',
  },
  {
    label: 'Sequence Data Completed (depricated, use controller ready instead)',
    value: 'sequence_data_completed',
  },
  {
    label: 'Room Data Value',
    value: 'data_value',
  },
  {
    label: 'Social Mission All Reservations Count',
    value: 'reservation_count',
  },
  {
    label: 'Accepted Social Mission Count',
    value: 'accepted_reservation_count',
  },
  {
    label: 'Controller Ready',
    value: 'controller_ready',
  },
  {
    label: 'Controller Error',
    value: 'controller_error',
  },
  {
    label: 'Api Response Ready',
    value: 'api_response_success',
  },
  {
    label: 'Api Response Error',
    value: 'api_response_error',
  },
  {
    label: 'Journey added to selection',
    value: 'journey_selected',
  },
  {
    label: 'Sphere of Excellence top3 complete',
    value: 'soe_top3_complete',
  },
  {
    label: 'Number of room users',
    value: 'room_user_count',
  },
  {
    label: 'Onboarding Completed',
    value: 'onboarding_completed',
  },
  {
    label: 'Videocall Custom Permissions Success',
    value: 'videocall_permissions_success',
  },
  {
    label: 'Videocall Custom Permissions Error',
    value: 'videocall_permissions_error',
  },
];

const DONE_CONDITION = [
  {
    name: 'type',
    label: 'Type',
    type: 'select',
    changing: ['comparison', 'numberValue', 'stringValue', 'boolValue', 'actionId'],
    options: DONE_CONDITION_OPTIONS,
  },
  {
    name: 'comparison',
    label: 'Comparison',
    type: 'select',
    options: COMPARISON_OPTIONS,
    condition: ['type'],
    conditionValues: [
      'step_time',
      'reservation_count',
      'accepted_reservation_count',
      'nmtp_completed',
      'data_value',
      'room_user_count',
    ],
  },
  {
    name: 'numberValue',
    type: 'number',
    label: 'Number value',
    condition: ['type'],
    conditionValues: [
      'step_time',
      'reservation_count',
      'accepted_reservation_count',
      'data_value',
      'room_user_count',
    ],
  },
  {
    name: 'stringValue',
    type: 'string',
    label: 'String value',
    condition: ['type'],
    conditionValues: ['data_value'],
  },
  {
    name: 'boolValue',
    type: 'boolean',
    label: 'Bool value',
    condition: ['type'],
    conditionValues: ['nmtp_completed', 'soe_top3_complete', 'onboarding_completed'],
  },
  {
    name: 'actionId',
    type: 'string',
    label: 'Action ID',
    condition: ['type'],
    conditionValues: [
      'readycheck',
      'button_clicked',
      'timer_time_passed',
      'timer_overtime_passed',
      'question_completed',
      'list_selection_completed',
      'brainsnack_completed',
      'paged_text_completed',
      'droparea_filled',
      'roomnode_clicked',
      'quote_closed',
      'ai_controller_ready',
      'image_picker_ready',
      'image_upload_ready',
      'api_response_success',
      'api_response_error',
      'reservation_request_success',
      'controller_ready',
      'controller_error',
      'sequence_data_completed',
      'reservation_count',
      'accepted_reservation_count',
      'soe_item_canvas_edit',
      'data_value',
      'soe_top3_complete',
      'videocall_permissions_error',
      'videocall_permissions_success',
    ],
  },
  {
    name: 'index',
    type: 'number',
    label: 'Index',
  },
  {
    name: 'increaseSteps',
    type: 'number',
    label: 'Increase steps',
  },
  {
    name: 'toStepIndex',
    type: 'number',
    label: 'To step index',
  },
  {
    name: 'toStepName',
    type: 'string',
    label: 'To step name',
  },
];

const SHOW_CONDITION = {
  name: 'showCondition',
  label: 'showCondition',
  type: 'object',
  collapsible: true,
  schema: [
    {
      name: 'type',
      label: 'type',
      type: 'select',
      options: [
        {
          label: 'Brainsnack Completed (depricated, use Paged Text Completed instead)',
          value: 'brainsnack_complete',
        },
        {
          label: 'Paged Text Completed',
          value: 'paged_text_completed',
        },
        {
          label: 'Droparea Filled (all)',
          value: 'droparea_filled',
        },
        {
          label: 'Droparea Filled (at least 1 or the amount in Value)',
          value: 'droparea_filled_any',
        },
        {
          label: 'Question Completed',
          value: 'question_completed',
        },
      ],
      changing: ['value'],
    },
    {
      name: 'actionId',
      label: 'actionId',
      type: 'array',
      schema: STRING_ARRAY_SCHEMA,
    },
    {
      name: 'value',
      label: 'Value',
      type: 'number',
      condition: ['type'],
      conditionValues: ['droparea_filled_any'],
    },
  ],
};

const CANVAS_FOCUS = {
  name: 'focus',
  label: 'Focus',
  type: 'object',
  collapsible: true,
  schema: [
    {
      name: 'positionX',
      label: 'X Position',
      type: 'number',
    },
    {
      name: 'positionY',
      label: 'Y Position',
      type: 'number',
    },
    {
      name: 'width',
      label: 'width',
      type: 'number',
    },
    {
      name: 'height',
      label: 'height',
      type: 'number',
    },
    {
      name: 'priorityAnchor',
      label: 'When not enough space, which part needs to be in view?',
      type: 'select',
      options: [
        {
          label: 'center',
          value: 'center',
        },
        {
          label: 'top-center',
          value: 'top-center',
        },
        {
          label: 'top-left',
          value: 'top-left',
        },
      ],
    },
  ],
};

const REFRAME_SCHEMA = [
  {
    name: 'negativeCoreBelief',
    label: 'negativeCoreBelief',
    type: 'string',
  },
  {
    name: 'newCoreBelief',
    label: 'newCoreBelief',
    type: 'string',
  },
  {
    name: 'supportingBeliefs',
    label: 'supportingBeliefs',
    type: 'array',
    schema: [
      {
        name: '',
        type: 'string',
      },
    ],
  },
  {
    name: 'oppositeBeliefs',
    label: 'oppositeBeliefs',
    type: 'array',
    schema: [
      {
        name: '',
        type: 'string',
      },
    ],
  },
  {
    name: 'literalOppositeBeliefs',
    label: 'literalOppositeBeliefs',
    type: 'array',
    schema: [
      {
        name: '',
        type: 'string',
      },
    ],
  },
  {
    name: 'variantOppositeBeliefs',
    label: 'variantOppositeBeliefs',
    type: 'array',
    schema: [
      {
        name: '',
        type: 'string',
      },
    ],
  },
  {
    name: 'extremeOppositeBeliefs',
    label: 'extremeOppositeBeliefs',
    type: 'array',
    schema: [
      {
        name: '',
        type: 'string',
      },
    ],
  },
];

const ACTION_TYPES = [
  {
    value: 'create_object',
    label: 'Create object',
  },
  {
    value: 'change_object',
    label: 'Change object',
  },
  {
    value: 'disappear_object',
    label: 'Disappear object',
  },
  {
    value: 'disappear_objects',
    label: 'Disappear Multiple Objects',
  },
  {
    value: 'disappear_step_objects',
    label: 'Disappear All Objects Of Steps',
  },
  {
    value: 'settings',
    label: 'Settings',
  },
  {
    value: 'background',
    label: 'Background',
  },
  {
    value: 'background_music',
    label: 'Background Music',
  },
  {
    value: 'clean_user_texts',
    label: 'Clean User Texts',
  },
  {
    value: 'profile_update',
    label: 'Profile Update',
  },
  {
    value: 'userdata_update',
    label: 'User Data Update',
  },
  {
    value: 'module_start_part',
    label: 'Start Module Part',
  },
  {
    value: 'module_complete_part',
    label: 'Complete Module Part',
  },
  {
    value: 'start_sequence',
    label: 'Start Sequence',
  },
  {
    value: 'navigate',
    label: 'Navigate',
  },
  {
    value: 'module_collection',
    label: 'Module Collection',
  },
  {
    value: 'save_reframe',
    label: 'Save Reframe',
  },
  {
    value: 'create_antiprofile_description',
    label: 'Create Antiprofile Description',
  },
  {
    value: 'upload_image',
    label: 'Upload Image',
  },
  {
    value: 'set_variable',
    label: 'Set Variable',
  },
];

const OBJECT_TYPES = [
  {
    value: 'readycheck',
    label: 'Ready Check Button',
  },
  {
    value: 'textobject',
    label: 'Text Object',
  },
  {
    value: 'anchor',
    label: 'Anchor',
  },
  {
    value: 'canvas2',
    label: 'Canvas (us the other one)',
  },
  {
    value: 'canvas',
    label: 'Canvas (infinite)',
  },
  {
    value: 'image_object',
    label: 'Image Object',
  },
  {
    value: 'infographic',
    label: 'Infographic',
  },
  {
    value: 'image_picker',
    label: 'Image Picker',
  },
  {
    value: 'image_treasure',
    label: 'Image Treasure',
  },
  {
    value: 'text_treasure',
    label: 'Text Treasure',
  },
  {
    value: 'profile_node',
    label: 'Profile Node',
  },
  {
    value: 'room_node',
    label: 'Room Node',
  },
  {
    value: 'social_room_node',
    label: 'Social Room Node',
  },
  {
    value: 'live_session_node',
    label: 'Live Session Node',
  },
  {
    value: 'module_node',
    label: 'Module Node',
  },
  {
    value: 'journey_node',
    label: 'Journey Node',
  },
  {
    value: 'usertext',
    label: 'User Text',
  },
  {
    value: 'video',
    label: 'Video Player',
  },
  {
    value: 'videocall',
    label: 'Video Call',
  },
  {
    value: 'videocall_custom',
    label: 'Custom Video Call (low level UI)',
  },
  {
    value: 'button',
    label: 'Button',
  },
  {
    value: 'droparea',
    label: 'Droparea',
  },
  {
    value: 'timer',
    label: 'Timer',
  },
  {
    value: 'question',
    label: 'Question',
  },
  {
    value: 'user_item',
    label: 'User Item',
  },
  {
    value: 'stepindicator',
    label: 'Step Indicator',
  },
  {
    value: 'brainsnack',
    label: 'Brainsnack (depricated, use Paged Text instead)',
  },
  {
    value: 'paged_text',
    label: 'Paged Text',
  },
  {
    value: 'audio_player',
    label: 'Audio Player',
  },
  {
    value: 'link',
    label: 'Link',
  },
  {
    value: 'module_view',
    label: 'Module View',
  },
  {
    value: 'journey_view',
    label: 'Journey View',
  },
  {
    value: 'avatar_visual',
    label: 'Avatar Visual',
  },
  {
    value: 'instruction_bar',
    label: 'Instruction Bar',
  },
  {
    value: 'gallery_view',
    label: 'Gallery View',
  },
  {
    value: 'quote',
    label: 'Quote',
  },
  {
    value: 'rest_values',
    label: 'Rest Values',
  },
  {
    value: 'reframe',
    label: 'Reframe Object',
  },
  {
    value: 'reservation_node',
    label: 'Social Reservation Node',
  },
  {
    value: 'leadership_object',
    label: 'Leadership Object',
  },
  {
    value: 'resilience_canvas',
    label: 'Resilience Canvas',
  },
  {
    value: 'resilience_altar',
    label: 'Resilience Altar',
  },
  {
    value: 'ai_controller',
    label: 'AI Controller',
  },
  {
    value: 'soe_controller',
    label: 'Sphere of Excellence Controller',
  },
  {
    value: 'soe_item_canvas',
    label: 'Sphere of Excellence Item Canvas',
  },
  {
    value: 'soe_item',
    label: 'Sphere of Excellence Item',
  },
  {
    value: 'image_upload_controller',
    label: 'Image Upload Controller',
  },
  {
    value: 'reservation_collection',
    label: 'Reservation Collection',
  },
  {
    value: 'reservation_request',
    label: 'Reservation Request',
  },
  {
    value: 'sequence_data_controller',
    label: 'SequenceData Controller',
  },
  {
    value: 'breakout_controller',
    label: 'Break-Out Controller',
  },
];

const DROP_AREA_OBJECT_TYPES = [
  {
    value: 'textobject',
    label: 'Text Object',
  },
  {
    value: 'image_object',
    label: 'Image Object',
  },
  {
    value: 'soe_item',
    label: 'Sphere of Excellence Item',
  },
  {
    value: 'infographic',
    label: 'Infographic',
  },
  {
    value: 'profile_node',
    label: 'Profile Node',
  },
  {
    value: 'room_node',
    label: 'Room Node',
  },
  {
    value: 'social_room_node',
    label: 'Social Room Node',
  },
  {
    value: 'module_node',
    label: 'Module Node',
  },
  {
    value: 'journey_node',
    label: 'Journey Node',
  },
  {
    value: 'usertext',
    label: 'User Text',
  },
  {
    value: 'video',
    label: 'Video Player',
  },

  {
    value: 'timer',
    label: 'Timer',
  },

  {
    value: 'user_item',
    label: 'User Item',
  },

  {
    value: 'brainsnack',
    label: 'Brainsnack',
  },
  {
    value: 'audio_player',
    label: 'Audio Player',
  },
  {
    value: 'link',
    label: 'Link',
  },

  {
    value: 'rest_values',
    label: 'Rest Values',
  },
  {
    value: 'reframe',
    label: 'Reframe Object',
  },
  {
    value: 'leadership_object',
    label: 'Leadership Object',
  },
  {
    value: 'resilience_canvas',
    label: 'Resilience Canvas',
  },
  {
    value: 'resilience_altar',
    label: 'Resilience Altar',
  },
];

const ENTITY_PROPS = {
  name: 'entityProps',
  label: 'Entity Properties',
  type: 'object',
  collapsible: true,
  schema: [
    {
      name: 'parentActionId',
      label: 'Parent Action Id',
      type: 'string',
    },
    {
      name: 'positionX',
      label: 'positionX',
      type: 'number',
    },
    {
      name: 'positionY',
      label: 'positionY',
      type: 'number',
    },
    {
      name: 'relativePosX',
      label: 'Relative position X',
      type: 'number',
    },
    {
      name: 'relativePosY',
      label: 'Relative position Y',
      type: 'number',
    },
    {
      name: 'anchoredPivotX',
      label: 'Anchored Pivot X',
      type: 'number',
    },
    {
      name: 'anchoredPivotY',
      label: 'Anchored Pivot Y',
      type: 'number',
    },
    {
      name: 'moveMode',
      label: 'Move Mode',
      type: 'select',
      options: [
        {
          label: 'default',
          value: '',
        },
        {
          label: 'kinetic',
          value: 'kinetic',
        },
        {
          label: 'anchored',
          value: 'anchored',
        },
        {
          label: 'static',
          value: 'static',
        },
      ],
    },
    {
      name: 'style',
      label: 'Style',
      type: 'string',
    },
    {
      name: 'requestTextInput',
      label: 'Show Cursor Text Input',
      type: 'boolean',
    },
    {
      name: 'disableAutoSize',
      label: 'Disable Auto Size',
      type: 'boolean',
    },
    {
      name: 'connections',
      label: 'connections',
      type: 'array',
      schema: [
        {
          name: 'entityActionId',
          label: 'entityActionId',
          type: 'string',
          required: true,
        },
        {
          name: 'type',
          label: 'type',
          type: 'string',
          required: true,
        },
        {
          name: 'style',
          label: 'style',
          type: 'string',
          required: true,
        },
        {
          name: 'myAttachpoints',
          label: 'my attachpoint to use (leave empty for all)',
          type: 'string',
        },
        {
          name: 'otherAttachpoints',
          label: 'attachpoint on otherobject to use (leave empty for all)',
          type: 'string',
        },
      ],
    },
  ],
};

const READYCHECK_PROPS_SCHEMA = [
  {
    name: 'label',
    label: 'Label',
    type: 'string',
  },
  {
    name: 'readyRule',
    label: 'What amount of users need to be ready?',
    type: 'select',
    options: [
      {
        value: 'all_users',
        label: 'All users need to be ready',
      },
      {
        value: 'user_percentage',
        label: 'A percentage of users',
      },
      {
        value: 'user_count',
        label: 'A specific number of users',
      },
    ],
  },
  {
    name: 'userPercentage',
    label: 'Percentage of users',
    type: 'number',
    condition: ['readyRule'],
    conditionValues: ['user_percentage'],
  },
  {
    name: 'userCount',
    label: 'number of users',
    type: 'number',
    condition: ['readyRule'],
    conditionValues: ['user_count'],
  },
  SHOW_CONDITION,

  ENTITY_PROPS,
];

const ANCHOR_PROPS_SCHEMA = [ENTITY_PROPS];

const CANVAS_PROPS_SCHEMA = [
  CANVAS_FOCUS,
  {
    name: 'focusOverridePerClient',
    label: 'Focus Override Per Client',
    type: 'array',
    schema: [
      {
        name: 'clientIndex',
        label: 'Client Index',
        type: 'number',
      },
      CANVAS_FOCUS,
    ],
  },
  {
    name: 'relativeScreenFocusPointX',
    label: 'Relative Screen Focus Point X',
    type: 'number',
  },
  {
    name: 'relativeScreenFocusPointY',
    label: 'Relative Screen Focus Point Y',
    type: 'number',
  },
];

const IMAGE_OBJECT_PROPS_SCHEMA = [
  {
    name: 'apiUri',
    label: 'Image (from CMS)',
    type: 'imgFileName',
  },
  {
    name: 'imageUri',
    label: 'External Image',
    type: 'string',
  },
  {
    name: 'clientImageId',
    label: 'clientImageId',
    type: 'string',
  },
  {
    name: 'noHover',
    label: 'noHover',
    type: 'boolean',
  },
  {
    name: 'label',
    label: 'Label',
    type: 'string',
  },
  {
    name: 'maxImageWidthEm',
    label: 'maxImageWidthEm',
    type: 'number',
  },
  {
    name: 'minImageWidthEm',
    label: 'minImageWidthEm',
    type: 'number',
  },
  {
    name: 'maxImageHeightEm',
    label: 'maxImageHeightEm',
    type: 'number',
  },
  {
    name: 'fixedSize',
    label: 'Fixed size',
    type: 'boolean',
  },
  {
    name: 'backgroundHexColor',
    label: 'backgroundHexColor',
    type: 'string',
  },
  ENTITY_PROPS,
];

const INFOGRAPHIC_PROPS_SCHEMA = [
  {
    name: 'apiUri',
    label: 'Image (from CMS)',
    type: 'imgFileName',
  },
  {
    name: 'widthEm',
    label: 'Image Width (Em)',
    type: 'number',
  },
  {
    name: 'textWidthEm',
    label: 'Text Width (Em)',
    type: 'number',
  },
  {
    name: 'backgroundHexColor',
    label: 'backgroundHexColor',
    type: 'string',
  },
  {
    name: 'boxedText',
    label: 'Boxed Text',
    type: 'boolean',
  },
  {
    name: 'defaultText',
    label: 'default text',
    type: 'textarea',
  },
  {
    name: 'horTextOffsetEm',
    label: 'Horizontal TextOffset (em) (below zero = to the left)',
    type: 'number',
  },
  {
    name: 'vertTextOffsetEm',
    label: 'Vertical TextOffset (em)',
    type: 'number',
  },
  {
    name: 'showBorder',
    label: 'Show Hotspot Border',
    type: 'boolean',
  },
  {
    name: 'hotspots',
    label: 'hotspots',
    type: 'array',
    schema: [
      {
        name: 'shape',
        label: 'shape',
        type: 'select',
        options: [
          {
            label: 'circle',
            value: 'circle',
          },
          {
            label: 'rectangle',
            value: 'rectangle',
          },
        ],
      },
      {
        name: 'relativePositionX',
        label: 'relative X-Position',
        type: 'number',
      },
      {
        name: 'relativePositionY',
        label: 'relative Y-Position',
        type: 'number',
      },
      {
        name: 'relativeRadius',
        label: 'radius (relative to image width)',
        type: 'number',
      },
      {
        name: 'relativeWidth',
        label: 'width (relative to image width)',
        type: 'number',
      },
      {
        name: 'relativeHeight',
        label: 'height (relative to image width)',
        type: 'number',
      },
      {
        name: 'text',
        label: 'text',
        type: 'textarea',
      },
    ],
  },

  ENTITY_PROPS,
];

const IMAGE_PICKER_PROPS_SCHEMA = [
  {
    name: 'imgUploadTitle',
    label: 'imgUploadTitle',
    type: 'string',
  },
  {
    name: 'dataId',
    label: 'DataId Filename',
    type: 'string',
  },
  {
    name: 'imageIdDataId',
    label: 'DataId ImageId',
    type: 'string',
  },
  ENTITY_PROPS,
];

const PROFILE_NODE_SCHEMA = [
  {
    name: 'showImageType',
    label: 'Which image is shown?',
    type: 'select',
    options: [
      {
        label: 'Not My Typical Profile',
        value: 'not_my_typical_profile',
      },
      {
        label: 'Profile Picture',
        value: 'profile_picture',
      },
    ],
  },
  {
    name: 'sequenceSlug',
    label: 'Link to sequence slug',
    type: 'string',
  },
  {
    name: 'infoWhenNoImage',
    label: 'Info when no image',
    type: 'string',
  },
  {
    name: 'allowEdit',
    label: 'Allow edit profile image',
    type: 'boolean',
  },
  ENTITY_PROPS,
];

const ROOM_NODE_PROPS_SCHEMA = [
  {
    name: 'label',
    label: 'Label',
    type: 'string',
  },
  {
    name: 'iconId',
    label: 'iconId',
    type: 'string',
  },
  {
    name: 'contentId',
    label: 'contentId',
    type: 'string',
  },
  {
    name: 'style',
    label: 'Style',
    type: 'select',
    options: [
      {
        label: 'none',
        value: '',
      },
      {
        label: 'Default',
        value: 'default',
      },
      {
        label: 'Big',
        value: 'big',
      },
      {
        label: 'Circle',
        value: 'circle',
      },
    ],
  },
  {
    name: 'showCondition',
    label: 'showCondition',
    type: 'object',
    collapsible: true,
    schema: [
      {
        name: 'moduleSlug',
        label: 'moduleSlug',
        type: 'string',
      },
      {
        name: 'partId',
        label: 'partId',
        type: 'string',
      },
      {
        name: 'msg',
        label: 'msg',
        type: 'string',
      },
    ],
  },

  ENTITY_PROPS,
];
const SOCIAL_ROOM_NODE_PROPS_SCHEMA = [
  {
    name: 'label',
    label: 'Label',
    type: 'string',
  },
  {
    name: 'iconId',
    label: 'iconId',
    type: 'string',
  },
  {
    name: 'contentId',
    label: 'contentId',
    type: 'string',
  },
  {
    name: 'info',
    label: 'info',
    type: 'string',
  },
  {
    name: 'dateTime',
    label: 'Date and Time info',
    type: 'string',
  },
  {
    name: 'isOpen',
    label: 'Room is open to join',
    type: 'boolean',
  },
  {
    name: 'date',
    label: 'When does the circle starts?',
    type: 'date',
  },
  {
    name: 'durationMinutes',
    label: 'How long is the event in minutes? (0 will only show start time)',
    type: 'number',
  },
  {
    name: 'socialRoomHostIds',
    type: 'array',
    label: 'Social Room Host Ids',
    schema: [
      {
        name: '',
        type: 'ref',
        entity: 'worldUser',
        label: 'Users',
        required: true,
      },
    ],
  },
  ENTITY_PROPS,
];

const LIVE_SESSION_NODE_SCHEMA = [
  {
    name: 'label',
    label: 'Label',
    type: 'string',
  },
  {
    name: 'imageUri',
    label: 'Image Uri',
    type: 'imgFileName',
  },
  {
    name: 'sequenceSlug',
    label: 'sequenceSlug',
    type: 'string',
  },
  {
    name: 'info',
    label: 'info',
    type: 'string',
  },
  {
    name: 'dateTimeStart',
    label: 'Session start time',
    type: 'date',
  },
  {
    name: 'dateTimeClose',
    label: 'Session end time',
    type: 'date',
  },
  {
    name: 'openBeforeStartDuration',
    label: 'How long before the start time can user join?',
    type: 'number',
  },
  {
    name: 'socialRoomHostIds',
    type: 'array',
    label: 'Social Room Host Ids',
    schema: [
      {
        name: '',
        type: 'ref',
        entity: 'worldUser',
        label: 'Users',
        required: true,
      },
    ],
  },
  ENTITY_PROPS,
];

//TODO: is this a CMS object?
const MODULE_NODE_PROPS_SCHEMA = [
  {
    name: 'label',
    label: 'Label',
    type: 'string',
  },
  {
    name: 'moduleSlug',
    label: 'moduleSlug',
    type: 'string',
  },
  {
    name: 'imageUri',
    label: 'Image (from CMS)',
    type: 'imgFileName',
  },
  {
    name: 'sequenceSlug',
    label: 'sequenceSlug',
    type: 'string',
  },
  {
    name: 'linkedToModuleNodeId',
    label: 'linkedToModuleNodeId',
    type: 'string',
  },
  {
    name: 'unlocked',
    label: 'unlocked',
    type: 'string',
  },
  ENTITY_PROPS,
];

//TODO: is this a CMS object?
const JOURNEY_NODE_PROPS_SCHEMA = [
  {
    name: 'lastActivity',
    label: 'Journey with last activity',
    type: 'boolean',
    changing: ['journeySlug'],
  },
  {
    name: 'loadByJourneySlug',
    label: 'Journey Slug',
    type: 'string',
    condition: ['lastActivity'],
    conditionValues: [false],
  },
  ENTITY_PROPS,
];

const USERTEXT_PROPS_SCHEMA = [
  {
    name: 'text',
    label: 'Text',
    type: 'string',
  },
  {
    name: 'removeWhenEmpty',
    label: 'Do not create when empty',
    type: 'boolean',
  },
  ENTITY_PROPS,
];

const VIDEO_PROPS_SCHEMA = [
  {
    name: 'uri',
    label: 'uri',
    type: 'string',
  },
  {
    name: 'imageUri',
    label: 'Image Uri',
    type: 'string',
  },
  {
    name: 'bunnyId',
    label: 'Bunny Id',
    type: 'string',
  },
  {
    name: 'sizeEm',
    label: 'sizeEm',
    type: 'string',
  },
  {
    name: 'fixedSize',
    label: 'Fixed Size',
    type: 'boolean',
  },
  {
    name: 'autoPlay',
    label: 'Auto Play',
    type: 'boolean',
  },
  {
    name: 'loop',
    label: 'Loop',
    type: 'boolean',
  },
  {
    name: 'style',
    label: 'style',
    type: 'select',
    options: [
      {
        label: 'default',
        value: 'default',
      },
      {
        label: 'polaroid',
        value: 'polaroid',
      },
      {
        label: 'fullscreen',
        value: 'fullscreen',
      },
    ],
  },
  {
    name: 'name',
    label: 'name',
    type: 'string',
  },
  {
    name: 'title',
    label: 'title',
    type: 'string',
  },
  ENTITY_PROPS,
];

const VIDEOCALL_PROPS_SCHEMA = [
  {
    name: 'layout',
    label: 'Layout',
    type: 'select',
    options: [
      {
        label: 'Full Screen Speaker',
        value: 'fullscreen',
      },
      {
        label: 'Full Screen Front (no typing)',
        value: 'fullscreen_front',
      },
      {
        label: 'Right',
        value: 'right',
      },
      {
        label: 'Full Screen Tiled',
        value: 'multi_user',
      },
      {
        label: 'Full Screen Tiled & Right for hosts',
        value: 'multiuser_hosts_right',
      },
    ],
  },
  {
    name: 'screenSharing',
    type: 'select',
    label: 'Screen Sharing',
    options: [
      {
        value: '',
        label: 'Unavailable',
      },
      {
        value: 'only_hosts',
        label: 'Only Hosts',
      },
      {
        value: 'everyone',
        label: 'everyone',
      },
    ],
  },
];

const VIDEOCALL_CUSTOM_PROPS_SCHEMA = [
  {
    name: 'state',
    label: 'state',
    type: 'select',
    options: [
      {
        value: 'no_streams',
        label: 'No Streams',
      },
      {
        value: 'local_stream_only',
        label: 'Only local stream',
      },
      {
        value: 'join_conference',
        label: 'Join Conference',
      },
    ],
  },
  ENTITY_PROPS,
];

const BUTTON_PROPS_SCHEMA = [
  {
    name: 'label',
    label: 'Label',
    type: 'string',
  },
  {
    name: 'iconId',
    label: 'iconId',
    type: 'select',
    options: ICON_ID_OPTIONS,
  },
  {
    name: 'minWidthEm',
    label: 'Minimum Width Em',
    type: 'number',
  },
  {
    name: 'buttonAction',
    label: 'buttonAction',
    type: 'select',
    options: [
      {
        label: 'none',
        value: '',
      },
      {
        label: 'Navigate Back',
        value: 'nav_back',
      },
      {
        label: 'Navigate Home',
        value: 'nav_home',
      },
      {
        label: 'External Link - New Tab (always start with https://)',
        value: 'external_link',
      },
      {
        label: 'Local Link - New Tab (relative to local base url)',
        value: 'local_link_blank',
      },
      {
        label: 'Local Link - Same Tab (relative to local base url)',
        value: 'local_link',
      },
      {
        label: 'Local Playground Link - New Tab (relative to pg subdomain)',
        value: 'local_link_pg_blank',
      },
      {
        label: 'Local Playground Link - Same Tab (relative to pg subdomain)',
        value: 'local_link_pg',
      },
      {
        label: 'Open Brainsnack',
        value: 'open_brainsnack',
      },
      {
        label: 'Show Profile Edit Modal',
        value: 'show_profile',
      },
      {
        label: 'Log Out',
        value: 'logout',
      },
    ],
  },
  {
    name: 'buttonActionValue',
    label: 'buttonActionValue',
    type: 'string',
  },
  SHOW_CONDITION,
  {
    name: 'showLoader',
    label: 'showLoader',
    type: 'boolean',
  },
  {
    name: 'showHint',
    label: 'Show Text On Hover',
    type: 'textarea',
  },
  {
    name: 'hintPosition',
    label: 'Position of the Hover Text',
    type: 'select',
    options: [
      {
        label: 'Top',
        value: 'top',
      },
      {
        label: 'Bottom',
        value: 'bottom',
      },
      {
        label: 'Left',
        value: 'left',
      },
      {
        label: 'Right',
        value: 'right',
      },
    ],
  },
  ENTITY_PROPS,
];
const DROPAREA_PROPS_SCHEMA = [
  {
    name: 'label',
    label: 'Label',
    type: 'string',
  },
  {
    name: 'defaultWidthEm',
    label: 'defaultWidthEm',
    type: 'number',
  },
  {
    name: 'defaultHeightEm',
    label: 'defaultHeightEm',
    type: 'number',
  },
  {
    name: 'dataId',
    label: 'dataId',
    type: 'string',
  },
  {
    name: 'defaultDataValue',
    label: 'Default Data Value (set to dataId when no object is attached)',
    type: 'string',
  },
  {
    name: 'attachedActionId',
    label: 'Attached Object Action Id',
    type: 'string',
  },
  {
    name: 'acceptObjectTypes',
    label: 'Accept Object Types',
    type: 'array',
    schema: [
      {
        name: '',
        type: 'select',
        options: DROP_AREA_OBJECT_TYPES,
      },
    ],
  },
  {
    name: 'type',
    label: 'type (depricated, use Accept Object Types instead)',
    type: 'select',
    options: [
      {
        label: 'none',
        value: '',
      },
      {
        label: 'text',
        value: 'text',
      },
      {
        label: 'user_item',
        value: 'user_item',
      },
      {
        label: 'image_object',
        value: 'image_object',
      },
    ],
  },
  {
    name: 'characterLimit',
    label: 'characterLimit',
    type: 'number',
  },
  {
    name: 'showCharacterLimit',
    label: 'At which length should the character limit show?',
    type: 'number',
  },
  {
    name: 'typeHintOnHover',
    label: 'Show the type-cursor on hover',
    type: 'boolean',
  },
  {
    name: 'showLabelOnAttach',
    label: 'Show label when attached',
    type: 'boolean',
  },
  {
    name: 'labelAlignment',
    label: 'Label Alignment',
    type: 'select',
    options: [
      {
        value: 'left',
        label: 'left',
      },
      {
        value: 'center',
        label: 'center',
      },
      {
        value: 'right',
        label: 'right',
      },
    ],
  },

  ENTITY_PROPS,
];
const TIMER_PROPS_SCHEMA = [
  {
    name: 'manualStart',
    label: 'manualStart',
    type: 'boolean',
  },
  {
    name: 'enableAbsoluteTime',
    label: 'Count down to absolute time',
    type: 'boolean',
    changing: ['absoluteTime', 'duration', 'startDelay'],
  },
  {
    name: 'duration',
    label: 'duration',
    type: 'number',
    condition: ['enableAbsoluteTime'],
    conditionValues: [false],
  },
  {
    name: 'absoluteTime',
    label: 'Absolute end time',
    type: 'date',
    condition: ['enableAbsoluteTime'],
    conditionValues: [true],
  },
  {
    name: 'startDelay',
    label: 'startDelay',
    type: 'number',
    condition: ['enableAbsoluteTime'],
    conditionValues: [false],
  },
  {
    name: 'overtime',
    label: 'overtime',
    type: 'number',
  },
  {
    name: 'startText',
    label: 'startText',
    type: 'string',
  },
  {
    name: 'timeUpText',
    label: 'timeUpText',
    type: 'string',
  },
  {
    name: 'overtimeUpText',
    label: 'overtimeUpText',
    type: 'string',
  },
  ENTITY_PROPS,
];

const SETTINGS_PROPS_SCHEMA = [
  {
    name: 'logoUri',
    label: 'Logo',
    type: 'imgFileName',
  },
  {
    name: 'helpUri',
    label: 'Help Url',
    type: 'string',
  },
];

const QUESTION_PROPS_SCHEMA = [
  {
    name: 'text',
    label: 'text',
    type: 'array',
    schema: STRING_ARRAY_SCHEMA,
  },
  {
    name: 'dataId',
    label: 'dataId',
    type: 'array',
    schema: STRING_ARRAY_SCHEMA,
  },
  {
    name: 'answerRestrictionMin',
    label: 'answerRestrictionMin',
    type: 'number',
  },
  {
    name: 'answerRestrictionMax',
    label: 'answerRestrictionMax',
    type: 'number',
  },
  {
    name: 'applyLabel',
    label: 'applyLabel',
    type: 'string',
  },
  {
    name: 'autoApply',
    label: 'Auto Apply',
    type: 'boolean',
  },
  {
    name: 'startValue',
    label: 'startValue',
    type: 'string',
  },
  {
    name: 'audio',
    label: 'audio',
    type: 'string',
  },
  {
    name: 'feedback',
    label: 'feedback',
    type: 'array',
    collapsible: true,
    schema: [
      {
        name: 'answerCondition',
        label: 'answerCondition',
        type: 'array',
        schema: [
          {
            name: '',
            type: 'number',
          },
        ],
      },
      {
        name: 'text',
        label: 'text',
        type: 'string',
      },
      {
        name: 'audio',
        label: 'audio',
        type: 'string',
      },
    ],
  },
  {
    name: 'showSliderLabel',
    label: 'showSliderLabel',
    type: 'boolean',
  },
  {
    name: 'dataValues',
    label: 'dataValues',
    type: 'string',
  },

  ENTITY_PROPS,
];

const USER_ITEM_PROPS_SCHEMA = [
  {
    name: 'dbUserId',
    label: 'dbUserId',
    type: 'string',
  },
  {
    name: 'firstName',
    label: 'firstName',
    type: 'string',
  },
  {
    name: 'lastName',
    label: 'lastName',
    type: 'string',
  },
  {
    name: 'nmtpImageUri',
    label: 'nmtpImageUri',
    type: 'string',
  },
  {
    name: 'nmtpImageUri_t',
    label: 'nmtpImageUri_t',
    type: 'string',
  },
  {
    name: 'email',
    label: 'email',
    type: 'string',
  },
  ENTITY_PROPS,
];
const STEPINDICATOR_PROPS_SCHEMA = [
  {
    name: 'stepCount',
    label: 'stepCount',
    type: 'number',
  },
  {
    name: 'distBetweenSteps',
    label: 'Distance between steps',
    type: 'number',
  },
  {
    name: 'stepSize',
    label: 'Step Size',
    type: 'number',
  },
  {
    name: 'activeStepIndex',
    label: 'Active Step Index (starts at 0)',
    type: 'number',
  },
  {
    name: 'lastCompletedStepIndex',
    label: 'lastCompletedStepIndex (starts at 0)',
    type: 'number',
  },
  {
    name: 'useEntityProps',
    label: 'Custom position (when false, indicator is shown at top of screen)',
    type: 'boolean',
  },
  ENTITY_PROPS,
];
const PAGED_TEXT_PROPS_SCHEMA = [
  {
    name: 'text',
    label: 'text',
    type: 'array',
    schema: TEXTAREA_ARRAY_SCHEMA,
  },
  {
    name: 'title',
    label: 'Title',
    type: 'string',
  },
  {
    name: 'audio',
    label: 'audio',
    type: 'array',
    schema: STRING_ARRAY_SCHEMA,
  },
  SHOW_CONDITION,
  {
    name: 'widthEm',
    label: 'widthEm',
    type: 'number',
  },
  ENTITY_PROPS,
];

const LINK_PROPS_SCHEMA = [
  {
    name: 'text',
    label: 'text',
    type: 'string',
  },
  {
    name: 'link',
    label: 'link',
    type: 'string',
  },
  {
    name: 'showQR',
    label: 'showQR',
    type: 'boolean',
  },
  ENTITY_PROPS,
];

const MODULE_VIEW_PROPS_SCHEMA = [
  {
    name: 'moduleSlug',
    label: 'Module Slug',
    type: 'string',
  },
  {
    name: 'newStyle',
    label: 'New style view',
    type: 'boolean',
  },
  ENTITY_PROPS,
];

const JOURNEY_VIEW_PROPS_SCHEMA = [
  {
    name: 'journeySlug',
    label: 'Journey Slug',
    type: 'string',
  },
  ENTITY_PROPS,
];

const AVATAR_VISUAL_PROPS_SCHEMA = [
  {
    name: 'distFromCenter',
    label: 'distFromCenter',
    type: 'number',
  },
  {
    name: 'label2',
    label: 'label2',
    type: 'array',
    schema: STRING_ARRAY_SCHEMA,
  },
  ENTITY_PROPS,
];
const INSTUCTION_BAR_PROPS_SCHEMA = [
  {
    name: 'heightEm',
    label: 'heightEm',
    type: 'number',
  },
  {
    name: 'preparation',
    label: 'preparation',
    type: 'array',
    schema: [
      {
        name: '',
        type: 'array',
        schema: TEXTAREA_ARRAY_SCHEMA,
      },
    ],
  },
  {
    name: 'instruction',
    label: 'instruction',
    type: 'array',
    schema: TEXTAREA_ARRAY_SCHEMA,
  },
  {
    name: 'tips',
    label: 'tips',
    type: 'array',
    schema: [
      {
        name: '',
        type: 'array',
        schema: [
          {
            name: 'title',
            label: 'title',
            type: 'string',
          },
          {
            name: 'body',
            label: 'body',
            type: 'textarea',
          },
        ],
      },
    ],
  },
  {
    name: 'overtimePassedMessage',
    label: 'overtimePassedMessage',
    type: 'string',
  },
  ENTITY_PROPS,
];
const LIST_VIEW_PROPS_SCHEMA = [
  {
    name: 'title',
    label: 'title',
    type: 'string',
  },
  {
    name: 'textWhenEmpty',
    label: 'Text to show then there are no items',
    type: 'string',
  },
  {
    name: 'itemFilter',
    label: 'itemFilter (depricated, user "Item Filters" instead)',
    type: 'string',
  },
  {
    name: 'itemFilters',
    label: 'Item Filters',
    type: 'array',
    schema: [
      {
        name: 'type',
        type: 'string',
        label: 'filter type',
      },
      {
        name: 'value',
        type: 'string',
        label: 'value',
      },
    ],
  },
  {
    name: 'includeSelf',
    label: 'includeSelf',
    type: 'boolean',
  },
  {
    name: 'allowSearch',
    label: 'allowSearch',
    type: 'boolean',
  },
  {
    name: 'allowShuffle',
    label: 'allowShuffle',
    type: 'boolean',
  },
  {
    name: 'maxItems',
    label: 'maxItems',
    type: 'number',
  },
  {
    name: 'objectType',
    label: 'objectType',
    type: 'select',
    options: OBJECT_TYPES,
    changing: ['imageTreasureType', 'textTreasureType'],
  },
  {
    name: 'imageTreasureType',
    label: 'Image Treasure Type',
    type: 'select',
    options: IMAGE_TREASURE_TYPES,
    condition: ['objectType'],
    conditionValues: ['image_treasure'],
  },
  {
    name: 'textTreasureType',
    label: 'Text Treasure Type',
    type: 'select',
    options: TEXT_TREASURE_TYPES,
    condition: ['objectType'],
    conditionValues: ['text_treasure'],
  },
  {
    name: 'soeItemType',
    label: 'Sphere of excelence item Type',
    type: 'select',
    options: SOE_ITEM_TYPES,
    condition: ['objectType'],
    conditionValues: ['soe_item'],
  },
  {
    name: 'journeyNodeFilter',
    label: 'Journey Node Filter',
    type: 'select',
    options: [
      {
        label: 'none',
        value: '',
      },
      {
        label: 'All journeys',
        value: 'all',
      },
      {
        label: 'All selected journeys',
        value: 'selected',
      },
      {
        label: 'Uncompleted selected journeys',
        value: 'selected_uncompleted',
      },
      {
        label: 'Completed selected journeys',
        value: 'selected_completed',
      },
      {
        label: 'not selected Journeys',
        value: 'unselected',
      },
    ],
    condition: ['objectType'],
    conditionValues: ['journey_node'],
  },
  {
    name: 'items',
    label: 'items',
    type: 'array',
    schema: [
      {
        name: 'actionId',
        label: 'actionId',
        type: 'string',
      },
      {
        name: 'dataValue',
        label: 'dataValue',
        type: 'string',
      },
    ],
  },
  {
    name: 'widthScale',
    label: 'Horizontal Scale',
    type: 'number',
  },
  {
    name: 'heightScale',
    label: 'Verical Scale',
    type: 'number',
  },
  {
    name: 'appearDelay',
    label: 'Delay between showing items in seconds',
    type: 'number',
  },
  {
    name: 'isMultipleChoice',
    label: 'isMultipleChoice',
    type: 'boolean',
  },
  {
    name: 'maxSelectedItems',
    label: 'maxSelectedItems',
    type: 'number',
  },
  {
    name: 'dataId',
    label: 'dataId',
    type: 'string',
  },
  {
    name: 'itemPlacement',
    label: 'itemPlacement',
    type: 'select',
    options: [
      {
        label: 'random',
        value: 'random',
      },
      {
        label: 'row',
        value: 'row',
      },
      {
        label: 'search',
        value: 'search',
      },
    ],
  },
  ENTITY_PROPS,
];
const QUOTE_VIEW_PROPS_SCHEMA = [
  {
    name: 'slugs',
    label: 'slugs',
    type: 'array',
    schema: STRING_ARRAY_SCHEMA,
  },
  ENTITY_PROPS,
];
const REST_VALUES_PROPS_SCHEMA = [
  {
    name: 'roomUserIndex',
    label: 'roomUserIndex',
    type: 'number',
  },
  {
    name: 'label',
    label: 'Label',
    type: 'string',
  },
  ENTITY_PROPS,
];
const REFRAME_PROPS_SCHEMA = [
  {
    name: 'reframeId',
    label: 'reframeId',
    type: 'string',
  },
  {
    name: 'reframe',
    label: 'reframe',
    type: 'object',
    collapsible: true,
    schema: REFRAME_SCHEMA,
  },
  ENTITY_PROPS,
];

const RESERVATION_NODE_PROPS_SCHEMA = [
  {
    name: 'sequenceSlug',
    label: 'Social Mission Slug',
    type: 'string',
  },
  {
    name: 'showAsNotification',
    label: 'Show as notification',
    type: 'boolean',
  },
  {
    name: 'dateTime',
    label: 'Date and Time',
    type: 'date',
  },
  {
    name: 'skipPlanScreen',
    label: 'Skip the planning screen',
    type: 'boolean',
  },
  {
    name: 'info',
    label: 'information text',
    type: 'string',
  },
  ENTITY_PROPS,
];

const LEADERSCHIP_OBJECT_PROPS_SCHEMA = [
  {
    name: 'userIndex',
    label: 'User Index',
    type: 'number',
  },
  {
    name: 'apiUri',
    label: 'Image (from CMS)',
    type: 'imgFileName',
  },
  {
    name: 'title',
    label: 'title',
    type: 'string',
  },
  {
    name: 'text',
    label: 'text',
    type: 'string',
  },
  {
    name: 'userName',
    label: 'userName',
    type: 'string',
  },
  {
    name: 'userId',
    label: 'userId',
    type: 'string',
  },
  ENTITY_PROPS,
];
const RESILIENCE_CANVAS_PROPS_SCHEMA = [
  {
    name: 'userIndex',
    label: 'userIndex',
    type: 'number',
  },
  {
    name: 'minimized',
    label: 'minimized',
    type: 'boolean',
  },
  {
    name: 'editMode',
    label: 'editMode',
    type: 'boolean',
  },
  {
    name: 'onlyEditByOwner',
    label: 'Only Allow Edit by Owner',
    type: 'boolean',
  },
  {
    name: 'showWhenEmpty',
    label: 'showWhenEmpty',
    type: 'boolean',
  },
  ENTITY_PROPS,
];

const TEXT_TEASSURE_PROPS_SCHEMA = [
  {
    name: 'type',
    label: 'Type',
    type: 'select',
    options: TEXT_TREASURE_TYPES,
  },
  {
    name: 'userName',
    label: 'userName',
    type: 'string',
  },
  {
    name: 'userId',
    label: 'userId',
    type: 'string',
  },
  {
    name: 'label',
    label: 'label',
    type: 'string',
  },
  {
    name: 'content',
    label: 'content',
    type: 'string',
  },
  ENTITY_PROPS,
];

const IMAGE_TEASSURE_PROPS_SCHEMA = [
  {
    name: 'type',
    label: 'Type',
    type: 'select',
    options: IMAGE_TREASURE_TYPES,
  },
  {
    name: 'apiUri',
    label: 'Image (from CMS)',
    type: 'imgFileName',
  },
  {
    name: 'userName',
    label: 'userName',
    type: 'string',
  },
  {
    name: 'userId',
    label: 'userId',
    type: 'string',
  },
  ENTITY_PROPS,
];

const SOE_CONTROLLER_PROPS_SCHEMA = [
  {
    name: 'action',
    label: 'Sphere of Excellence action to execute:',
    type: 'select',
    options: [
      {
        label: 'Add strength/value/passion items',
        value: 'addItems',
      },
      {
        label: 'Save Passion Image URL',
        value: 'savePassionImageUrl',
      },
      {
        label: 'Create the AI Suggestions based on the created items',
        value: 'createAiSuggestions',
      },
      {
        label: 'Save sentence parts',
        value: 'sentenceParts',
      },
      {
        label: 'Get Sphere Of Excelence user data',
        value: 'getData',
      },
    ],
    changing: [
      'type',
      'passionImageUrl',
      'addItems',
      'sentencePart1',
      'sentencePart2',
      'sentencePart3',
      'dataIdSuggestion1',
      'dataIdSuggestion2',
      'dataIdSuggestion3',
      'dataIdNewSuggestionsAllowed',
      'dataIdSoeSentence',
    ],
  },
  {
    name: 'type',
    label: 'Type',
    type: 'select',
    options: SOE_ITEM_TYPES,
    condition: ['action'],
    conditionValues: ['addItems'],
  },
  {
    name: 'passionImageUrl',
    label: 'Passion Image Url',
    type: 'string',
    condition: ['action'],
    conditionValues: ['savePassionImageUrl'],
  },
  {
    name: 'addItems',
    label: 'Add Items',
    type: 'array',
    schema: [
      {
        name: '',
        type: 'string',
      },
    ],
    condition: ['action'],
    conditionValues: ['addItems'],
  },
  {
    name: 'dataIdSuggestion1',
    label: 'DataId to store AI Suggestion 1',
    type: 'string',
    condition: ['action'],
    conditionValues: ['getData'],
  },
  {
    name: 'dataIdSuggestion2',
    label: 'DataId to store AI Suggestion 2',
    type: 'string',
    condition: ['action'],
    conditionValues: ['getData'],
  },
  {
    name: 'dataIdSuggestion3',
    label: 'DataId to store AI Suggestion 3',
    type: 'string',
    condition: ['action'],
    conditionValues: ['getData'],
  },
  {
    name: 'sentencePart1',
    label: 'Sentence Part 1',
    type: 'string',
    condition: ['action'],
    conditionValues: ['sentenceParts'],
  },
  {
    name: 'sentencePart2',
    label: 'Sentence Part 1',
    type: 'string',
    condition: ['action'],
    conditionValues: ['sentenceParts'],
  },
  {
    name: 'sentencePart3',
    label: 'Sentence Part 1',
    type: 'string',
    condition: ['action'],
    conditionValues: ['sentenceParts'],
  },
  {
    name: 'dataIdNewSuggestionsAllowed',
    label: 'DataId to store if new AI suggestions are allowed',
    type: 'string',
    condition: ['action'],
    conditionValues: ['getData'],
  },
  {
    name: 'dataIdSoeSentence',
    label: 'DataId to store the user soe summary',
    type: 'string',
    condition: ['action'],
    conditionValues: ['getData'],
  },
];

const SOE_ITEM_CANVAS_PROPS_SCHEMA = [
  {
    name: 'type',
    label: 'Type',
    type: 'select',
    options: SOE_ITEM_TYPES,
  },
  {
    name: 'includeBacklogItems',
    label: 'Include items outside top-3',
    type: 'boolean',
  },
  {
    name: 'compactView',
    label: 'Compact View',
    type: 'boolean',
  },
  {
    name: 'titleText',
    label: 'Title',
    type: 'textarea',
  },
  ENTITY_PROPS,
];

const SOE_ITEM_PROPS_SCHEMA = [
  {
    name: 'type',
    label: 'Type',
    type: 'select',
    options: SOE_ITEM_TYPES,
  },
  {
    name: 'text',
    label: 'Text',
    type: 'string',
  },
  ENTITY_PROPS,
];

const AI_CONTROLLER_PROPS_SCHEMA = [
  {
    name: 'type',
    label: 'type',
    type: 'select',
    options: [
      {
        label: '',
        value: '',
      },
      {
        label: 'text',
        value: 'text',
      },
      {
        label: 'image',
        value: 'image',
      },
    ],
  },
  {
    name: 'prompt',
    label: 'prompt',
    type: 'string',
  },
  {
    name: 'amount',
    label: 'amount',
    type: 'number',
  },
  {
    name: 'size',
    label: 'size',
    type: 'select',
    options: [
      {
        label: '256 by 256 pixels',
        value: '256x256',
      },
      {
        label: '512 by 512 pixels',
        value: '512x512',
      },
      {
        label: '1024by 1024 pixels',
        value: '1024x1024',
      },
    ],
  },
  {
    name: 'dataId',
    label: 'dataId',
    type: 'array',
    schema: STRING_ARRAY_SCHEMA,
  },
  ENTITY_PROPS,
];
const IMAGE_UPLOAD_CONTROLLER_PROPS_SCHEMA = [
  {
    name: 'imageObjectActionId',
    label: 'imageObjectActionId',
    type: 'string',
  },
  {
    name: 'imageObjectServerObjId',
    label: 'imageObjectServerObjId',
    type: 'string',
  },
  {
    name: 'dataId',
    label: 'dataId',
    type: 'string',
  },
  {
    name: 'title',
    label: 'title',
    type: 'string',
  },
  ENTITY_PROPS,
];
const RESERVATION_COLLECTION_PROPS_SCHEMA = [
  {
    name: 'allActiveReservations',
    label: 'All Active Reservations',
    type: 'boolean',
  },
  {
    name: 'showAsNotifications',
    label: 'Show as notifications',
    type: 'boolean',
  },
  {
    name: 'sequenceSlug',
    label: 'Sequence Slug',
    type: 'string',
  },
  {
    name: 'sequenceTitle',
    label: 'Sequence Title',
    type: 'string',
  },
  {
    name: 'showFinalReservations',
    label: 'Show Final Reservations',
    type: 'boolean',
  },
  {
    name: 'showReservationRequestsReceived',
    label: 'Show Received Reservation Requests',
    type: 'boolean',
  },
  {
    name: 'showReservationRequestsSent',
    label: 'Show Sent Reservation Requests',
    type: 'boolean',
  },
  {
    name: 'showOpenMatches',
    label: 'Show Open Matches From This User',
    type: 'boolean',
  },
  {
    name: 'showMatchables',
    label: 'Show Matchable requests',
    type: 'boolean',
  },
  ENTITY_PROPS,
];
const RESERVATION_REQUEST_PROPS_SCHEMA = [
  {
    name: 'invite_user_id',
    label: 'UserId to Invite',
    type: 'string',
  },
  {
    name: 'sequenceSlug',
    label: 'Sequence Slug',
    type: 'string',
  },
  {
    name: 'content',
    label: 'content',
    type: 'string',
  },
  ENTITY_PROPS,
];
const SEQUENCE_DATA_CONTROLLER_PROPS_SCHEMA = [
  {
    name: 'sequenceSlug',
    label: 'Sequence Slug to retrieve data from',
    type: 'string',
  },
  {
    name: 'sequenceTitleDataId',
    label: 'DataId to store sequence title',
    type: 'string',
  },
  {
    name: 'socialMissionInfoDataId',
    label: 'DataId to store social mission info',
    type: 'string',
  },
  {
    name: 'backgroundImDataId',
    label: 'DataId to store sequence background image',
    type: 'string',
  },
  ENTITY_PROPS,
];

const BREAKOUT_CONTROLLER = [
  {
    name: 'sequenceSlug',
    label: 'Sequence Slug',
    type: 'string',
  },
  {
    name: 'doAutoStart',
    label: 'Auto Start Breakout Rooms',
    type: 'boolean',
  },
  ENTITY_PROPS,
];

const TEXTOBJECT_PROPS_SCHEMA = [
  {
    name: 'text',
    label: 'Text',
    type: 'array',
    schema: TEXTAREA_ARRAY_SCHEMA,
  },
  {
    name: 'widthEm',
    label: 'Width (em)',
    type: 'number',
  },
  {
    name: 'align',
    label: 'Align',
    type: 'select',
    options: [
      {
        value: 'left',
        label: 'left',
      },
      {
        value: 'center',
        label: 'center',
      },
      {
        value: 'right',
        label: 'right',
      },
    ],
  },
  {
    name: 'audio',
    label: 'Audio',
    type: 'string',
  },
  {
    name: 'LLMPrompt',
    label: 'LLMPrompt',
    type: 'string',
  },
  {
    name: 'disappearWhenEmpty',
    label: 'Do no create TextObject when text is empty',
    type: 'boolean',
  },
  {
    name: 'style',
    label: 'Text Style',
    type: 'select',
    options: [
      {
        label: 'Content Default',
        value: 'content_default',
      },
      {
        label: 'Content Small',
        value: 'content_small',
      },
      {
        label: 'Title',
        value: 'title',
      },
      {
        label: 'Big Title',
        value: 'title_big',
      },
      {
        label: 'Floating Item',
        value: 'floating_item',
      },
      {
        label: 'Small Floating Item',
        value: 'floating_item_small',
      },
      {
        label: 'Floating Item Green',
        value: 'floating_item_green',
      },
      {
        label: 'Floating Item Red',
        value: 'floating_item_red',
      },
      {
        label: 'Question',
        value: 'question',
      },
    ],
  },
  {
    name: 'fixedFontSize',
    label: 'Fixed font size',
    type: 'boolean',
  },
  ENTITY_PROPS,
];

const ACTION_SCHEMA = [
  {
    name: 'id',
    label: 'ID',
    type: 'string',
  },
  {
    name: 'type',
    label: 'Type',
    type: 'select',
    changing: ['props'],
    options: ACTION_TYPES,
  },
  {
    name: 'objectType',
    label: 'Object Type',
    type: 'select',
    condition: ['type'],
    conditionValues: ['create_object', 'change_object'],
    changing: ['props'],
    options: OBJECT_TYPES,
  },
  {
    name: 'enabled',
    label: 'Enabled',
    type: 'boolean',
    defaultValue: true,
  },
  {
    name: 'disappearOnStepDone',
    label: 'Disappear on step done',
    type: 'boolean',
  },
  {
    name: 'delay',
    label: 'Delay',
    type: 'number',
  },
  {
    name: 'dataCondition',
    label: 'Data Condition',
    type: 'object',
    collapsible: true,
    schema: [
      {
        name: 'dataId',
        label: 'Data Id',
        type: 'string',
      },
      {
        name: 'value',
        label: 'Value to compare',
        type: 'string',
      },
      {
        name: 'valueNumber',
        label: 'Value to compare if it is a number',
        type: 'number',
      },
      {
        name: 'comparison',
        label: 'Comparison type',
        type: 'select',
        options: COMPARISON_OPTIONS,
      },
      {
        name: 'checkOnce',
        label: 'Only check the condition once',
        type: 'boolean',
      },
    ],
  },

  {
    name: 'userIndexFilter',
    label: 'User index filter',
    type: 'array',
    schema: [
      {
        name: '',
        type: 'number',
      },
    ],
  },
  {
    name: 'clientFilter',
    label: 'User Filter',
    type: 'object',
    condition: ['type'],
    conditionValues: ['create_object'],
    schema: [
      {
        name: 'type',
        label: 'Filter Type',
        type: 'select',
        changing: ['clientIndex'],
        options: [
          {
            value: 'everyone',
            label: 'All Users',
          },
          {
            value: 'only_hosts',
            label: 'Only Hosts',
          },
          {
            value: 'room_client_index',
            label: 'Room User Index',
          },
        ],
      },
      {
        name: 'clientIndex',
        label: 'Room User Index',
        type: 'number',
        condition: ['type'],
        conditionValues: ['room_client_index'],
      },
    ],
  },

  {
    name: 'props',
    label: 'Props',
    type: 'object',
    collapsible: true,
    condition: ['type', 'objectType'],
    schema: {
      setings: [SETTINGS_PROPS_SCHEMA],
      background: [
        {
          name: 'apiUri',
          label: 'Image (from CMS)',
          type: 'imgFileName',
        },
        {
          name: 'imageUri',
          label: 'external Image Uri',
          type: 'string',
        },
        {
          name: 'Hex Color ()',
          label: 'hexColor',
          type: 'string',
        },
        {
          name: 'blur',
          label: 'Blur',
          type: 'number',
        },
      ],

      background_music: [
        {
          name: 'trackUri',
          label: 'Track Uri',
          type: 'string',
        },
        {
          name: 'volume',
          label: 'volume',
          type: 'number',
        },
        {
          name: 'loop',
          label: 'Loop',
          type: 'boolean',
        },
      ],
      clean_user_text: [],
      profile_update: [
        {
          name: 'firstname',
          label: 'First Name',
          type: 'string',
        },
        {
          name: 'lastname',
          label: 'Last Name',
          type: 'string',
        },
        {
          name: 'title',
          label: 'Title',
          type: 'string',
        },
        {
          name: 'companyName',
          label: 'Company Name',
          type: 'string',
        },
        {
          name: 'profilePicId',
          label: 'Profile Picture Id',
          type: 'string',
        },
        {
          name: 'profilePicFile',
          label: 'Profile Picture File',
          type: 'string',
        },
      ],
      userdata_update: [
        {
          name: 'leadershipObjectUri',
          label: 'leadershipObjectUri',
          type: 'string',
        },
        {
          name: 'resilienceAltarUri',
          label: 'resilienceAltarUri',
          type: 'string',
        },
        {
          name: 'leadershipObjectTitle',
          label: 'leadershipObjectTitle',
          type: 'string',
        },
        {
          name: 'leadershipObjectText',
          label: 'leadershipObjectText',
          type: 'string',
        },
        {
          name: 'onboardingCompleted',
          label: 'onboardingCompleted',
          type: 'boolean',
        },
        {
          name: 'courageousConversation',
          label: 'Courageous Conversation',
          colapsible: true,
          type: 'object',
          schema: [
            {
              name: 'observation',
              label: 'observation',
              type: 'string',
            },

            {
              name: 'feeling',
              label: 'feeling',
              type: 'string',
            },

            {
              name: 'need',
              label: 'need',
              type: 'string',
            },
            {
              name: 'request',
              label: 'request',
              type: 'string',
            },
            {
              name: 'learning',
              label: 'Learning',
              type: 'string',
            },
            {
              name: 'doShareLearning',
              label: 'Share Learning with other users',
              type: 'boolean',
            },
          ],
        },
        {
          name: 'resilienceCanvas',
          label: 'resilienceCanvas',
          colapsible: true,
          type: 'object',
          schema: [
            {
              name: 'body',
              label: 'body',
              type: 'array',
              schema: STRING_ARRAY_SCHEMA,
            },
            {
              name: 'mind',
              label: 'mind',
              type: 'array',
              schema: STRING_ARRAY_SCHEMA,
            },
            {
              name: 'soul',
              label: 'soul',
              type: 'array',
              schema: STRING_ARRAY_SCHEMA,
            },
            {
              name: 'heart',
              label: 'heart',
              type: 'array',
              schema: STRING_ARRAY_SCHEMA,
            },
          ],
        },
        {
          name: 'rarValues',
          label: 'Rest & Recovery Values',
          type: 'array',
          schema: [
            {
              name: 'label',
              label: 'Label',
              type: 'string',
            },
            {
              name: 'value',
              label: 'Value',
              type: 'string',
            },
            {
              name: 'Improve',
              label: 'Improve',
              type: 'string',
            },
            {
              name: 'plan',
              label: 'Plan',
              type: 'string',
            },
            {
              name: 'share',
              label: 'Share',
              type: 'boolean',
            },
          ],
        },
        {
          name: 'antiprofile',
          label: 'antiprofile',
          colapsible: true,
          type: 'object',
          schema: [
            {
              name: 'characterDescription',
              label: 'characterDescription',
              type: 'string',
            },
            {
              name: 'profileText',
              label: 'profileText',
              type: 'string',
            },
            {
              name: 'imagePrompt',
              label: 'imagePrompt',
              type: 'string',
            },
            {
              name: 'imageUrl',
              label: 'imageUrl',
              type: 'string',
            },
            {
              name: 'questions',
              label: 'questions',
              type: 'array',
              schema: [
                {
                  name: 'question',
                  label: 'question',
                  type: 'string',
                },
                {
                  name: 'answer',
                  label: 'answer',
                  type: 'string',
                },
              ],
            },
          ],
        },
      ],
      module_start_part: [
        {
          name: 'moduleId',
          label: 'moduleId',
          type: 'string',
        },
        {
          name: 'partId',
          label: 'partId',
          type: 'string',
        },
      ],
      module_complete_part: [
        {
          name: 'moduleId',
          label: 'moduleId',
          type: 'string',
        },
        {
          name: 'partId',
          label: 'partId',
          type: 'string',
        },
        {
          name: 'unCompletePart',
          label: 'Un-complete part',
          type: 'boolean',
        },
      ],
      disappear_object: [],
      disappear_objects: [
        {
          name: 'actionIds',
          label: 'Action Ids',
          type: 'array',
          schema: [
            {
              name: '',
              type: 'string',
            },
          ],
        },
      ],
      disappear_step_objects: [
        {
          name: 'stepNames',
          label: 'Step Names',
          type: 'array',
          schema: [
            {
              name: '',
              type: 'string',
            },
          ],
        },
        {
          name: 'onlyCreateObjectActions',
          label: 'Only disappear objects which are created in these steps',
          type: 'boolean',
        },
      ],
      start_sequence: [
        {
          name: 'sequenceSlug',
          label: 'sequenceSlug',
          type: 'string',
        },
        {
          name: 'seperateUsers',
          label: 'seperateUsers',
          type: 'boolean',
        },
        {
          name: 'startAtStepName',
          label: 'startAtStepName',
          type: 'string',
        },
        {
          name: 'roomParams',
          label: 'roomParams',
          type: 'array',
          schema: [
            {
              name: 'label',
              label: 'label',
              type: 'string',
            },
            {
              name: 'value',
              label: 'value',
              type: 'string',
            },
          ],
        },
      ],
      navigate: [
        {
          name: 'navType',
          label: 'navType',
          type: 'select',
          options: [
            {
              label: 'back',
              value: '_back',
            },
            {
              label: 'close',
              value: '_close',
            },
            {
              label: 'home',
              value: '_home',
            },
            {
              label: 'slug',
              value: '_slug',
            },
            {
              label: 'reload',
              value: '_reload',
            },
          ],
        },
        {
          name: 'slug',
          label: 'slug',
          type: 'string',
        },
      ],

      module_collection: [
        {
          name: 'modules',
          label: 'modules',
          type: 'array',
          schema: [
            {
              name: 'slug',
              label: 'slug',
              type: 'string',
            },
            {
              name: 'imageUri',
              label: 'imageUri',
              type: 'string',
            },
            {
              name: 'posX',
              label: 'posX',
              type: 'number',
            },
            {
              name: 'posY',
              label: 'posY',
              type: 'number',
            },
          ],
        },
      ],
      save_reframe: [
        {
          name: 'dbid_dataId',
          label: 'DataId to store reframe ID',
          type: 'string',
        },
        {
          name: 'reframe',
          label: 'reframe',
          type: 'object',
          collapsible: true,
          schema: REFRAME_SCHEMA,
        },
      ],
      create_antiprofile_description: [
        {
          name: 'dataId',
          label: 'dataId',
          type: 'string',
        },
      ],
      upload_image: [
        //depricated?
        {
          name: '',
          label: '',
          type: 'string',
        },
      ],
      set_variable: [
        {
          name: 'dataId',
          label: 'dataId',
          type: 'string',
        },
        {
          name: 'value',
          label: 'value',
          type: 'string',
        },
        {
          name: 'saveToRoomParams',
          label: 'Save variable in room params',
          type: 'boolean',
        },
      ],
      anchor: ANCHOR_PROPS_SCHEMA,
      canvas: CANVAS_PROPS_SCHEMA,
      canvas2: CANVAS_PROPS_SCHEMA,
      image_object: IMAGE_OBJECT_PROPS_SCHEMA,
      infographic: INFOGRAPHIC_PROPS_SCHEMA,
      image_picker: IMAGE_PICKER_PROPS_SCHEMA,
      profile_node: PROFILE_NODE_SCHEMA,
      room_node: ROOM_NODE_PROPS_SCHEMA,
      live_session_node: LIVE_SESSION_NODE_SCHEMA,
      social_room_node: SOCIAL_ROOM_NODE_PROPS_SCHEMA,
      module_node: MODULE_NODE_PROPS_SCHEMA,
      journey_node: JOURNEY_NODE_PROPS_SCHEMA,
      textobject: TEXTOBJECT_PROPS_SCHEMA,
      usertext: USERTEXT_PROPS_SCHEMA,
      video: VIDEO_PROPS_SCHEMA,
      videocall: VIDEOCALL_PROPS_SCHEMA,
      videocall_custom: VIDEOCALL_CUSTOM_PROPS_SCHEMA,
      button: BUTTON_PROPS_SCHEMA,
      droparea: DROPAREA_PROPS_SCHEMA,
      timer: TIMER_PROPS_SCHEMA,
      settings: SETTINGS_PROPS_SCHEMA,
      question: QUESTION_PROPS_SCHEMA,
      user_item: USER_ITEM_PROPS_SCHEMA,
      stepindicator: STEPINDICATOR_PROPS_SCHEMA,
      paged_text: PAGED_TEXT_PROPS_SCHEMA,
      readycheck: READYCHECK_PROPS_SCHEMA,
      link: LINK_PROPS_SCHEMA,
      module_view: MODULE_VIEW_PROPS_SCHEMA,
      journey_view: JOURNEY_VIEW_PROPS_SCHEMA,
      avatar_visual: AVATAR_VISUAL_PROPS_SCHEMA,
      instruction_bar: INSTUCTION_BAR_PROPS_SCHEMA,
      gallery_view: LIST_VIEW_PROPS_SCHEMA,
      quote: QUOTE_VIEW_PROPS_SCHEMA,
      rest_values: REST_VALUES_PROPS_SCHEMA,
      reframe: REFRAME_PROPS_SCHEMA,
      reservation_node: RESERVATION_NODE_PROPS_SCHEMA,
      leadership_object: LEADERSCHIP_OBJECT_PROPS_SCHEMA,
      resilience_canvas: RESILIENCE_CANVAS_PROPS_SCHEMA,
      text_treasure: TEXT_TEASSURE_PROPS_SCHEMA,
      image_treasure: IMAGE_TEASSURE_PROPS_SCHEMA,
      ai_controller: AI_CONTROLLER_PROPS_SCHEMA,
      soe_controller: SOE_CONTROLLER_PROPS_SCHEMA,
      soe_item_canvas: SOE_ITEM_CANVAS_PROPS_SCHEMA,
      soe_item: SOE_ITEM_PROPS_SCHEMA,
      image_upload_controller: IMAGE_UPLOAD_CONTROLLER_PROPS_SCHEMA,
      reservation_collection: RESERVATION_COLLECTION_PROPS_SCHEMA,
      reservation_request: RESERVATION_REQUEST_PROPS_SCHEMA,
      sequence_data_controller: SEQUENCE_DATA_CONTROLLER_PROPS_SCHEMA,
      breakout_controller: BREAKOUT_CONTROLLER,
    },
  },
];

const FORM = {
  endpoint: 'pg_sequence',
  itemPath: 'sequence',
  backPath: '/playground?tab=1',
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'title_full',
      type: 'string',
      label: 'Title full',
    },
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
      required: true,
    },
    {
      name: 'body',
      type: 'object',
      schema: [
        {
          name: 'backgroundImage',
          type: 'imgFileName',
          label: 'Background Image',
        },
        {
          name: 'amountOfUsersMin',
          type: 'number',
          label: 'Amount of users min',
        },
        {
          name: 'amountOfUsersMax',
          type: 'number',
          label: 'Amount of users max',
        },
        {
          name: 'type',
          type: 'select',
          label: 'Type',
          changing: ['moduleLink'],
          options: [
            {
              value: 'world',
              label: 'World',
            },
            {
              value: 'home',
              label: 'Home',
            },
            {
              value: 'world_sub',
              label: 'World sub page (exclude from navigation history)',
            },
            {
              value: 'social_quest',
              label: 'Social quest',
            },
            {
              value: 'solo_quest',
              label: 'Solo quest',
            },
            {
              value: 'social_room',
              label: 'Social room',
            },
            {
              value: 'solo_room',
              label: 'Solo room',
            },
          ],
        },
        {
          name: 'moduleLink',
          type: 'ref',
          entity: 'module',
          label: 'Module link',
          condition: ['type'],
          conditionValues: ['social_quest', 'solo_quest'],
        },
        {
          name: 'socialQuestInfo',
          type: 'textarea',
          label: 'Social Quest Information',
          condition: ['type'],
          conditionValues: ['social_quest'],
        },
        {
          name: 'syncCursor',
          type: 'boolean',
          label: 'Sync cursor',
        },
        {
          name: 'showCursor',
          type: 'boolean',
          label: 'Show cursor',
        },
        {
          name: 'syncCursorText',
          type: 'boolean',
          label: 'Sync cursor text',
        },
        {
          name: 'useVideocall',
          type: 'boolean',
          label: 'Videocall Required',
        },
        {
          name: 'videocallOptional',
          type: 'boolean',
          label: 'Videocall Optional',
        },
        {
          name: 'dynamicCanvas',
          type: 'boolean',
          label: 'Enable Dynamic Canvas',
        },
        {
          name: 'steps',
          type: 'array',
          label: 'Steps',
          schema: [
            {
              name: 'name',
              type: 'string',
              label: 'Name',
              required: true,
            },
            {
              name: 'disappearAllOnDone',
              type: 'boolean',
              label: 'Disappear All On Done',
            },
            {
              name: 'transitionDelay',
              type: 'number',
              label: 'Transition delay',
              validate: (value: number) => !(value < 0),
            },
            {
              name: 'doneConditions',
              type: 'array',
              label: 'Done conditions',
              schema: DONE_CONDITION,
            },
            {
              name: 'actions',
              type: 'array',
              label: 'Actions',
              schema: [
                {
                  name: 'action',
                  type: 'object',
                  label: 'Action',
                  schema: ACTION_SCHEMA,
                },
              ],
            },
          ],
        },
        {
          name: 'sequenceCompleteActions',
          type: 'array',
          label: 'Sequence complete actions',
          schema: [
            {
              name: 'action',
              type: 'object',
              label: 'Action',
              schema: ACTION_SCHEMA,
            },
          ],
        },
      ],
    },
  ],
};

function Sequence() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUserContext();
  const { result, doFetch, loading, error } = useFetch(`${SEQUENCE_URL}/${id}`);
  const sequence = useMemo(() => result?.data, [result]);
  const values = useMemo(() => {
    if (!sequence) return null;
    const { created, user_id, _id, updated, ...data } = sequence;
    return data;
  }, [sequence]);

  useEffect(() => {
    if (id === 'new') return;
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the sequence with id: {id}</AlertTitle>
          </Alert>
        ) : !sequence ? (
          <>
            <h1 className="h3">Create new sequence</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm {...FORM} />
            </Box>
          </>
        ) : null}
        {!sequence ? null : (
          <>
            <h1 className="h3">{sequence.title}</h1>
            <Tag mt={2} colorScheme="facebook">
              {sequence._id}
            </Tag>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={values} id={sequence._id} fetchItem={doFetch} {...FORM} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default Sequence;
