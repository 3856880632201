/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon, CopyIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Spinner,
  Tag,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import AlertDialog from '../../common/AlertDialog/AlertDialog';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const RESERVATION_URL = `${process.env.REACT_APP_API_URL}/reservation`;

const FORM = {
  endpoint: 'reservation',
  itemPath: 'reservation',
  backPath: '/playground?tab=5',
  schema: [
    {
      name: 'pg_sequence',
      type: 'ref',
      entity: 'sequence',
      label: 'Sequence',
      required: true,
    },
    {
      name: 'users',
      type: 'array',
      label: 'Users',
      maxLength: '2',
      schema: [
        {
          name: '',
          type: 'ref',
          entity: 'worldUser',
          label: 'Users',
          required: true,
        },
      ],
    },
    {
      name: 'time',
      type: 'date-slot',
      label: 'When',
    },
    {
      name: 'content',
      type: 'textarea',
      label: 'Text',
    },
  ],
};

function Reservation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUserContext();
  const { result, doFetch, loading, error } = useFetch(`${RESERVATION_URL}/${id}`);
  const { result: creationResult, doFetch: doCreate } = useFetch(`${RESERVATION_URL}/create`);
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState();
  const reservation = useMemo(() => {
    if (!result?.data) return null;
    const { ...rest } = result.data;
    return rest;
  }, [result]);
  const toast = useToast();

  useEffect(() => {
    if (id === 'new') return;
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  const cancel = () => setOpen(false);

  useEffect(() => {
    if (creationResult && creationResult.status === 'success') {
      const { _id } = creationResult.data;
      setOpen(false);
      navigate(`/reservation/${_id}`);
      toast({
        status: 'success',
        title: 'Reservation successfully created',
        duration: 2000,
        position: 'top',
      });
    }
  }, [creationResult]);

  const onFormSubmit = (_data: any) => {
    setOpen(true);
    setBody(_data);
  };

  const create = () => {
    doCreate({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  };

  const values = useMemo(() => {
    if (reservation) {
      const { created, user_id, _id, updated, ...data } = reservation;
      return data;
    }
    return {};
  }, [reservation]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        <AlertDialog
          onClose={cancel}
          isOpen={open}
          title="Are you sure you want to continue?"
          description="Creating this reservation will result in permanently deleting other reservations these users may already have. Are you sure you want to continue?"
          actions={['Cancel', 'Create']}
          onAction={() => create()}
        />
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the reservation with id: {id}</AlertTitle>
          </Alert>
        ) : !reservation ? (
          <>
            <h1 className="h3">Create new reservation</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm withPrompt={false} {...FORM} onFormSubmit={onFormSubmit} />
            </Box>
          </>
        ) : null}
        {!reservation ? null : (
          <>
            <h1 className="h3">{`${reservation.pg_sequence}: ${reservation.users
              .map((user: any) => `${user.label}`)
              .join(' & ')}`}</h1>
            <Tag mt={2} colorScheme="facebook">
              {reservation._id}
            </Tag>
            <br />
            <div>
              <Tag mt={2} mr={1} colorScheme="facebook">
                Hash: {reservation.hash}
              </Tag>
              <CopyIcon
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigator.clipboard.writeText(reservation.hash);
                  toast({
                    title: 'Copied reservation id',
                    position: 'top',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                  });
                }}
              />
            </div>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={values} id={reservation._id} fetchItem={doFetch} {...FORM} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default Reservation;
