/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from '@chakra-ui/react';
import { Control, useController } from 'react-hook-form';
import { FiChevronDown } from 'react-icons/fi';

interface IProps {
  className?: string;
  control: Control<any, any>;
  path: string;
  required?: string | boolean;
  options: string[];
  placeholder: string;
}

function RHFInnerRef({ className, control, path, required, options, placeholder }: IProps) {
  const { field } = useController({
    name: path,
    control,
    rules: {
      required,
    },
  });

  return (
    <div className={`${className || ''}`}>
      <div className="input__wrapper">
        <select className="input__field input__field--select" {...field}>
          <option value="" key="placeholder">
            {placeholder}
          </option>
          {options.map((option) => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
        <Icon className="input__icon icon" as={FiChevronDown} />
      </div>
    </div>
  );
}

export default RHFInnerRef;
