import { useDisclosure, useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ItemLister from '../../Components/ItemLister/ItemLister';
import { useUserContext } from '../context/user.context';
import useFetch from './useFetch';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

interface Props {
  endpointSingle: string;
  endpointPlural: string;
  initialSort?: string;
  filters?: string[];
  perPage?: number;
  fixedPage?: number;
  statisticsSlug?: string;
  withDownload?: boolean;
  listerMinHeight?: number;
  filterInputs?: boolean;
  showTotal?: boolean;
}

const useItemLister = ({
  endpointSingle,
  endpointPlural,
  initialSort,
  filters,
  perPage,
  fixedPage,
  statisticsSlug,
  withDownload,
  listerMinHeight,
  filterInputs,
  showTotal = true,
}: Props) => {
  const { user: currentUser, getUser } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const timeout = useRef<ReturnType<typeof setInterval> | null>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [bulkSelection, setBultSelection] = useState<string[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const sortValue = useMemo(() => searchParams.get('sort') || '', [searchParams]);
  const [sort, setSort] = useState<{
    property: string;
    order: boolean;
    changed: boolean;
  }>({
    property: sortValue.split('-')[0] || initialSort || '',
    order: sortValue.split('-')[1] !== 'DESC',
    changed: !!sortValue,
  });
  const searchValue = useMemo(() => searchParams.get('search') || '', [searchParams]);
  const [search, setSearch] = useState<string>(searchValue);
  const [onlyExisting, setOnlyExisting] = useState<boolean>(false);
  const page = useMemo(() => searchParams.get('page') || '1', [searchParams]);
  const { result, doFetch, loading, error } = useFetch(
    `${BASE_URL}/${endpointPlural}${endpointPlural.includes('?') ? '&' : '?'}per_page=${
      perPage || 10
    }&page=${fixedPage || page}${
      sort.property ? `&sort=${sort.property}${sort.order ? ':ASC' : ':DESC'}` : ''
    }${
      !searchValue || !filters
        ? ''
        : filters.reduce((str, value) => `${str}&${value}=${searchValue}`, '')
    }${onlyExisting ? '&is_removed=false' : ''}`,
  );
  const [endpoint, setEndpoint] = useState(endpointSingle);
  const {
    result: deletedItem,
    doFetch: doDeleteFetch,
    loading: deleteLoading,
    error: deleteError,
  } = useFetch(`${BASE_URL}/${endpointSingle}/${activeId}`);
  const { result: statistics, doFetch: fetchStatistics } = useFetch(
    `${BASE_URL}/statistics/${statisticsSlug || endpointPlural}`,
  );
  const { result: activeStatistics, doFetch: fetchActiveStatistics } = useFetch(
    `${BASE_URL}/statistics/users-active`,
  );
  const { result: removedStatistics, doFetch: fetchRemovedStatistics } = useFetch(
    `${BASE_URL}/statistics/users-removed`,
  );

  useEffect(() => {
    setSearch(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (!isActive) return;
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
    fetchStatistics({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
    if (endpointPlural === 'users') {
      fetchActiveStatistics({
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
      fetchRemovedStatistics({
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
    }
  }, [searchParams, isActive, onlyExisting]);

  const deleteItem = useCallback((id: string, path = endpointSingle) => {
    setActiveId(id);
    setEndpoint(path);
    onOpen();
  }, []);

  useEffect(() => {
    if (!error) return;
    toast({
      title: error.message,
      position: 'top',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
    getUser();
  }, [error]);

  const closeModal = useCallback(() => {
    setActiveId(null);
    onClose();
  }, []);

  const confirmDelete = useCallback(() => {
    console.log(endpoint);
    doDeleteFetch({
      newUrl: `${BASE_URL}/${endpoint}/${activeId}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
    onClose();
  }, [activeId, endpoint, doDeleteFetch]);

  useEffect(() => {
    if (!deletedItem) return;
    setActiveId(null);
    toast({
      title: deletedItem.message,
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [deletedItem]);

  useEffect(() => {
    if (!deleteError) return;
    setActiveId(null);
    toast({
      title: 'Could not delete item',
      description: deleteError.message,
      position: 'top',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  }, [deleteError]);

  const changeSearch = useCallback(
    (value: string) => {
      searchParams.delete('page');
      setSearch(value);

      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        if (value) {
          searchParams.set('search', value);
        } else {
          searchParams.delete('search');
        }

        setSearchParams(searchParams, {
          replace: true,
        });
      }, 500);
    },
    [searchParams],
  );

  useEffect(
    () => () => {
      if (timeout.current) clearTimeout(timeout.current);
    },
    [],
  );

  const handleDownload = useCallback(async () => {
    try {
      const res = await fetch(`${BASE_URL}/export/users`, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
      const blob = await res.blob();
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = `user_data_${new Date().toLocaleString('en-gb', {
        day: '2-digit',
        month: 'numeric',
        year: 'numeric',
      })}.csv`;
      a.click();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log(err.message);
    }
  }, []);

  const Lister = useCallback(
    ({ children }: { children: JSX.Element }) => (
      <ItemLister
        items={result?.data?.items}
        pager={result?.data?.pager}
        loading={loading}
        error={error}
        isOpen={isOpen}
        closeModal={closeModal}
        confirmDelete={confirmDelete}
        search={search}
        changeSearch={changeSearch}
        page={page}
        total={statistics?.data?.total || 0}
        removed={removedStatistics?.data?.total}
        active={activeStatistics?.data?.total}
        withDownload={withDownload}
        handleDownload={handleDownload}
        filters={filters}
        filterInputs={filterInputs || false}
        onFilter={setOnlyExisting}
        minHeight={listerMinHeight}
        showTotal={showTotal}
      >
        {children}
      </ItemLister>
    ),
    [result, loading, error, isOpen, search, closeModal, confirmDelete],
  );

  const changeSort = useCallback(
    (val: string | undefined) => {
      searchParams.delete('page');
      if (val === sort.property) {
        searchParams.set('sort', `${val}${!sort.order ? '-ASC' : '-DESC'}`);
        setSearchParams(searchParams, {
          replace: true,
        });
        return setSort((prevState) => ({
          ...prevState,
          order: !prevState.order,
          changed: true,
        }));
      }
      searchParams.set('sort', `${val}-ASC`);
      setSearchParams(searchParams, {
        replace: true,
      });
      return setSort(() => ({
        property: val || '',
        order: true,
        changed: true,
      }));
    },
    [sort, searchParams],
  );

  return {
    items: result?.data?.items,
    loading,
    error,
    activeId,
    isOpen,
    refetch: (newUrl?: string) =>
      doFetch({
        newUrl,
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      }),
    closeModal,
    deleteItem,
    confirmDelete,
    deleteLoading,
    Lister,
    sort,
    changeSort,
    bulkSelection,
    setBultSelection,
    search,
    changeSearch,
    isActive,
    setIsActive,
  };
};

export default useItemLister;
