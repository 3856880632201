/* eslint-disable import/no-extraneous-dependencies */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/jsx-props-no-spreading */
import { Flex, Select } from '@chakra-ui/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useEffect } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Amsterdam');

interface IProps {
  className?: string;
  type?: 'slot' | 'date';
  field: any;
  path: string;
  register: any;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
}

function RHFDateField({
  className,
  field,
  path,
  register,
  getValues,
  setValue,
  type = 'date',
}: IProps) {
  const { token } = useUserContext();
  const { doFetch, result: slots } = useFetch(`${process.env.REACT_APP_API_URL}`);

  const fetchTimeSlots = (day: string) =>
    doFetch({
      newUrl: `${process.env.REACT_APP_API_URL}/time_slots?slot=${day}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  useEffect(() => {
    if (!getValues(path) && !getValues('time_slot')) return;
    const timeValue = dayjs(getValues(path));
    if (type === 'date') {
      setValue(path, timeValue.format('YYYY-MM-DDTHH:mm'));
    } else {
      setValue(path, timeValue.format('YYYY-MM-DD'));
    }
  }, []);

  useEffect(() => {
    setValue('time_slot', getValues('time_slot'));
  }, [slots]);

  return type === 'date' ? (
    <input
      className={`RHF-date${className ? ` ${className}` : ''}`}
      type="datetime-local"
      id={path}
      {...register(path, {
        required: field.required ? path : false,
        setValueAs: (value: string) => new Date(value).getTime(),
      })}
    />
  ) : (
    <Flex gap={5}>
      <input
        className={`RHF-date${className ? ` ${className}` : ''}`}
        type="date"
        id={path}
        {...register(path, {
          required: field.required ? path : false,
          setValueAs: (value: string) => {
            const time = new Date(value).getTime();
            const dayjsValue = dayjs(time);
            const day = dayjsValue.format('dddd');
            fetchTimeSlots(day);
            return time;
          },
        })}
      />
      <Select
        className={`RHF-date${className ? ` ${className}` : ''}`}
        placeholder="Select time slot"
        {...register(`time_slot`, {
          required: field.required ? `time_slot` : false,
          setValueAs: (value: string) => value,
        })}
        width={250}
        style={{
          height: 50,
          padding: 0,
          paddingLeft: 20,
        }}
      >
        {slots &&
          slots?.data &&
          slots.data?.items &&
          slots.data.items.length > 0 &&
          slots.data.items.map((slot: any) => (
            <option key={slot._id} value={slot._id}>
              {slot.slot}
            </option>
          ))}
      </Select>
    </Flex>
  );
}

export default RHFDateField;
