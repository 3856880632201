/* eslint-disable react/jsx-props-no-spreading */
import { Box, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const INVITE_FORM = {
  endpoint: '/',
  itemPath: '',
  backPath: '',
  resetOnSave: true,
  schema: [
    {
      name: 'email',
      type: 'string',
      label: 'Email',
      required: true,
    },
    {
      name: 'roles',
      type: 'multiSelect',
      label: 'Roles',
      options: [
        {
          value: 'CMS',
          label: 'CMS',
        },
        {
          value: 'WORLD',
          label: 'World',
        },
        {
          value: 'PLATFORM',
          label: 'Platform',
        },
      ],
    },
    {
      name: 'firstname',
      type: 'string',
      label: 'First name',
      required: false,
    },
    {
      name: 'lastname',
      type: 'string',
      label: 'Last name',
      required: false,
    },
    {
      name: 'company_name',
      type: 'string',
      label: 'Company name',
      required: false,
    },
    {
      name: 'company_url',
      type: 'string',
      label: 'Company URL',
      required: false,
    },
    {
      name: 'subscriptions',
      type: 'array',
      label: 'Subscription',
      schema: [
        {
          name: 'subscription',
          type: 'ref',
          entity: 'subscription',
          label: 'Subscription',
          required: true,
          query: '?paid=null',
        },
      ],
    },
  ],
};

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

function InviteUser() {
  const { doFetch, result, loading } = useFetch(`${BASE_URL}/subscriptions?paid=null`);
  const { doFetch: invite, error, result: inviteResult } = useFetch(`${BASE_URL}/user/invite`);
  const { token } = useUserContext();
  const [initialValue, setInitialValue] = useState<any>();
  const toast = useToast();

  useEffect(() => {
    doFetch({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  useEffect(() => {
    if (result && 'data' in result) {
      const hasDefault = result.data.items.find((item: any) => item?.default);

      if (hasDefault) {
        setInitialValue({
          email: '',
          roles: null,
          subscriptions: [
            {
              subscription: {
                label: hasDefault.name,
                subscription: hasDefault._id,
              },
            },
          ],
        });
      } else {
        const firstFree = result.data.items[0];
        setInitialValue({
          email: '',
          roles: null,
          subscriptions: [
            {
              subscription: {
                label: firstFree.name,
                subscription: firstFree._id,
              },
            },
          ],
        });
      }
    }
  }, [loading]);

  useEffect(() => {
    if (!inviteResult || inviteResult.status !== 'success') return;
    toast({
      title: inviteResult.message,
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    setInitialValue((prevState: any) => ({
      ...prevState,
      email: '',
      roles: null,
      firstname: '',
      lastname: '',
      company_name: '',
      company_url: '',
    }));
  }, [inviteResult]);

  useEffect(() => {
    if (!error) return;
    toast({
      title: error.message,
      position: 'top',
      status: 'error',
      duration: 10000,
      isClosable: true,
    });
  }, [error]);

  const onSubmit = (data: any) => {
    invite({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...data,
        subscriptions: data.subscriptions.map((sub: any) => sub.subscription.subscription),
      }),
    });
  };

  return (
    <section>
      <Box my={10}>
        {loading === false && result && result.data.items && (
          <RHForm onFormSubmit={onSubmit} itemValues={initialValue} {...INVITE_FORM} />
        )}
      </Box>
    </section>
  );
}

export default InviteUser;
