/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';

const FORM = {
  endpoint: 'pg_life_question',
  itemPath: 'pg-life-question',
  backPath: '/playground?tab=5',
  schema: [
    {
      name: 'question',
      type: 'string',
      label: 'Life question',
      required: true,
    },
    {
      name: 'section',
      type: 'select',
      label: 'Category',
      options: [
        {
          label: 'Category 1',
          value: 1,
        },
        {
          label: 'Category 2',
          value: 2,
        },
        {
          label: 'Category 3',
          value: 3,
        },
      ],
    },
  ],
};

function NewPgLifeQuestion() {
  const navigate = useNavigate();

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        <h1 className="h3">Create a new life question</h1>
        <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
          <RHForm {...FORM} />
        </Box>
      </Box>
    </section>
  );
}

export default NewPgLifeQuestion;
