import {
  Box,
  Flex,
  Heading,
  Icon,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FiInfo } from 'react-icons/fi';

import TableHeader from '../../common/TableHeader/TableHeader';
import useItemLister from '../../core/hooks/useItemLister';
import { ISubscriptionUser } from '../../core/models';

const COLS: {
  label: string;
  sort?: string;
  isNumeric?: boolean;
}[] = [
  {
    label: 'User ID',
  },
  {
    label: 'User Name',
  },
];

interface ISubscriptionUserListerProps {
  subscriptionId: string;
}

function SubscriptionUserLister({ subscriptionId }: ISubscriptionUserListerProps) {
  const { items, Lister, setIsActive } = useItemLister({
    endpointSingle: `subscription/users/${subscriptionId}`,
    endpointPlural: `subscription/users/${subscriptionId}`,
    listerMinHeight: 5,
    showTotal: false,
  });

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  return (
    <Box bg="white" p={5} borderRadius={6} boxShadow="lg" flex="1 1 auto">
      <Flex>
        <Heading fontSize={24} display="flex" alignItems="center">
          Subscribers
        </Heading>
        <Tooltip
          label="Users that are subscribed to this subscription."
          hasArrow
          p={5}
          borderRadius={5}
        >
          <Tag mx={3} p={1}>
            <Icon fontSize={15} as={FiInfo} />
          </Tag>
        </Tooltip>
      </Flex>
      {items?.length > 0 ? (
        <>
          {Lister({
            children: (
              <Box my={5}>
                <TableContainer fontSize="1rem" boxShadow="xl">
                  <Table variant="striped" colorScheme="facebook">
                    <TableHeader cols={COLS} />
                    <Tbody>
                      {items?.map((user: ISubscriptionUser) => (
                        <Tr key={user.user_id}>
                          <Td py={3} verticalAlign="middle">
                            <button style={{ padding: '6px' }} type="button">
                              {user.user_id}
                            </button>
                          </Td>
                          <Td py={3} verticalAlign="middle">
                            <button style={{ padding: '6px' }} type="button">
                              {user.name}
                            </button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            ),
          })}
        </>
      ) : (
        <Flex justifyContent="center" alignItems="center" height={50}>
          <Text>No users are subscribed to this subscription.</Text>
        </Flex>
      )}
    </Box>
  );
}

export default SubscriptionUserLister;
