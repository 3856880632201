/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Control, UseFormRegister, UseFormSetValue, useController } from 'react-hook-form';
import { FiXCircle } from 'react-icons/fi';

import useItemLister from '../../core/hooks/useItemLister';
import useModal from '../../core/hooks/useModal';
import ImageGrid from '../ImageGrid/ImageGrid';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

interface IProps {
  setValue: UseFormSetValue<any>;
  path: string;
  getValues: any;
  register: UseFormRegister<any>;
  field: any;
  control: Control<any, any>;
}

function RHFImageNameField({ setValue, path, getValues, register, field, control }: IProps) {
  const { modal, open, close, isOpen } = useModal();
  const btnRef = useRef(null);
  const [disable, setDisable] = useState(
    path.includes('.action.props.') && getValues(path) === undefined,
  );
  const { items, Lister, setIsActive } = useItemLister({
    endpointSingle: 'image',
    endpointPlural: 'images',
    filters: ['title', 'alt'],
  });
  const { field: imageField, fieldState } = useController({
    name: path,
    control,
    rules: {
      required: field.required ? path : undefined,
    },
  });
  const imageSrc = `${process.env.REACT_APP_API_URL}/uploads/${imageField.value}`;

  const openImageModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [btnRef]);

  const addImage = useCallback(
    async (id: string) => {
      const result = await fetch(`${BASE_URL}/image/${id}`);
      const data = await result.json();

      setValue(path, data.data.uri, { shouldDirty: true });
    },
    [path],
  );

  useEffect(() => {
    setIsActive(isOpen);
  }, [isOpen]);

  return (
    <>
      <Flex align="center" gap={5}>
        <input
          className={`input__field${fieldState.error ? ' error' : ''}`}
          id={path}
          {...register(path, {
            validate: field.validate || null,
            required: field.required ? path : false,
            disabled: disable,
          })}
        />
        <button
          id={path}
          className={`image-btn${fieldState.error ? ' error' : ''}`}
          type="button"
          ref={btnRef}
          onClick={openImageModal}
        >
          {imageField.value ? (
            <img
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'contain',
              }}
              src={imageSrc}
              alt=""
            />
          ) : (
            'Choose an image'
          )}
        </button>
        {!imageField.value ? null : (
          <IconButton
            colorScheme="red"
            size="sm"
            aria-label="Delete image"
            fontSize={20}
            icon={<FiXCircle />}
            onClick={() => setValue(path, '', { shouldDirty: true })}
          />
        )}
      </Flex>
      {path.includes('.action.props.') ? (
        <Box mt={2} className="input input--checkbox">
          <label className="input__checkbox-label" htmlFor={`${path}-skip`}>
            Disable
            <input
              className="input__checkbox"
              id={`${path}-skip`}
              type="checkbox"
              checked={disable}
              onChange={(e) => {
                setDisable(e.target.checked);
              }}
            />
            <CheckIcon className="input__checkmark-icon" width={5} height={5} />
            <span className="input__checkmark" />
          </label>
        </Box>
      ) : null}
      {modal({
        children: (
          <ImageGrid callback={addImage} closeModal={close} items={items} Lister={Lister} />
        ),
      })}
    </>
  );
}

export default RHFImageNameField;
