/* eslint-disable @typescript-eslint/no-explicit-any */
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Heading, Wrap, WrapItem } from '@chakra-ui/react';
import { useCallback } from 'react';
import { Control, UseFormSetValue, useController } from 'react-hook-form';

interface IProps {
  newPath: string;
  path: string;
  field: any;
  checkError: any;
  setValue: UseFormSetValue<any>;
  getValues?: any;
  control: Control<any, any>;
}

interface IOption {
  value: string;
  label: string;
}

function RHMultiSelect({ newPath, path, field, checkError, setValue, control, getValues }: IProps) {
  const { field: controllerField } = useController({
    name: newPath,
    control,
  });
  const dependencyValues = field?.dependency ? getValues(`${path}${field?.dependency}`) : null;
  const value = controllerField.value || [];

  const onChange = useCallback(
    (e: any, changedValue: string) => {
      if (e.target.checked) {
        setValue(newPath, [...value, changedValue], { shouldDirty: true });
      } else {
        setValue(
          newPath,
          value?.filter((el: string) => el !== changedValue),
          { shouldDirty: true },
        );
      }
    },
    [value, newPath],
  );

  return (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <Heading mb={1} fontSize={18} fontWeight={500}>
        {field.label}
      </Heading>
      <Wrap spacing={10} py={3}>
        {dependencyValues
          ? dependencyValues[field.name]?.map((option: any) => (
              <WrapItem key={`${newPath}.${option._id}`}>
                <div className={`input input--checkbox${!checkError() ? '' : ' error'}`}>
                  {!option.slug ? null : (
                    <label className="input__checkbox-label" htmlFor={`${newPath}.${option.slug}`}>
                      {option.slug}
                      <input
                        id={`${newPath}.${option.slug}`}
                        type="checkbox"
                        name={`${newPath}.${option.slug}`}
                        checked={value?.includes(option._id)}
                        onChange={(e) => onChange(e, option._id)}
                        className="input__checkbox"
                      />
                      {!value?.includes(option._id) ? null : (
                        <CheckIcon className="input__checkmark-icon" width={5} height={5} />
                      )}
                      <span className="input__checkmark" />
                    </label>
                  )}
                </div>
              </WrapItem>
            ))
          : field.options?.map((option: IOption) => (
              <WrapItem key={`${newPath}.${option.value}`}>
                <div className={`input input--checkbox${!checkError() ? '' : ' error'}`}>
                  {!option.label ? null : (
                    <label className="input__checkbox-label" htmlFor={`${newPath}.${option.value}`}>
                      {option.label}
                      <input
                        id={`${newPath}.${option.value}`}
                        type="checkbox"
                        name={`${newPath}.${option.value}`}
                        checked={value?.includes(option.value)}
                        onChange={(e) => onChange(e, option.value)}
                        className="input__checkbox"
                      />
                      {!value?.includes(option.value) ? null : (
                        <CheckIcon className="input__checkmark-icon" width={5} height={5} />
                      )}
                      <span className="input__checkmark" />
                    </label>
                  )}
                </div>
              </WrapItem>
            ))}
      </Wrap>
    </Box>
  );
}

export default RHMultiSelect;
