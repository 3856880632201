/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { Control, UseFormSetValue, useController } from 'react-hook-form';

import LocationInput from '../../common/LocationInput/LocationInput';
import { useFormInput } from '../../core/hooks/useFormInput';

interface IProps {
  path: string;
  setValue: UseFormSetValue<any>;
  control: Control<any, any>;
  field: any;
}

function RHLocationField({ path, field, setValue, control }: IProps) {
  const { field: controllerField } = useController({
    name: path,
    control,
  });

  const input = useFormInput(
    controllerField.value
      ? `${
          controllerField.value.city
            ? `${
                controllerField.value.country
                  ? `${controllerField.value.city}, ${controllerField.value.country}`
                  : controllerField.value.city
              }`
            : controllerField.value.country
            ? controllerField.value.country
            : ''
        }`
      : '',
  );

  const setCurrentLocation = useCallback(
    (location: any) => {
      setValue(path, location, { shouldDirty: true });
    },
    [path],
  );

  return (
    <LocationInput
      id={field.name}
      placeholder=""
      name={field.name}
      input={input}
      label={field.label}
      onChange={(location) => setCurrentLocation(location)}
    />
  );
}

export default RHLocationField;
