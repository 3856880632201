import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  AlertDialog as Dialog,
} from '@chakra-ui/react';
import { useRef } from 'react';

interface IProps {
  title: string;
  description: string;
  isOpen: boolean;
  onClose: () => void;
  onAction: () => void;
  actions: string[];
}

function AlertDialog({ isOpen, onClose, onAction, actions, title, description }: IProps) {
  const cancelRef = useRef(null);

  return (
    <Dialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="2rem" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody fontSize="1rem" mb={5}>
            {description}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {actions[0]}
            </Button>
            <Button colorScheme="red" onClick={onAction} ml={3}>
              {actions[1]}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </Dialog>
  );
}

export default AlertDialog;
