/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Divider, Flex, Heading, Wrap, WrapItem, useToast } from '@chakra-ui/react';
import { ReactElement, cloneElement, useEffect, useMemo, useRef } from 'react';

import CustomCheckbox from '../../common/CustomCheckbox/CustomCheckbox';
import CustomInput from '../../common/CustomInput/CustomInput';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import { useFormCheckbox } from '../../core/hooks/useFormCheckbox';
import { useFormInput } from '../../core/hooks/useFormInput';
import useModal from '../../core/hooks/useModal';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

interface IAccountInvitationModalProps {
  button: ReactElement;
  id: string;
}

function AccountInvitationModal({ button, id }: IAccountInvitationModalProps) {
  const { open, modal, close } = useModal();
  const openRef = useRef(null);
  const email = useFormInput('');
  const toast = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { result, doFetch, loading, error } = useFetch(`${BASE_URL}/user/invite`);
  const { user } = useUserContext();
  const roleCMS = useFormCheckbox(false);
  const roleWorld = useFormCheckbox(false);
  const rolePlatform = useFormCheckbox(false);

  const userRoles = useMemo(() => {
    const arr = [];
    if (roleCMS.checked) arr.push('CMS');
    if (roleWorld.checked) arr.push('WORLD');
    if (rolePlatform.checked) arr.push('PLATFORM');
    return arr;
  }, [roleCMS.checked, roleWorld.checked, rolePlatform.checked]);

  const openModal = () => {
    if (!openRef.current) return;
    open(openRef.current);
  };

  const send = () => {
    doFetch({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email.value,
        member_id: id,
        roles: userRoles,
      }),
    });
  };

  useEffect(() => {
    if (result?.status !== 'success') return;
    toast({
      title: result.message,
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    email.setValue('');
    close();
  }, [result]);

  useEffect(() => {
    if (!error) return;
    toast({
      title: error.message,
      position: 'top',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  }, [error]);

  return (
    <>
      <div>{cloneElement(button, { onClick: openModal, ref: openRef })}</div>
      {modal({
        children: (
          <Flex flexDirection="column">
            <Flex p={4} alignItems="center" justifyContent="center">
              <h1
                style={{
                  fontSize: '1.3rem',
                }}
              >
                Send invite
              </h1>
            </Flex>
            <Divider />
            <Flex p={4} flexDirection="column">
              <CustomInput
                id="email"
                name="email"
                input={email}
                placeholder="Email"
                label="Email"
              />
              <Heading mb={1} fontSize={18} fontWeight={500}>
                Roles
              </Heading>
              <Wrap spacing={10} py={3}>
                <WrapItem>
                  <CustomCheckbox id="cms-modal" name="cms-modal" input={roleCMS} label="CMS" />
                </WrapItem>
                <WrapItem>
                  <CustomCheckbox
                    id="world-modal"
                    name="world-modal"
                    input={roleWorld}
                    label="World"
                  />
                </WrapItem>
                <WrapItem>
                  <CustomCheckbox
                    id="platform-modal"
                    name="platform-modal"
                    input={rolePlatform}
                    label="Platform"
                  />
                </WrapItem>
              </Wrap>
              <Button onClick={send} colorScheme="messenger">
                Send invite
              </Button>
            </Flex>
          </Flex>
        ),
      })}
    </>
  );
}

export default AccountInvitationModal;
