import { Wrapper } from '@googlemaps/react-wrapper';
import React from 'react';
import ReactDOM from 'react-dom/client';

import AppRoutes from './AppRoutes';
import AppContextProvider from './core/context/appContextProvider';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY || '';

if (process.env.REACT_APP_GIT_COMMIT_HASH) {
  // eslint-disable-next-line no-console
  console.log('version', process.env.REACT_APP_GIT_COMMIT_HASH);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <Wrapper libraries={['places']} apiKey={GOOGLE_MAPS_API_KEY}>
        <AppRoutes />
      </Wrapper>
    </AppContextProvider>
  </React.StrictMode>,
);
