import { Badge, Box, Button, Flex, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TableActions from '../../common/TableActions/TableActions';
import TableHeader from '../../common/TableHeader/TableHeader';
import useItemLister from '../../core/hooks/useItemLister';

const COLS: {
  label: string;
  sort?: string;
  isNumeric?: boolean;
}[] = [
  {
    label: 'Country',
  },
  {
    label: 'Country Code',
  },
  {
    label: 'Standard Rate ( % )',
  },
  {
    label: 'Actions',
    isNumeric: true,
  },
];

function VATLister() {
  const { items, deleteItem, deleteLoading, Lister, activeId, sort, changeSort, setIsActive } =
    useItemLister({
      endpointSingle: 'vat',
      endpointPlural: 'vats',
      filters: ['country', 'code'],
    });

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
        <h1 className="h3">Country Specific VAT %</h1>
        <Button
          colorScheme="messenger"
          type="button"
          shadow="lg"
          ml="auto"
          onClick={() => navigate(`/vat/new`)}
        >
          Create a new country specific rate
        </Button>
      </Flex>
      {Lister({
        children: (
          <Box my={5}>
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader cols={COLS} sort={sort} changeSort={changeSort} />
                <Tbody>
                  {items?.map((vat: any) => (
                    <Tr key={vat._id}>
                      <Td verticalAlign="middle">{vat.country}</Td>
                      <Td verticalAlign="middle">{vat.code}</Td>
                      <Td verticalAlign="middle">{vat.vat_p}%</Td>
                      <Td py={3} verticalAlign="right">
                        <TableActions
                          item={vat}
                          path="vat"
                          activeId={activeId}
                          deleteItem={deleteItem}
                          deleteLoading={deleteLoading}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </>
  );
}

export default VATLister;
