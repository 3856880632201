/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Icon, IconButton, Tag, Tooltip, useCallbackRef } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { FiImage, FiInfo } from 'react-icons/fi';

import useItemLister from '../../core/hooks/useItemLister';
import useModal from '../../core/hooks/useModal';
import ImageGrid from '../ImageGrid/ImageGrid';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

interface IProps {
  path: string;
  field: any;
  checkError: any;
  register: UseFormRegister<any>;
  setValue: any;
  getValues: any;
}

function RHFPGMarkdownField({ path, field, checkError, register, setValue, getValues }: IProps) {
  const btnRef = useRef(null);
  const { modal, open, close, isOpen } = useModal();
  const { items, Lister, setIsActive } = useItemLister({
    endpointSingle: 'image',
    endpointPlural: 'images',
    filters: ['title', 'alt'],
  });

  useEffect(() => {
    setIsActive(isOpen);
  }, [isOpen]);

  const openImageModal = useCallbackRef(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [btnRef]);

  const addImage = useCallback(
    async (id: string) => {
      const result = await fetch(`${BASE_URL}/image/${id}`);
      const data = await result.json();
      const value = getValues(path);

      setValue(
        path,
        `${value}${value === '' ? '' : '\n\n'}![${data.data.alt}](${BASE_URL}/uploads/${
          data.data.sizes?.medium?.uri || data.data.uri
        })`,
        { shouldDirty: true },
      );
    },
    [path],
  );

  return (
    <>
      <Box
        _notLast={{
          mb: 5,
        }}
      >
        <label htmlFor={path}>{field.label}</label>
        {!field.info ? null : (
          <Tooltip label={field.info} hasArrow p={5} borderRadius={5}>
            <Tag mx={3} mb={2} p={1}>
              <Icon fontSize={15} as={FiInfo} />
            </Tag>
          </Tooltip>
        )}
        <textarea
          className={`input__field${checkError() ? ' error' : ''}`}
          rows={field.rows || 6}
          id={path}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(path, {
            required: field.required ? path : false,
          })}
        />
        <Tooltip label="Add image to body">
          <IconButton
            ref={btnRef}
            variant="outline"
            type="button"
            colorScheme="facebook"
            size="md"
            mt={5}
            fontSize={25}
            aria-label="Add image to body"
            icon={<FiImage />}
            onClick={openImageModal}
          />
        </Tooltip>
      </Box>
      {modal({
        children: (
          <ImageGrid callback={addImage} closeModal={close} items={items} Lister={Lister} />
        ),
      })}
    </>
  );
}

export default RHFPGMarkdownField;
