/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Tag } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const FORM = {
  endpoint: 'life_question',
  itemPath: 'life_question',
  backPath: '/content?tab=11',
  schema: [
    {
      name: 'question',
      type: 'string',
      label: 'Life question',
      required: true,
    },
  ],
};

function EditLifeQuestion() {
  const params = useParams();
  const navigate = useNavigate();
  const {
    doFetch: getLifeQuestion,
    result,
    error,
  } = useFetch(`${process.env.REACT_APP_API_URL}/life_question/${params.id}`);

  const { token } = useUserContext();

  useEffect(() => {
    getLifeQuestion({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  }, []);

  useEffect(() => {
    if (error) {
      navigate('/content?tab=11');
    }
  }, [error]);

  const values = useMemo(() => {
    if (result) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { created, _id, updated, ...data } = result.data;
      return data;
    }
    return {};
  }, [result]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {result && (
          <>
            <h1 className="h3">Edit life question</h1>
            <Tag mt={2} colorScheme="facebook">
              {result.data._id}
            </Tag>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm
                itemValues={values}
                id={result.data._id}
                fetchItem={getLifeQuestion}
                withPublish
                {...FORM}
              />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default EditLifeQuestion;
