/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Tag } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import { Sequence } from '../../core/models';

const FORM = {
  endpoint: 'quote',
  itemPath: 'quote',
  backPath: '/playground?tab=2',
  schema: [
    {
      name: 'img',
      type: 'img',
    },
    {
      name: 'slug',
      type: 'string',
      label: 'Slug',
      required: true,
    },
    {
      name: 'author',
      type: 'string',
      label: 'Author',
      required: true,
    },
    {
      name: 'quote',
      type: 'textarea',
      label: 'Quote',
      required: true,
    },
  ],
};

function EditQuote() {
  const params = useParams();
  const navigate = useNavigate();
  const {
    doFetch: getQuote,
    result,
    error,
  } = useFetch(`${process.env.REACT_APP_API_URL}/quote/${params.id}`);

  const { token } = useUserContext();

  useEffect(() => {
    getQuote({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  }, []);

  useEffect(() => {
    if (error) {
      navigate('/playground?tab=2');
    }
  }, [error]);

  const values = useMemo(() => {
    if (result) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { created, _id, updated, sequences, ...data } = result.data;
      return data;
    }
    return {};
  }, [result]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {result && (
          <>
            <h1 className="h3">Edit quote</h1>
            <Tag mt={2} colorScheme="facebook">
              {result.data._id}
            </Tag>
            <br />
            {result.data?.sequences && result.data.sequences?.length > 0 && (
              <Tag mt={2} colorScheme="facebook">
                Sequences: {result.data.sequences.map((sequence: Sequence) => sequence.slug)}
              </Tag>
            )}
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={values} id={result.data._id} fetchItem={getQuote} {...FORM} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default EditQuote;
