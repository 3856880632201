import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import App from './App';
import Log from './Components/Log/Log';
import ProtectedRoute from './Components/ProtectedRoute';
import Arcticle from './Pages/Arcticle/Arcticle';
import Circle from './Pages/Circle/UserCircle';
import CommunityRole from './Pages/CommunityRole/CommunityRole';
import ContentManager from './Pages/ContentManager/ContentManager';
import Discount from './Pages/Discount/Discount';
import EditBrainsnack from './Pages/EditBrainsnack/EditBrainsnack';
import EditCase from './Pages/EditCase/EditCase';
import EditJourney from './Pages/EditJourney/EditJourney';
import EditLifeQuestion from './Pages/EditLifeQuestion/EditLifeQuestion';
import EditMemberClass from './Pages/EditMemberClass/EditMemberClass';
import EditPgLifeQuestion from './Pages/EditPgLifeQuestion/EditPgLifeQuestion';
import EditQuote from './Pages/EditQuote/EditQuote';
import EditTimeSlot from './Pages/EditTimeSlot/EditTimeSlot';
import EmailConfirm from './Pages/EmailConfirm/EmailConfirm';
import EmailSettings from './Pages/EmailSettings/EmailSettings';
import Expertise from './Pages/Expertise/Expertise';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Home from './Pages/Home/Home';
import Image from './Pages/Image/Image';
import Industry from './Pages/Industry/Industry';
import InvitationSettings from './Pages/InvitationSettings/InvitationSettings';
import Login from './Pages/Login/Login';
import Logs from './Pages/Logs/Logs';
import MediaLibrary from './Pages/MediaLibrary/MediaLibrary';
import Module from './Pages/Module/Module';
import NewLifeQuestion from './Pages/NewLifeQuestion/NewLifeQuestion';
import NewMember from './Pages/NewMember/NewMember';
import NewMemberClass from './Pages/NewMemberClass/NewMemberClass';
import NewPgLifeQuestion from './Pages/NewPgLifeQuestion/NewPgLifeQuestion';
import NewQuote from './Pages/NewQuote/NewQuote';
import NewTimeSlot from './Pages/NewTimeSlot/NewTimeSlot';
import Page from './Pages/Page/Page';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import Playground from './Pages/Playground/Playground';
import Profile from './Pages/Profile/Profile';
import ProfileForm from './Pages/ProfileForm/ProfileForm';
import Reservation from './Pages/Reservation/Reservation';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import Sequence from './Pages/Sequence/Sequence';
import Subscription from './Pages/Subscription/Subscription';
import Tag from './Pages/Tag/Tag';
import Transactions from './Pages/Transactions/Transactions';
import User from './Pages/User/User';
import UserInvited from './Pages/UserInvited/UserInvited';
import VAT from './Pages/VAT/VAT';
import Website from './Pages/Website/Website';
import World from './Pages/World/World';
import WorldPage from './Pages/WorldPage/WorldPage';
import { useUserContext } from './core/context/user.context';

function AppRoutes() {
  const { user } = useUserContext();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="login/" element={<Login />} />
        <Route path="forgot-password/" element={<ForgotPassword />} />
        <Route path="register/" element={<UserInvited />} />
        <Route element={<ProtectedRoute user={user} />}>
          <Route path="content/" element={<ContentManager />} />
          <Route path="media/" element={<MediaLibrary />} />
          <Route path="playground/" element={<Playground />} />
          <Route path="world/" element={<World />} />
          <Route path="website/" element={<Website />} />
          <Route path="payment-transactions/" element={<Transactions />} />
          <Route path="profile/" element={<Profile />} />
          <Route path="email/" element={<EmailSettings />} />
          <Route path="invitations/" element={<InvitationSettings />} />
          <Route path="new-quote/" element={<NewQuote />} />
          <Route path="new-life-question" element={<NewLifeQuestion />} />
          <Route path="new-pg-life-question" element={<NewPgLifeQuestion />} />
          <Route path="new-time-slot/" element={<NewTimeSlot />} />
          <Route path="log/">
            <Route index element={<Logs />} />
            <Route path=":id/" element={<Log />} />
          </Route>
          <Route path="quote/">
            <Route path=":id/" element={<EditQuote />} />
          </Route>
          <Route path="discount/">
            <Route path=":id/" element={<Discount />} />
          </Route>
          <Route path="user_circle/">
            <Route path=":id/" element={<Circle />} />
          </Route>
          <Route path="time-slot/">
            <Route path=":id/" element={<EditTimeSlot />} />
          </Route>
          <Route path="page/">
            <Route path=":id/" element={<Page />} />
          </Route>
          <Route path="world-page/">
            <Route path=":id/" element={<WorldPage />} />
          </Route>
          <Route path="image/">
            <Route path=":id/" element={<Image />} />
          </Route>
          <Route path="subscription/">
            <Route path=":id/" element={<Subscription />} />
          </Route>
          <Route path="article/">
            <Route path=":id/" element={<Arcticle />} />
          </Route>
          <Route path="vat/">
            <Route path=":id/" element={<VAT />} />
          </Route>
          <Route path="case/">
            <Route path=":id/" element={<EditCase />} />
          </Route>
          <Route path="journey/">
            <Route path=":id/" element={<EditJourney />} />
          </Route>
          <Route path="life-question/">
            <Route path=":id/" element={<EditLifeQuestion />} />
          </Route>
          <Route path="pg-life-question/">
            <Route path=":id/" element={<EditPgLifeQuestion />} />
          </Route>
          <Route path="new-member/" element={<NewMember />} />
          <Route path="new-member-class/" element={<NewMemberClass />} />
          <Route path="brainsnack/">
            <Route path=":id/" element={<EditBrainsnack />} />
          </Route>
          <Route path="class/">
            <Route path=":id/" element={<EditMemberClass />} />
          </Route>
          <Route path="tag/">
            <Route path=":id/" element={<Tag />} />
          </Route>
          <Route path="expertise/">
            <Route path=":id/" element={<Expertise />} />
          </Route>
          <Route path="community-role/">
            <Route path=":id/" element={<CommunityRole />} />
          </Route>
          <Route path="industry/">
            <Route path=":id/" element={<Industry />} />
          </Route>
          <Route path="module/">
            <Route path=":id/" element={<Module />} />
          </Route>
          <Route path="sequence/">
            <Route path=":id/" element={<Sequence />} />
          </Route>
          <Route path="reservation/">
            <Route path=":id/" element={<Reservation />} />
          </Route>
          <Route path="user/">
            <Route path=":id/" element={<User />} />
          </Route>
          <Route path="profile/">
            <Route path=":id/" element={<ProfileForm />} />
          </Route>
          <Route path="email-confirm/:hash" element={<EmailConfirm />} />
        </Route>
        <Route path="resetpassword" element={<ResetPassword />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>,
    ),
  );
  return <RouterProvider router={router} />;
}

export default AppRoutes;
