import { CopyIcon, EmailIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { FiUpload } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import TableActions from '../../common/TableActions/TableActions';
import TableHeader from '../../common/TableHeader/TableHeader';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import useItemLister from '../../core/hooks/useItemLister';

function InvitationLister() {
  const navigate = useNavigate();
  const {
    items,
    deleteItem,
    deleteLoading,
    Lister,
    activeId,
    sort,
    changeSort,
    refetch,
    setIsActive,
  } = useItemLister({
    endpointSingle: 'invitation',
    endpointPlural: 'invitations',
    initialSort: 'email',
    filters: ['email', 'roles'],
  });
  const toast = useToast();
  const {
    doFetch: resend,
    result,
    error,
  } = useFetch(`${process.env.REACT_APP_API_URL}/invitation/resend/:id`);
  const { token } = useUserContext();
  const { doFetch: bulkInvite, loading: bulkInviteLoading } = useFetch(
    `${process.env.REACT_APP_API_URL}/users/bulk-invite`,
  );

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  const csvRef = useRef<HTMLInputElement>(null);

  const onCSVUpload = async () => {
    if (csvRef && csvRef.current) {
      const { files } = csvRef.current;

      if (files && files.length > 0) {
        const file = files[0];
        const formData = new FormData();
        formData.append('csv', file);
        const data = await bulkInvite({
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        await refetch();
        toast({
          title: data.message,
          position: 'top',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        csvRef.current.value = '';
      }
    }
  };

  useEffect(() => {
    if (result && result.message) {
      toast({
        title: result.message,
        position: 'top',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [result]);

  useEffect(() => {
    if (error && error.message) {
      toast({
        title: error.message,
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [error]);

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" width="100%">
        <h1 className="h3">Invitations</h1>
        <Flex gap={2}>
          <Button
            colorScheme="messenger"
            type="button"
            shadow="lg"
            ml="auto"
            onClick={() => navigate(`/user/new`)}
          >
            Invite a new user
          </Button>
          <input
            onChange={onCSVUpload}
            ref={csvRef}
            type="file"
            accept=".csv"
            style={{ display: 'none' }}
          />
          <Button
            onClick={() => {
              if (csvRef && csvRef.current) {
                csvRef.current.click();
              }
            }}
            isLoading={bulkInviteLoading}
            colorScheme="messenger"
            shadow="lg"
            leftIcon={<FiUpload />}
          >
            Bulk invite from CSV
          </Button>
        </Flex>
      </Flex>
      {Lister({
        children: (
          <Box my={5}>
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader
                  sort={sort}
                  changeSort={changeSort}
                  cols={[
                    {
                      label: 'Email',
                      sort: 'email',
                    },
                    {
                      label: 'First name',
                      sort: 'firstname',
                    },
                    {
                      label: 'Last name',
                      sort: 'lastname',
                    },
                    {
                      label: 'Roles',
                    },
                    {
                      label: 'Actions',
                      isNumeric: true,
                    },
                  ]}
                />
                <Tbody>
                  {items?.map((invitation: any) => (
                    <Tr key={invitation._id}>
                      <Td py={3} verticalAlign="middle">
                        {invitation.email}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        {invitation?.profile ? invitation.profile.firstname : ''}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        {invitation?.profile ? invitation.profile.lastname : ''}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        {invitation.roles?.join(', ')}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        <TableActions
                          item={invitation}
                          path="profile"
                          activeId={activeId}
                          deleteItem={deleteItem}
                          deleteLoading={deleteLoading}
                          showEditButton={!!invitation?.profile}
                          customEditUrl={
                            invitation?.profile ? `/user/${invitation.profile._id}` : undefined
                          }
                          customActions={[
                            {
                              label: 'Copy invitation id',
                              onClick: () => {
                                navigator.clipboard.writeText(invitation.invitation_hash);
                                toast({
                                  title: 'Copied invitation id',
                                  position: 'top',
                                  status: 'success',
                                  duration: 2000,
                                  isClosable: true,
                                });
                              },
                              icon: <CopyIcon />,
                            },
                            {
                              label: 'Resend invitation',
                              onClick: () => {
                                resend({
                                  newUrl: `${process.env.REACT_APP_API_URL}/invitation/resend/${invitation._id}`,
                                  method: 'POST',
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                  },
                                });
                              },
                              icon: <EmailIcon />,
                            },
                          ]}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </Flex>
  );
}

export default InvitationLister;
