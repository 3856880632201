/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-empty */
import { Badge, Box, Button, Flex, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { decode } from 'js-base64';
import { useCallback, useEffect, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';

import TableHeader from '../../common/TableHeader/TableHeader';
import { useUserContext } from '../../core/context/user.context';
import useItemLister from '../../core/hooks/useItemLister';
import RHForm from '../RHForm/RHForm';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

const COLS: {
  label: string;
  sort?: string;
  isNumeric?: boolean;
}[] = [
  {
    label: 'Date created',
  },
  {
    label: 'Last update',
  },
  {
    label: 'Transaction ID',
  },
  {
    label: 'Status',
  },
  {
    label: 'User name',
  },
  {
    label: 'User ID',
  },
  {
    label: 'Adyen reference',
  },
  {
    label: 'Country Code',
  },
  {
    label: 'Total Amount ( EUR )',
  },
  {
    label: 'VAT Amount ( EUR )',
  },
  {
    label: 'VAT %',
  },
  {
    label: 'Net Amount ( EUR )',
  },
  {
    label: 'Note',
  },
];

const FORM = {
  endpoint: '',
  itemPath: '',
  backPath: '',
  schema: [
    {
      name: 'user',
      type: 'ref',
      entity: 'user',
      label: 'User',
    },
    {
      name: 'subscription',
      type: 'ref',
      entity: 'subscription',
      label: 'Subscription',
      query: '?paid=true',
    },
  ],
};

function PaymentTransactionLister() {
  const [initialValue, setInitialValue] = useState<any>({
    user: undefined,
    subscription: undefined,
  });
  const { items, Lister, sort, changeSort, setIsActive, refetch } = useItemLister({
    endpointSingle: 'payment_transaction',
    endpointPlural: 'payment_transactions?populate=user',
  });
  const { token } = useUserContext();
  const [searchParams] = useSearchParams();

  const handleDownload = useCallback(async () => {
    try {
      const res = await fetch(`${BASE_URL}/export/transactions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await res.blob();
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = `transaction_data_${new Date().toLocaleString('en-gb', {
        day: '2-digit',
        month: 'numeric',
        year: 'numeric',
      })}.csv`;
      a.click();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get('user')) {
      try {
        const decoded = decode(searchParams.get('user') || '');
        const json = JSON.parse(decoded);
        setInitialValue((prevState: any) => ({
          ...prevState,
          user: json,
        }));
      } catch {}
    }
    if (searchParams.get('subscription')) {
      try {
        const decoded = decode(searchParams.get('subscription') || '');
        const json = JSON.parse(decoded);
        setInitialValue((prevState: any) => ({
          ...prevState,
          subscription: json,
        }));
      } catch {}
    }
  }, [searchParams]);

  useEffect(() => {
    const url = new URL(`${BASE_URL}/payment_transactions`);

    if (initialValue.subscription) {
      url.searchParams.append('subscription', initialValue.subscription.subscription);
    }

    if (initialValue.user) {
      url.searchParams.append('user', initialValue.user.user_id);
    }

    refetch(url.toString());
  }, [initialValue]);

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  const onApply = (data: any) => {
    setInitialValue(data);
  };

  return (
    <>
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
        <h1 className="h3">Payment Transactions</h1>
        <Button
          onClick={handleDownload}
          colorScheme="messenger"
          shadow="lg"
          leftIcon={<FiDownload />}
        >
          Export to CSV
        </Button>
      </Flex>
      <div style={{ marginTop: 30 }}>
        <RHForm itemValues={initialValue} onFormSubmit={onApply} {...FORM} />
      </div>
      {Lister({
        children: (
          <Box my={5}>
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader cols={COLS} sort={sort} changeSort={changeSort} />
                <Tbody>
                  {items?.map((transaction: any) => (
                    <Tr key={transaction._id}>
                      <Td verticalAlign="middle">
                        {dayjs(transaction.created_at).format('DD-MM-YYYY HH:mm:ss')}
                      </Td>
                      <Td verticalAlign="middle">
                        {dayjs(transaction.updated_at).format('DD-MM-YYYY HH:mm:ss')}
                      </Td>
                      <Td verticalAlign="middle">
                        <Badge color="grey" fontSize={14}>
                          {transaction._id}
                        </Badge>
                      </Td>
                      <Td verticalAlign="middle">
                        <Badge
                          color={
                            transaction.status === 'completed'
                              ? 'green'
                              : transaction.status === 'pending'
                              ? 'orange'
                              : 'red'
                          }
                        >
                          {transaction.status}
                        </Badge>
                      </Td>
                      <Td verticalAlign="middle">{transaction?.user?.name || ''}</Td>
                      <Td verticalAlign="middle">
                        {' '}
                        <Badge color="grey" fontSize={14}>
                          {transaction?.user?._id || ''}
                        </Badge>
                      </Td>
                      <Td verticalAlign="middle">
                        {' '}
                        <Badge color="grey" fontSize={14}>
                          {transaction?.adyenReference || ''}
                        </Badge>
                      </Td>
                      <Td verticalAlign="middle">{transaction.country_code}</Td>
                      <Td verticalAlign="middle">{Number(transaction.amount).toFixed(2)}</Td>
                      <Td verticalAlign="middle">
                        {Number(
                          Number(Number(transaction.amount).toFixed(2)) * (transaction.vat_p / 100),
                        ).toFixed(2)}
                      </Td>
                      <Td verticalAlign="middle">{transaction.vat_p}%</Td>
                      <Td verticalAlign="middle">
                        {transaction.amount > 0.01
                          ? Number(
                              Number(Number(transaction.amount).toFixed(2)) -
                                Number(Number(transaction.amount).toFixed(2)) *
                                  (transaction.vat_p / 100),
                            ).toFixed(2)
                          : 0.0}
                      </Td>
                      <Td verticalAlign="middle">{transaction?.note || ''}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </>
  );
}

export default PaymentTransactionLister;
