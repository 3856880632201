import PaymentTransactionLister from '../../Components/PaymentTransactionLister/PaymentTransactionLister';
import VATLister from '../../Components/VATLister/VATLister';
import ItemTabs from '../../common/ItemTabs/ItemTabs';

const TABS = [
  { label: 'Transactions', component: <PaymentTransactionLister /> },
  { label: 'VAT % Configuration', component: <VATLister /> },
];

function Transactions() {
  return (
    <section>
      <ItemTabs path="payment-transactions" tabs={TABS} />
    </section>
  );
}

export default Transactions;
