import ArticleLister from '../../Components/ArticleLister/ArticleLister';
import CaseLister from '../../Components/CaseLister/CaseLister';
import CommunityRoleLister from '../../Components/CommunityRoleLister/CommunityRoleLister';
import ExpertiseLister from '../../Components/ExpertiseLister/ExpertiseLister';
import IndustriesLister from '../../Components/IndustriesLister/IndustriesLister';
import MemberClassLister from '../../Components/MemberClassLister/MemberClassLister';
import QuestionLister from '../../Components/QuestionLister/QuestionLister';
import TagsLister from '../../Components/TagsLister/TagsLister';
import UserLister from '../../Components/UserLister/UserLister';
import ItemTabs from '../../common/ItemTabs/ItemTabs';

const TABS = [
  { label: 'User', component: <UserLister /> },
  { label: 'Profile', component: <UserLister listType="accounts" /> },
  { label: 'Tags', component: <TagsLister /> },
  { label: 'Article', component: <ArticleLister /> },
  { label: 'Expertise', component: <ExpertiseLister /> },
  { label: 'Role', component: <CommunityRoleLister /> },
  { label: 'Industry', component: <IndustriesLister /> },
  { label: 'Class', component: <MemberClassLister /> },
  { label: 'Questions', component: <QuestionLister /> },
  { label: 'Case', component: <CaseLister /> },
];

function ContentManager() {
  return (
    <section>
      <ItemTabs path="content" tabs={TABS} />
    </section>
  );
}

export default ContentManager;
