import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Input, Spinner, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import CustomInput from '../../common/CustomInput/CustomInput';
import OutsideAlerter from '../../common/OutsideAlerter/OutsideAlerter';
import '../../common/TagField/TagField.css';
import { useFormInput } from '../../core/hooks/useFormInput';
import useItemLister from '../../core/hooks/useItemLister';
import { IQuestion, IUserQuestion } from '../../core/models';

interface Props {
  onChange?: (value: IUserQuestion[]) => void;
  value?: IUserQuestion[];
  setTouched?: React.Dispatch<React.SetStateAction<boolean>>;
}

function PgLifeQuestionInput({ onChange = () => {}, value = [], setTouched }: Props) {
  const { items, setIsActive, changeSearch, loading } = useItemLister({
    endpointSingle: 'pg_life_question',
    endpointPlural: 'pg_life_questions',
    perPage: 20,
    fixedPage: 1,
    filters: ['question'],
  });
  const [showOptions, setShowOptions] = useState(false);
  const search = useFormInput('', () => true);
  const [selected, setSelected] = useState<IUserQuestion[]>(value || []);

  useEffect(() => {
    changeSearch(search.value);
  }, [search.value]);

  useEffect(() => {
    setIsActive(showOptions);
    changeSearch('');
    search.setValue('');
  }, [showOptions]);

  const addItem = useCallback(
    (el: IQuestion) => {
      setSelected((prevState: IUserQuestion[]) => [
        ...prevState,
        {
          question: el,
          answer: '',
        },
      ]);
    },
    [items],
  );

  const tagOptions = useMemo(() => {
    if (!items) return [];
    const filteredItems =
      items.filter(
        (el: IQuestion) => !selected?.map((item) => item.question._id).find((e) => e === el._id),
      ) || [];
    return filteredItems.map((el: IQuestion) => (
      <button
        className="tag-field__option"
        type="button"
        key={el._id}
        onClick={() => {
          addItem(el);
          setShowOptions(false);
        }}
      >
        {el.question} <span className="tag-field__option-info">({el._id})</span>
      </button>
    ));
  }, [items, selected]);

  const handleItemRemoval = useCallback((id: string) => {
    if (setTouched) setTouched(true);
    setSelected((prevState) => prevState.filter((el) => el.question._id !== id));
  }, []);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  return (
    <Box
      bg="white"
      p={5}
      _notLast={{
        mb: 5,
      }}
      borderRadius={6}
      boxShadow="0 0 8px rgba(0,0,0,0.2)"
      flex="1 1 auto"
      className="tag-field"
    >
      <div className="tag-field__container">
        <OutsideAlerter onClick={() => setShowOptions(false)}>
          <CustomInput
            input={search}
            id="pg-life-questions"
            name="life-questions"
            label="Playground life questions"
            onFocus={() => setShowOptions(true)}
            placeholder="Playground life questions"
          />
          {!showOptions ? null : loading ? (
            <div className="tag-field__options">
              <Box p={3}>
                <Spinner />
              </Box>
            </div>
          ) : (
            <div className="tag-field__options">{tagOptions}</div>
          )}
        </OutsideAlerter>
      </div>
      <Flex flexDirection="column">
        {selected?.map((el: IUserQuestion) => (
          <Box
            bg="white"
            p={5}
            _notLast={{
              mb: 5,
            }}
            borderRadius={6}
            boxShadow="0 0 8px rgba(0,0,0,0.2)"
            flex="1 1 auto"
            display="flex"
            flexDirection="column"
            gap={3}
            className="tag-field"
            key={el.question._id}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize={18}>{el?.question.question}</Text>
              <IconButton
                onClick={() => handleItemRemoval(el.question._id)}
                size="xs"
                aria-label="close"
                icon={<CloseIcon />}
              />
            </Flex>
            <Input
              onChange={(e) => {
                if (setTouched) setTouched(true);
                setSelected((prevState: IUserQuestion[]) => {
                  const newState = [...prevState];
                  const elIdx = newState.findIndex(
                    (pe: IUserQuestion) => pe.question === el.question,
                  );
                  if (elIdx < 0) return prevState;
                  newState[elIdx] = {
                    question: prevState[elIdx].question,
                    answer: e.target.value,
                  };
                  return newState;
                });
              }}
              value={el?.answer}
            />
          </Box>
        ))}
      </Flex>
    </Box>
  );
}

export default PgLifeQuestionInput;
