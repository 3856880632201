import { Box, Button, Flex, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TableActions from '../../common/TableActions/TableActions';
import TableHeader from '../../common/TableHeader/TableHeader';
import useItemLister from '../../core/hooks/useItemLister';
import { ImageInterface } from '../../core/models';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

const FILTERS = ['title', 'alt'];

const COLS: {
  label: string;
  sort?: string;
  isNumeric?: boolean;
}[] = [
  {
    label: 'Thumbnail',
  },
  {
    label: 'Title',
    sort: 'title',
  },
  {
    label: 'Alt',
    sort: 'alt',
  },
  {
    label: 'Actions',
    isNumeric: true,
  },
];

function ImageLister({ userGeneratedOnly = false }: { userGeneratedOnly?: boolean }) {
  const navigate = useNavigate();
  const { items, activeId, deleteItem, deleteLoading, Lister, sort, changeSort, setIsActive } =
    useItemLister({
      endpointSingle: 'image',
      endpointPlural: `images?user_generated=${userGeneratedOnly ? 'true' : 'null'}`,
      filters: FILTERS,
      statisticsSlug: userGeneratedOnly ? 'user-generated-images' : 'images',
    });

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  return (
    <>
      <Flex>
        <h1 className="h3">Image</h1>
        <Button
          colorScheme="messenger"
          type="button"
          shadow="lg"
          ml="auto"
          onClick={() => navigate(`/image/new`)}
        >
          Create New
        </Button>
      </Flex>
      {Lister({
        children: (
          <Box my={5}>
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader cols={COLS} sort={sort} changeSort={changeSort} />
                <Tbody>
                  {items?.map((image: ImageInterface) => (
                    <Tr key={image._id}>
                      <Td py={3} verticalAlign="middle">
                        <button type="button" onClick={() => navigate(`/image/${image._id}`)}>
                          <img
                            style={{
                              width: '80px',
                              height: '80px',
                              objectFit: 'contain',
                            }}
                            src={`${BASE_URL}/uploads/${
                              image.mimetype === 'image/svg+xml'
                                ? image.uri
                                : image.sizes?.thumb?.uri || image.uri
                            }`}
                            alt={image.alt}
                          />
                        </button>
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        <button
                          style={{
                            padding: '6px',
                            maxWidth: '300px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          type="button"
                          onClick={() => navigate(`/image/${image._id}`)}
                        >
                          {image.title}
                        </button>
                      </Td>
                      <Td
                        py={3}
                        verticalAlign="middle"
                        style={{ maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                      >
                        {image.alt}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        <TableActions
                          item={image}
                          path="image"
                          activeId={activeId}
                          deleteItem={deleteItem}
                          deleteLoading={deleteLoading}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </>
  );
}

export default ImageLister;
