/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const FORM = {
  endpoint: 'discount',
  itemPath: 'discount',
  backPath: '/playground?tab=3',
  withPublish: false,
  schema: [
    {
      name: 'name',
      type: 'string',
      label: 'Name',
      required: true,
    },
    {
      name: 'code',
      type: 'string',
      label: 'Code',
      required: true,
    },
    {
      name: 'active',
      type: 'boolean',
      label: 'Active',
      required: false,
    },
    {
      name: 'discount_p',
      type: 'number',
      min: 0,
      max: 100,
      label: 'Discount %',
      required: true,
    },
    {
      name: 'runs',
      type: 'number',
      label: 'Runs',
      required: true,
    },
  ],
};

const DISCOUNT_ID = `${process.env.REACT_APP_API_URL}/discount`;

function Discount() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUserContext();
  const { result, doFetch, loading, error } = useFetch(`${DISCOUNT_ID}/${id}`);
  const discount = useMemo(() => result?.data, [result]);

  useEffect(() => {
    if (id === 'new') return;
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  const values = useMemo(() => {
    if (result) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { _id, updated, ...data } = result.data;
      return data;
    }
    return {};
  }, [result]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the discount type with id: {id}</AlertTitle>
          </Alert>
        ) : !discount ? (
          <>
            <h1 className="h3">Create new discount type</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm {...FORM} />
            </Box>
          </>
        ) : (
          <>
            <h1 className="h3">{discount.name}</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={values} id={discount._id} fetchItem={doFetch} {...FORM} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default Discount;
