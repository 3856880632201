/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef, useState } from 'react';

const useFetch = (url: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>('');
  const [result, setResult] = useState<any>(null);
  const abortController = useRef<any>(null);

  const doFetch = useCallback(
    async (options?: RequestInit & { newUrl?: string }) => {
      setLoading(true);
      setError('');
      if (abortController.current) abortController.current.abort();

      try {
        abortController.current = new AbortController();
        const res = await fetch(options?.newUrl ? options.newUrl : url, {
          signal: abortController.current.signal,
          ...options,
        });
        const resData = await res.json();

        if (!resData || resData.status === 'error' || resData.status === 'fail') {
          setError(resData);
          setResult(null);
          setLoading(false);
          return;
        }
        setError('');
        setResult(resData);
        setLoading(false);
        return resData;
      } catch (err: any) {
        if (err.name !== 'AbortError') {
          setError(err);
          setResult(null);
          setLoading(false);
        }
      }
    },
    [abortController, url],
  );

  return { loading, error, result, doFetch };
};

export default useFetch;
