import { Box, Button, Flex, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';

import CustomInput from '../../common/CustomInput/CustomInput';
import { useUserContext } from '../../core/context/user.context';
import { isEmpty, isValidEmail } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';

function EmailConfigurationEditor() {
  const { doFetch, result, error, loading } = useFetch(
    `${process.env.REACT_APP_API_URL}/email-config`,
  );
  const { doFetch: updateConfig } = useFetch(`${process.env.REACT_APP_API_URL}/email-config`);
  const fromNameInput = useFormInput('');
  const fromEmailInput = useFormInput('');
  const { token } = useUserContext();

  const toast = useToast();

  const update = async () => {
    if (fromEmailInput.value && fromNameInput.value && token) {
      const data = await updateConfig({
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from_email: fromEmailInput.value,
          from_name: fromNameInput.value,
        }),
      });

      toast({
        title: data.message,
        position: 'top',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (token) {
      doFetch({
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (result) {
      fromNameInput.setValue(result?.data?.from_name || '');
      fromEmailInput.setValue(result?.data?.from_email || '');
    }
  }, [result]);

  return (
    <>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <h1 className="h3" style={{ marginBottom: '20px' }}>
          Email configuration
        </h1>
      </Flex>
      <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
        <Flex justifyContent="flex-end" mb={5}>
          <Button onClick={update} colorScheme="messenger" type="button" shadow="lg" ml="auto">
            Save
          </Button>
        </Flex>
        <CustomInput
          input={fromNameInput}
          label="From name"
          name="from-name"
          id="from-name"
          placeholder="From name"
        />
        <CustomInput
          input={fromEmailInput}
          label="From email"
          name="from-email"
          id="from-email"
          placeholder="From email"
        />
      </Box>
    </>
  );
}

export default EmailConfigurationEditor;
