import { Box, Flex, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TableHeader from '../../common/TableHeader/TableHeader';
import useItemLister from '../../core/hooks/useItemLister';
import { ILog } from '../../core/models';

const COLS: {
  label: string;
  sort?: string;
  isNumeric?: boolean;
}[] = [
  {
    label: 'Source',
  },
  {
    label: 'CreatedAt',
    sort: 'created',
  },
];

function Logs() {
  const navigate = useNavigate();
  const { items, Lister, sort, changeSort, setIsActive } = useItemLister({
    endpointSingle: 'log',
    endpointPlural: 'logs',
  });

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  return (
    <>
      <Flex>
        <h1 className="h3">Logs</h1>
      </Flex>
      {Lister({
        children: (
          <Box my={5}>
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader cols={COLS} sort={sort} changeSort={changeSort} />
                <Tbody>
                  {items?.map((log: ILog) => (
                    <Tr key={log._id}>
                      <Td p={2} verticalAlign="middle">
                        <button
                          style={{
                            padding: '6px',
                            maxWidth: '300px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          type="button"
                          onClick={() => navigate(`/log/${log._id}`)}
                        >
                          {log.source}
                        </button>
                      </Td>
                      <Td p={2} verticalAlign="middle">
                        {new Date(log.created).toLocaleString('en-gb', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </>
  );
}

export default Logs;
