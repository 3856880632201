/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Icon, IconButton, Tag, Tooltip } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { FiImage, FiInfo, FiTwitter, FiVideo } from 'react-icons/fi';

import useItemLister from '../../core/hooks/useItemLister';
import useModal from '../../core/hooks/useModal';
import ImageGrid from '../ImageGrid/ImageGrid';
import TweetForm from '../TweetForm/TweetForm';
import VideoForm from '../VideoForm/VideoForm';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

interface IProps {
  path: string;
  field: any;
  checkError: any;
  register: UseFormRegister<any>;
  setValue: any;
  getValues: any;
}

function RHFMarkdownField({ path, field, checkError, register, setValue, getValues }: IProps) {
  const btnRef = useRef(null);
  const tweetBtnRef = useRef(null);
  const videoBtnRef = useRef(null);
  const { modal, open, close, isOpen } = useModal();
  const { modal: tweetModal, open: openTweet, close: closeTweetModal } = useModal();
  const { modal: videoModal, open: openVideo, close: closeVideoModal } = useModal();
  const { items, Lister, setIsActive } = useItemLister({
    endpointSingle: 'image',
    endpointPlural: 'images',
    filters: ['title', 'alt'],
  });
  const values = getValues();

  useEffect(() => {
    setIsActive(isOpen);
  }, [isOpen]);

  const openImageModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [btnRef]);

  const openTweetModal = useCallback(() => {
    if (!tweetBtnRef.current) return;
    openTweet(tweetBtnRef.current);
  }, [tweetBtnRef]);

  const openVideoModal = useCallback(() => {
    if (!videoBtnRef.current) return;
    openVideo(videoBtnRef.current);
  }, [videoBtnRef]);

  const addImage = useCallback(
    async (id: string) => {
      const result = await fetch(`${BASE_URL}/image/${id}`);
      const data = await result.json();
      const value = getValues(path);

      setValue(
        path,
        `${value}${value === '' ? '' : '\n\n'}![${data.data.alt}](@@SERVER_BASE_URL@@/uploads/${
          data.data.uri
        })`,
        { shouldDirty: true },
      );
    },
    [path],
  );

  const addTweet = useCallback(
    async (data: { text: string; via: string; related: string }) => {
      const value = getValues(path);
      setValue(
        path,
        `${value}${value === '' ? '' : '\n\n'}> ${
          data.text
        } [Click to tweet](https://twitter.com/intent/tweet?url=@@CLIENT_BASE_URL@@/${
          values.slug
        }&text=${encodeURIComponent(data.text)}&via=${data.via}&related=${data.related})`,
        { shouldDirty: true },
      );
      closeTweetModal();
    },
    [values, path],
  );

  const addVideo = useCallback(
    async (data: { source: string }) => {
      const value = getValues(path);
      setValue(path, `${value}${value === '' ? '' : '\n\n'}<video src="${data.source}"></video>`, {
        shouldDirty: true,
      });
      closeVideoModal();
    },
    [values, path],
  );

  return (
    <>
      <Box
        _notLast={{
          mb: 5,
        }}
      >
        <label htmlFor={path}>{field.label}</label>
        {!field.info ? null : (
          <Tooltip label={field.info} hasArrow p={5} borderRadius={5}>
            <Tag mx={3} mb={2} p={1}>
              <Icon fontSize={15} as={FiInfo} />
            </Tag>
          </Tooltip>
        )}
        <textarea
          className={`input__field${checkError() ? ' error' : ''}`}
          rows={field.rows || 6}
          id={path}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(path, {
            required: field.required ? path : false,
          })}
        />
        <Tooltip label="Add image to body">
          <IconButton
            ref={btnRef}
            variant="outline"
            type="button"
            colorScheme="facebook"
            size="md"
            mt={5}
            fontSize={25}
            aria-label="Add image to body"
            icon={<FiImage />}
            onClick={openImageModal}
          />
        </Tooltip>
        <Tooltip label={!values.slug ? 'Add a slug to enable' : 'Add tweet'}>
          <IconButton
            ref={tweetBtnRef}
            variant="outline"
            type="button"
            colorScheme="facebook"
            size="md"
            mt={5}
            ml={5}
            fontSize={25}
            aria-label="Add tweet to body"
            icon={<FiTwitter />}
            onClick={openTweetModal}
            isDisabled={!values.slug}
          />
        </Tooltip>
        <Tooltip label="Add a video">
          <IconButton
            ref={videoBtnRef}
            variant="outline"
            type="button"
            colorScheme="facebook"
            size="md"
            mt={5}
            ml={5}
            fontSize={25}
            aria-label="Add a video to body"
            icon={<FiVideo />}
            onClick={openVideoModal}
          />
        </Tooltip>
      </Box>
      {modal({
        children: (
          <ImageGrid callback={addImage} closeModal={close} items={items} Lister={Lister} />
        ),
      })}
      {tweetModal({
        children: <TweetForm onSubmit={addTweet} />,
      })}
      {videoModal({
        children: <VideoForm onSubmit={addVideo} />,
      })}
    </>
  );
}

export default RHFMarkdownField;
