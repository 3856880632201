import { Box } from '@chakra-ui/react';

import logo from '../../assets/symbol-white.svg';
import './Home.css';

function Home() {
  return (
    <section className="home">
      <Box p={10}>
        <h1 className="h2">Welcome to THNK CMS</h1>
        <div className="home__logo-container">
          <img className="home__logo" src={logo} alt="THNK logo" />
        </div>
      </Box>
    </section>
  );
}

export default Home;
