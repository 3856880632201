import { EditIcon, Icon } from '@chakra-ui/icons';
import { Box, Button, VStack } from '@chakra-ui/react';
import {
  FiCodesandbox,
  FiDollarSign,
  FiGlobe,
  FiImage,
  FiList,
  FiLogIn,
  FiLogOut,
  FiMail,
  FiSend,
  FiSettings,
} from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import logo from '../../assets/logo.svg';
import { useUserContext } from '../../core/context/user.context';
import './Navigation.css';

function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useUserContext();

  return (
    <aside className="nav">
      <Box px={5} py={10} background="white" height="100%" boxShadow="lg">
        <nav>
          <Link to="/">
            <img className="nav__logo" src={logo} alt="THNK logo" />
          </Link>
          <VStack align="stretch" mt={10} flexGrow={1} gap={2}>
            {!user.token ? (
              <Button
                leftIcon={<Icon as={FiLogIn} width={5} height={5} />}
                colorScheme="gray"
                variant={location.pathname.startsWith('/login') ? 'solid' : 'outline'}
                justifyContent="left"
                onClick={() => navigate('login')}
                style={{ marginTop: 'auto' }}
              >
                Log In
              </Button>
            ) : user.email ? (
              <>
                <Button
                  leftIcon={<Icon as={FiGlobe} width={5} height={5} />}
                  colorScheme="gray"
                  variant={location.pathname.startsWith('/website') ? 'solid' : 'outline'}
                  justifyContent="left"
                  onClick={() => navigate('website')}
                >
                  Website
                </Button>
                <Button
                  leftIcon={<EditIcon width={5} height={5} />}
                  colorScheme="gray"
                  variant={location.pathname.startsWith('/content') ? 'solid' : 'outline'}
                  justifyContent="left"
                  onClick={() => navigate('content')}
                >
                  Content Manager
                </Button>
                <Button
                  leftIcon={<Icon as={FiImage} width={5} height={5} />}
                  colorScheme="gray"
                  variant={location.pathname.startsWith('/media') ? 'solid' : 'outline'}
                  justifyContent="left"
                  onClick={() => navigate('media')}
                >
                  Media Library
                </Button>
                <Button
                  leftIcon={<Icon as={FiGlobe} width={5} height={5} />}
                  colorScheme="gray"
                  variant={location.pathname.startsWith('/world') ? 'solid' : 'outline'}
                  justifyContent="left"
                  onClick={() => navigate('world')}
                >
                  World Landing
                </Button>
                <Button
                  leftIcon={<Icon as={FiCodesandbox} width={5} height={5} />}
                  colorScheme="gray"
                  variant={location.pathname.startsWith('/playground') ? 'solid' : 'outline'}
                  justifyContent="left"
                  onClick={() => navigate('playground')}
                >
                  Playground
                </Button>
                <Button
                  leftIcon={<Icon as={FiSend} width={5} height={5} />}
                  colorScheme="gray"
                  variant={location.pathname.startsWith('/invitations') ? 'solid' : 'outline'}
                  justifyContent="left"
                  onClick={() => navigate('invitations')}
                >
                  Invitations
                </Button>
                <Button
                  leftIcon={<Icon as={FiMail} width={5} height={5} />}
                  colorScheme="gray"
                  variant={
                    location.pathname.startsWith('/email') &&
                    !location.pathname.startsWith('/email-confirm')
                      ? 'solid'
                      : 'outline'
                  }
                  justifyContent="left"
                  onClick={() => navigate('email')}
                >
                  Email
                </Button>
                <Button
                  leftIcon={<Icon as={FiDollarSign} width={5} height={5} />}
                  colorScheme="gray"
                  variant={
                    location.pathname.startsWith('/payment-transactions') ? 'solid' : 'outline'
                  }
                  justifyContent="left"
                  onClick={() => navigate('payment-transactions')}
                >
                  Transactions
                </Button>
                <Button
                  leftIcon={<Icon as={FiList} width={5} height={5} />}
                  colorScheme="gray"
                  variant={location.pathname.startsWith('/log') ? 'solid' : 'outline'}
                  justifyContent="left"
                  onClick={() => navigate('log')}
                >
                  Logs
                </Button>
                <Button
                  leftIcon={<Icon as={FiSettings} width={5} height={5} />}
                  colorScheme="gray"
                  variant={location.pathname.startsWith('/profile') ? 'solid' : 'outline'}
                  justifyContent="left"
                  onClick={() => navigate('profile')}
                  style={{ marginTop: 'auto' }}
                >
                  Profile
                </Button>
                <Button
                  leftIcon={<Icon as={FiLogOut} width={5} height={5} />}
                  colorScheme="gray"
                  variant="outline"
                  justifyContent="left"
                  onClick={logout}
                >
                  Log Out
                </Button>
              </>
            ) : null}
          </VStack>
        </nav>
      </Box>
    </aside>
  );
}

export default Navigation;
