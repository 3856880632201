/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable react/jsx-props-no-spreading */
import { DeleteIcon, Icon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  Heading,
  IconButton,
  Tag,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import {
  Control,
  FormState,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  useFieldArray,
} from 'react-hook-form';
import { FiArrowDown, FiArrowUp, FiCopy, FiInfo } from 'react-icons/fi';

import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
// eslint-disable-next-line import/no-cycle
import Field from './Field';

interface IProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  unregister: UseFormUnregister<any>;
  field: any;
  getFieldState: UseFormGetFieldState<any>;
  getValues: UseFormGetValues<any>;
  formState: FormState<any>;
  setValue: UseFormSetValue<any>;
  path?: string;
  trigger: any;
}

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

export default function ArrayField({
  control,
  register,
  unregister,
  field,
  getFieldState,
  getValues,
  formState,
  setValue,
  path = '',
  trigger,
}: IProps) {
  const { fields, append, remove, move, insert } = useFieldArray({
    control,
    name: `${path}${field.name}`,
    keyName: 'customID',
  });
  const { doFetch } = useFetch(`${BASE_URL}${field?.addAllPath}`);
  const { token } = useUserContext();
  const toast = useToast();

  const cloneItem = useCallback((index: number) => {
    insert(index + 1, getValues(`${path}.${field.name}.${index}`));
  }, []);

  const addAll = useCallback(async () => {
    const alreadyHasElements = getValues(`${path}.${field.name}`).length > 0;
    if (!alreadyHasElements) {
      const result = await doFetch({
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if ('data' in result) {
        if ('items' in result.data && result.data.items.length > 0) {
          const arr = result.data.items.map((item: any) => ({
            journey: {
              journey: item.slug,
              pg_modules: item.pg_modules,
            },
          }));
          append(arr);
        }
      }
    } else {
      toast({
        title: `Can't add all ${field.label.toLowerCase()} when there is already an element`,
        status: 'error',
        position: 'top',
      });
    }
  }, []);

  if (field.condition && field.conditionValues) {
    if (!field.conditionValues.includes(getValues(`${path}${field.condition}`))) {
      const values = getValues((path.length > 0 ? path : undefined) as any) || {};
      if (Object.keys(values).length > 0) {
        delete values[field.name];
      }

      return null;
    }
  }

  return (
    <Accordion
      allowToggle
      _notLast={{
        mb: 5,
      }}
    >
      <AccordionItem boxShadow="0 0 8px rgba(0,0,0,0.2)" borderRadius={6} p={2}>
        <AccordionButton borderRadius={6}>
          <Heading fontSize={24} display="flex" alignItems="center">
            {field.label}{' '}
            <Tag ml={5} fontWeight="bold">
              {fields.length}
            </Tag>
            {!field.info ? null : (
              <Tooltip label={field.info} hasArrow p={5} borderRadius={5}>
                <Tag mx={3} p={1}>
                  <Icon fontSize={15} as={FiInfo} />
                </Tag>
              </Tooltip>
            )}
          </Heading>
          <AccordionIcon ml="auto" />
        </AccordionButton>
        <AccordionPanel mt={5}>
          <ul>
            {fields.map((item: any, index) => (
              <li className="rhf-form__array-item" key={item.customID}>
                <Accordion allowToggle mb={5}>
                  <AccordionItem border="1px solid lightgray" p={2}>
                    <Flex width="100%" alignItems="center">
                      <AccordionButton borderRadius={6}>
                        <Heading fontSize={20} textAlign="left" noOfLines={1}>
                          {item.name
                            ? item.name
                            : item.action?.id
                            ? item.action?.id
                            : item.type
                            ? `${item.type}${item.title ? ` - ${item.title}` : ''}`
                            : item.action?.type
                            ? `${item.action.type}${
                                item.action?.objectType ? ` ${item.action.objectType}` : ''
                              }`
                            : `
                          ${path.replace('body', '').replace(/\./g, ' ')}
                          ${field.label} ${index}
                          ${!item.name ? '' : `: ${item.name}`}
                          ${!item.label ? '' : `: ${item.label}`}
                          ${!item.title ? '' : `: ${item.title}`}
                          ${!item.oldURL ? '' : `: ${item.oldURL}`}
                          ${!item.id ? '' : `: ${item.id}`}
                          ${!item.journey?.journey ? '' : `: ${item.journey.journey}`}
                          `}
                        </Heading>
                        <AccordionIcon ml="auto" />
                      </AccordionButton>
                      <IconButton
                        colorScheme="facebook"
                        size="sm"
                        fontSize={20}
                        ml={3}
                        aria-label="Move down"
                        icon={<FiArrowDown />}
                        onClick={() => move(index, index + 1)}
                        isDisabled={index >= fields.length - 1}
                      />
                      <IconButton
                        colorScheme="facebook"
                        size="sm"
                        fontSize={20}
                        ml={3}
                        aria-label="Move up"
                        icon={<FiArrowUp />}
                        onClick={() => move(index, index - 1)}
                        isDisabled={index <= 0}
                      />
                      <IconButton
                        colorScheme="facebook"
                        size="sm"
                        fontSize={20}
                        ml={3}
                        aria-label="Clone"
                        icon={<FiCopy />}
                        onClick={() => cloneItem(index)}
                        isDisabled={fields.length >= field.maxLength}
                      />
                      <IconButton
                        colorScheme="red"
                        size="sm"
                        ml={3}
                        aria-label="Delete"
                        icon={<DeleteIcon />}
                        onClick={() => remove(index)}
                      />
                    </Flex>
                    <AccordionPanel mt={5}>
                      {!Array.isArray(field.schema) ? (
                        <div />
                      ) : (
                        field.schema.map((innerField: any) => {
                          const newPath = `${path}${field.name}.${index}.`;
                          if (innerField.type === 'array')
                            return (
                              <ArrayField
                                key={newPath + innerField.name}
                                {...{
                                  control,
                                  register,
                                  unregister,
                                  formState,
                                  field: innerField,
                                  getFieldState,
                                  getValues,
                                  setValue,
                                  path: newPath,
                                  trigger,
                                }}
                              />
                            );
                          return (
                            <Field
                              key={
                                `${newPath}-${innerField.name}${
                                  innerField.schema ? `-${innerField.schema.length}` : ''
                                }` || innerField.type
                              }
                              {...{
                                control,
                                register,
                                unregister,
                                field: innerField,
                                getFieldState,
                                getValues,
                                formState,
                                setValue,
                                path: newPath,
                                trigger,
                              }}
                            />
                          );
                        })
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </li>
            ))}
          </ul>
          {fields.length >= field.maxLength ? null : (
            <Flex gap={2}>
              <Button
                colorScheme="messenger"
                type="button"
                shadow="lg"
                mt={5}
                onClick={() => {
                  append(field?.allowRawString ? '' : {});
                }}
              >
                Add {field.label}
              </Button>
              {field.addAllBtn && (
                <Button colorScheme="messenger" type="button" shadow="lg" mt={5} onClick={addAll}>
                  Add All {field.label}
                </Button>
              )}
            </Flex>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
