/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';
import { Control, UseFormSetValue, useController } from 'react-hook-form';

import { IUserQuestion } from '../../core/models';
import LifeQuestionInput from '../LifeQuestionInput/LifeQuestionInput';

interface IProps {
  path: string;
  setValue: UseFormSetValue<any>;
  control: Control<any, any>;
}

function RHLocationField({ path, setValue, control }: IProps) {
  const [touched, setTouched] = useState(false);
  const { field } = useController({
    name: path,
    control,
  });

  const onChange = useCallback(
    (value: any) => {
      setValue(
        path,
        value?.map((item: IUserQuestion) => ({
          answer: item.answer,
          question: item.question?._id,
        })),
        {
          shouldDirty: touched,
        },
      );
    },
    [touched, path],
  );

  return <LifeQuestionInput value={field.value} onChange={onChange} setTouched={setTouched} />;
}

export default RHLocationField;
