/* eslint-disable import/no-extraneous-dependencies */
import { CopyIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useCallback, useEffect } from 'react';
import { FiDownload } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import BulkCheckbox from '../../common/BulkCheckbox/BulkCheckbox';
import TableActions from '../../common/TableActions/TableActions';
import TableHeader from '../../common/TableHeader/TableHeader';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import useItemLister from '../../core/hooks/useItemLister';
import { RESERVATION_REQUEST_STATUS, ReservationRequest, User } from '../../core/models';

dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Amsterdam');

const FILTERS = ['pg_sequence', 'user', 'status_text'];

const COLS: {
  label: string;
  sort?: string;
  isNumeric?: boolean;
}[] = [
  {
    label: '',
  },
  {
    label: 'ID',
  },
  {
    label: 'Sequence',
    sort: 'sequence',
  },
  {
    label: 'Users',
    sort: 'users',
  },
  {
    label: 'Type',
  },
  {
    label: 'Date created',
  },
  {
    label: 'Last update',
  },
  {
    label: 'Status',
    sort: 'status',
  },
  {
    label: 'Mail sent',
  },
  {
    label: 'Actions',
    isNumeric: true,
  },
];
const SEND_INVITE_URL = `${process.env.REACT_APP_API_URL}/reservations/bulk-send`;
const BASE_URL = `${process.env.REACT_APP_API_URL}`;

function ReservationLister() {
  const toast = useToast();
  const navigate = useNavigate();
  const {
    items,
    deleteItem,
    deleteLoading,
    Lister,
    activeId,
    sort,
    changeSort,
    bulkSelection,
    setBultSelection,
    setIsActive,
    refetch,
  } = useItemLister({
    endpointSingle: 'reservation',
    endpointPlural: 'reservations?populate=users',
    filters: FILTERS,
  });
  const { token } = useUserContext();
  const { result, loading, doFetch } = useFetch(SEND_INVITE_URL);

  const handleDownload = useCallback(async () => {
    try {
      const res = await fetch(`${BASE_URL}/export/reservations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await res.blob();
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = `reservation_data_${new Date().toLocaleString('en-gb', {
        day: '2-digit',
        month: 'numeric',
        year: 'numeric',
      })}.csv`;
      a.click();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  const bulkSendInvites = useCallback(() => {
    doFetch({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reservations: bulkSelection,
      }),
    }).then(() => {
      setBultSelection([]);
      refetch();
    });
  }, [bulkSelection]);

  useEffect(() => {
    if (result?.status !== 'success') return;
    toast({
      title: result.message,
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  }, [result]);

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" width="100%">
        <h1 className="h3">Reservations</h1>
        <Flex gap={5}>
          <Button
            colorScheme="messenger"
            type="button"
            shadow="lg"
            ml="auto"
            onClick={() => navigate(`/reservation/new`)}
          >
            Create a new reservation
          </Button>
          <Button
            colorScheme="messenger"
            type="button"
            shadow="lg"
            ml="auto"
            onClick={handleDownload}
            leftIcon={<FiDownload />}
          >
            Export reservations
          </Button>
        </Flex>
      </Flex>
      {Lister({
        children: (
          <Box my={5}>
            {!bulkSelection.length ? null : (
              <Button
                colorScheme="messenger"
                type="button"
                shadow="lg"
                mb={5}
                onClick={bulkSendInvites}
                isLoading={loading}
                isDisabled={loading}
              >
                Send
              </Button>
            )}
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader sort={sort} changeSort={changeSort} cols={COLS} />
                <Tbody>
                  {items?.map((item: ReservationRequest) => (
                    <Tr key={item._id}>
                      <BulkCheckbox
                        disabled={item.type !== 'reservation'}
                        id={item._id}
                        bulkSelection={bulkSelection}
                        setBultSelection={setBultSelection}
                      />
                      <Td py={3} verticalAlign="middle">
                        {item._id}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        <button
                          style={{
                            padding: '6px',
                            maxWidth: '300px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          type="button"
                          onClick={
                            item.type === 'reservation'
                              ? () => navigate(`/reservation/${item._id}`)
                              : () => {}
                          }
                        >
                          {item.pg_sequence}
                        </button>
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        {item.type === 'reservation'
                          ? item.users
                              .map((user: User) => `${user.firstname} ${user.lastname}`)
                              .join(', ')
                          : item.type === 'reservation_match_request'
                          ? item.user.length > 0
                            ? `${item.user[0]?.firstname || ''} ${item.user[0]?.lastname || ''}`
                            : ''
                          : item.deciding_node.length > 0
                          ? `${item.deciding_node[0].firstname || ''} ${
                              item.deciding_node[0].lastname || ''
                            }${
                              item.requester_node.length > 0
                                ? `, ${item.requester_node[0].firstname || ''} ${
                                    item.requester_node[0].lastname || ''
                                  }`
                                : ''
                            }`
                          : ''}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        {item.type === 'reservation'
                          ? 'Reservation'
                          : item.type === 'reservation_match_request'
                          ? 'Open Request'
                          : item.type === 'reservation_request'
                          ? 'Reservation Request'
                          : ''}
                      </Td>
                      <Td verticalAlign="middle">
                        {'created' in item
                          ? dayjs(item.created).format('DD-MM-YYYY HH:mm:ss')
                          : dayjs(parseInt((item as any)?._id.substr(0, 8), 16) * 1000).format(
                              'DD-MM-YYYY HH:mm:ss',
                            )}
                      </Td>
                      <Td verticalAlign="middle">
                        {'updated' in item
                          ? dayjs(item.updated).format('DD-MM-YYYY HH:mm:ss')
                          : dayjs(parseInt((item as any)?._id.substr(0, 8), 16) * 1000).format(
                              'DD-MM-YYYY HH:mm:ss',
                            )}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        {item.status_text}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        {item.mail_sent ? 'Yes' : item.type === 'reservation' ? 'No' : 'N/A'}
                      </Td>
                      <Td py={3} verticalAlign="middle">
                        <TableActions
                          item={item}
                          path={item.type}
                          activeId={activeId}
                          showEditButton={item.type === 'reservation'}
                          deleteItem={(id) => deleteItem(id, item.type)}
                          deleteLoading={deleteLoading}
                          customActions={
                            item.type === 'reservation'
                              ? [
                                  {
                                    label: 'Copy reservation hash',
                                    onClick: () => {
                                      navigator.clipboard.writeText(item.hash);
                                      toast({
                                        title: 'Copied reservation hash',
                                        position: 'top',
                                        status: 'success',
                                        duration: 2000,
                                        isClosable: true,
                                      });
                                    },
                                    icon: <CopyIcon />,
                                  },
                                ]
                              : undefined
                          }
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </Flex>
  );
}

export default ReservationLister;
