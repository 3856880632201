import {
  Box,
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';
import { FiRotateCcw, FiUpload } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import TableActions from '../../common/TableActions/TableActions';
import TableHeader from '../../common/TableHeader/TableHeader';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import useItemLister from '../../core/hooks/useItemLister';
import { User } from '../../core/models';

const COLS: {
  label: string;
  sort?: string;
  isNumeric?: boolean;
}[] = [
  {
    label: 'Email',
    sort: 'email',
  },
  {
    label: 'Name',
    sort: 'name',
  },
  {
    label: 'Roles',
  },
  {
    label: 'Status',
  },
  {
    label: 'Date registered',
  },
  {
    label: 'Actions',
    isNumeric: true,
  },
];

const FILTERS = ['name', 'email', 'subscriptions', '_id'];

interface IUserListerProps {
  listType?: 'users' | 'accounts';
}

function UserLister({ listType = 'users' }: IUserListerProps) {
  const { isAdmin, token } = useUserContext();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    items,
    activeId,
    deleteItem,
    refetch,
    deleteLoading,
    Lister,
    sort,
    changeSort,
    setIsActive,
  } = useItemLister({
    endpointSingle: 'user',
    endpointPlural: listType === 'accounts' ? 'users/members?no_credentials' : 'users',
    statisticsSlug: listType === 'accounts' ? 'profiles' : undefined,
    initialSort: listType === 'accounts' ? 'name' : 'email',
    filters: FILTERS,
    withDownload: listType === 'users',
    filterInputs: listType === 'users',
  });
  const { doFetch: updateUser } = useFetch(`${process.env.REACT_APP_API_URL}/user/:id`);
  const { doFetch: bulkInvite, loading: bulkInviteLoading } = useFetch(
    `${process.env.REACT_APP_API_URL}/users/bulk-invite`,
  );

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  const isComplete = (user: User) => {
    const keys = [
      'firstname',
      'lastname',
      'pronouns',
      'bio',
      'social',
      'current_location',
      'birth_location',
      'phone',
      'phone_country_code',
      'expertises',
      'industries',
      'company',
      'title',
      'profile_pic',
    ];

    if (Object.keys(user).filter((k) => keys.includes(k)).length !== keys.length) {
      return false;
    }

    return Object.entries(user).every(([key, value]) => {
      if (keys.includes(key)) {
        return !!value;
      }

      return true;
    });
  };

  const csvRef = useRef<HTMLInputElement>(null);

  const onCSVUpload = async () => {
    if (csvRef && csvRef.current) {
      const { files } = csvRef.current;

      if (files && files.length > 0) {
        const file = files[0];
        const formData = new FormData();
        formData.append('csv', file);
        const data = await bulkInvite({
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        await refetch();
        toast({
          title: data.message,
          position: 'top',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        csvRef.current.value = '';
      }
    }
  };

  const reactivateUser = useCallback(async (id: string) => {
    await updateUser({
      newUrl: `${process.env.REACT_APP_API_URL}/user/${id}`,
      method: 'PATCH',
      body: JSON.stringify({
        is_removed: false,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    refetch();
    toast({
      title: 'User reactivated successfully',
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  }, []);

  return (
    <>
      <Flex justifyContent="space-between" width="100%">
        <h1 className="h3">{listType === 'accounts' ? 'Profile' : 'User'}</h1>
        {!isAdmin ? null : (
          <Flex gap={2}>
            {listType !== 'users' && (
              <Button
                colorScheme="messenger"
                type="button"
                shadow="lg"
                ml="auto"
                onClick={() => navigate(`/profile/new`)}
              >
                Create a new profile
              </Button>
            )}
            {listType !== 'accounts' && (
              <Flex gap={2}>
                <Button
                  colorScheme="messenger"
                  type="button"
                  shadow="lg"
                  ml="auto"
                  onClick={() => navigate(`/user/new`)}
                >
                  Invite a new user
                </Button>
                <input
                  onChange={onCSVUpload}
                  ref={csvRef}
                  type="file"
                  accept=".csv"
                  style={{ display: 'none' }}
                />
                <Button
                  onClick={() => {
                    if (csvRef && csvRef.current) {
                      csvRef.current.click();
                    }
                  }}
                  isLoading={bulkInviteLoading}
                  colorScheme="messenger"
                  shadow="lg"
                  leftIcon={<FiUpload />}
                >
                  Bulk invite from CSV
                </Button>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
      {Lister({
        children: (
          <Box my={5}>
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader
                  cols={
                    listType === 'accounts'
                      ? COLS.filter(
                          (col) =>
                            col.label !== 'Email' &&
                            col.label !== 'Roles' &&
                            col.label !== 'Date registered',
                        )
                      : COLS
                  }
                  sort={sort}
                  changeSort={changeSort}
                />
                <Tbody>
                  {items?.map((user: User) => (
                    <Tr key={user._id}>
                      {listType !== 'accounts' && (
                        <Td py={3} verticalAlign="middle">
                          <button
                            style={{ padding: '6px' }}
                            type="button"
                            onClick={() =>
                              navigate(
                                listType === 'users' ? `/user/${user._id}` : `/profile/${user._id}`,
                              )
                            }
                          >
                            {user.email}
                          </button>
                        </Td>
                      )}
                      <Td py={3} verticalAlign="middle">
                        <button
                          style={{ padding: '6px' }}
                          type="button"
                          onClick={() =>
                            navigate(
                              listType !== 'accounts'
                                ? `/user/${user._id}`
                                : `/profile/${user._id}`,
                            )
                          }
                        >
                          {user.name}
                        </button>
                      </Td>
                      {listType !== 'accounts' && (
                        <Td py={3} verticalAlign="middle">
                          {user.roles?.join(', ')}
                        </Td>
                      )}
                      {listType === 'users' ? (
                        <Td
                          py={3}
                          verticalAlign="middle"
                          textTransform="capitalize"
                          color={user.is_removed ? 'red' : 'green'}
                        >
                          {user.is_removed ? 'Removed' : 'Active'}
                        </Td>
                      ) : (
                        <Td
                          py={3}
                          verticalAlign="middle"
                          textTransform="capitalize"
                          color={isComplete(user) ? 'green' : 'red'}
                        >
                          {isComplete(user)
                            ? `Complete ${user.is_removed ? '( Removed )' : ''}`
                            : `Incomplete ${user.is_removed ? '( Removed )' : ''}`}
                        </Td>
                      )}
                      {listType === 'users' && (
                        <Td py={3} verticalAlign="middle">
                          {user?.date_registered
                            ? new Date(user.date_registered).toLocaleString('en-gb', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                              })
                            : ''}
                        </Td>
                      )}
                      <Td py={3} verticalAlign="middle">
                        <TableActions
                          item={user}
                          path={listType !== 'accounts' ? 'user' : 'profile'}
                          activeId={activeId}
                          deleteItem={deleteItem}
                          deleteLoading={deleteLoading}
                          invitationButton={listType === 'accounts'}
                          customActions={
                            user.is_removed
                              ? [
                                  {
                                    icon: <FiRotateCcw />,
                                    onClick: () => reactivateUser(user._id),
                                    label: 'Reactivate',
                                  },
                                ]
                              : undefined
                          }
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </>
  );
}

export default UserLister;
