/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDisclosure, useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import FormContainer from '../../Components/FormContainer/FormContainer';
import { useUserContext } from '../context/user.context';
import { compareArrays } from '../helpers';
import { CustomInputInteface } from '../models';
import useFetch from './useFetch';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

interface Props {
  endpoint: string;
  id?: string;
  body: any;
  fetchItem?: (options?: RequestInit) => void;
  itemPath?: string;
  self?: boolean;
  disabled?: boolean;
  inputs?: CustomInputInteface[];
  item?: any;
  withPublish?: boolean;
  noDelete?: boolean;
  backPath?: string;
  pureEndpoint?: boolean;
}

const useItemForm = ({
  endpoint,
  id,
  body,
  fetchItem,
  itemPath,
  self,
  disabled,
  inputs,
  item,
  withPublish,
  backPath,
  noDelete,
  pureEndpoint,
}: Props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { user: currentUser } = useUserContext();
  const {
    result: updatedItem,
    doFetch: doUpdateFetch,
    loading: updateLoading,
    error: updateError,
  } = useFetch(`${BASE_URL}/${endpoint}${self ? '' : `/${id}`}`);
  const {
    result: createdItem,
    doFetch: doCreateFetch,
    loading: createLoading,
    error: createError,
  } = useFetch(
    `${BASE_URL}/${endpoint}${endpoint === 'image' ? '/upload' : pureEndpoint ? '' : '/create'}`,
  );
  const {
    result: deletedItem,
    doFetch: doDeleteFetch,
    loading: deleteLoading,
    error: deleteError,
  } = useFetch(`${BASE_URL}/${endpoint}/${id}`);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const confirmDelete = useCallback(() => {
    doDeleteFetch({
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
    onClose();
  }, [doDeleteFetch]);

  const updateItem = useCallback(
    (data?: any) => {
      const { published, ...editableData } = data;
      doUpdateFetch({
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${currentUser.token}`,
        },
        body: JSON.stringify(editableData || body),
      });
    },
    [body],
  );

  const publishItem = useCallback(() => {
    doUpdateFetch({
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${currentUser.token}`,
      },
      body: JSON.stringify({
        published: !item.published,
      }),
    });
  }, [item]);

  const createItem = useCallback(
    (data?: any) => {
      if (endpoint === 'image') {
        const { img, ...rest } = body;
        const formData = new FormData();

        Object.keys(rest).forEach((el) => {
          if (!rest[el]) return;
          formData.append(el, rest[el]);
        });
        if (img) formData.append('img', img);

        doCreateFetch({
          method: 'POST',
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
          },
          body: formData,
        });
      } else {
        const newData = data || body;
        Object.keys(newData).forEach((el) => {
          if (newData[el]) return;
          delete newData[el];
        });
        doCreateFetch({
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${currentUser.token}`,
          },
          body: JSON.stringify(data || body),
        });
      }
    },
    [body, endpoint],
  );

  useEffect(() => {
    if (updatedItem?.status !== 'success' || !fetchItem) return;
    toast({
      title: updatedItem.message,
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    fetchItem({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [updatedItem]);

  useEffect(() => {
    if (createdItem?.status !== 'success') return;
    toast({
      title: createdItem.message,
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    if (endpoint !== 'user/invite') {
      navigate(`/${itemPath || endpoint}/${createdItem.data._id}`, { state: { created: true } });
    }
  }, [createdItem]);

  useEffect(() => {
    if (!createError && !updateError && !deleteError) return;
    toast({
      title: createError.message || updateError.message || deleteError.message,
      position: 'top',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  }, [createError, updateError, deleteError]);

  const deleteItem = useCallback(() => {
    onOpen();
  }, []);

  useEffect(() => {
    if (!deletedItem) return;
    toast({
      title: deletedItem.message,
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    navigate(backPath || '/');
  }, [deletedItem]);

  const Container = useCallback(
    ({ children }: { children: React.ReactNode }) => (
      <FormContainer
        updateItem={updateItem}
        createItem={createItem}
        deleteItem={deleteItem}
        deleteLoading={deleteLoading}
        isOpen={isOpen}
        onClose={onClose}
        confirmDelete={confirmDelete}
        updateLoading={updateLoading}
        createLoading={createLoading}
        disabled={disabled}
        inputs={inputs}
        item={item}
        publishItem={publishItem}
        withPublish={withPublish}
        // isSame={isSame}
        noDelete={noDelete}
      >
        {children}
      </FormContainer>
    ),
    [item, inputs, disabled, body],
  );

  return {
    updateItem,
    updateLoading,
    createItem,
    createLoading,
    publishItem,
    Container,
    deleteItem,
    deleteLoading,
    isOpen,
    onClose,
    confirmDelete,
  };
};

export default useItemForm;
