/* eslint-disable @typescript-eslint/no-explicit-any */
import { Icon } from '@chakra-ui/icons';
import { useMemo } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { SelectOption } from '../../core/models';

interface Props {
  id: string;
  name: string;
  info?: string;
  label?: string;
  value?: string;
  error?: string;
  options: SelectOption[];
  placeholder: string;
  emptyMessage?: string;
  disablePlaceholder?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?(e: React.FormEvent<HTMLSelectElement>): void;
  input?: any;
}

function CustomSelect({
  id,
  name,
  info,
  label,
  value,
  error,
  options,
  placeholder,
  emptyMessage,
  disablePlaceholder,
  className = '',
  disabled = false,
  onChange,
  input,
}: Props) {
  const hasError = useMemo(
    () => (!input?.showError ? '' : input?.isEmpty ? 'empty' : !input?.isValid ? 'invalid' : ''),
    [input?.showError, input?.isEmpty, input?.isValid],
  );

  return (
    <div className={`input${!error && !hasError ? '' : ' error'} ${className}`}>
      {!label ? null : !label ? null : (
        <div className="input__header">
          <label className="input__label" htmlFor={id}>
            {label}
          </label>
        </div>
      )}
      <div className="input__wrapper">
        <select
          className="input__field input__field--select"
          name={name}
          id={id}
          disabled={disabled}
          value={input?.value || value}
          onChange={input?.onChange || onChange}
        >
          <option value="" disabled={disablePlaceholder} hidden={disablePlaceholder}>
            {placeholder}
          </option>
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <Icon className="input__icon icon" as={FiChevronDown} />
        {!info ? null : <div className="input__info caption">{info}</div>}
      </div>
      {!error ? null : (
        <div className="input__error body-small">
          <span>{error === 'empty' ? emptyMessage : ''}</span>
        </div>
      )}
    </div>
  );
}

export default CustomSelect;
