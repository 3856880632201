import { Box } from '@chakra-ui/react';
import { useMemo } from 'react';

import EmailForm from '../../Components/EmailForm/EmailForm';
import PasswordForm from '../../Components/PasswordForm/PasswordForm';
import UserForm from '../../Components/UserForm/UserForm';
import ItemTabs from '../../common/ItemTabs/ItemTabs';
import { useUserContext } from '../../core/context/user.context';

function Profile() {
  const { user, getUser } = useUserContext();

  const tabs = useMemo(
    () => [
      {
        label: 'Profile',
        component: (
          <Box bg="white" p={5} borderRadius={6} boxShadow="lg" flex="1 1 auto">
            <h1 className="h3" style={{ marginBottom: '20px' }}>
              Profile
            </h1>
            <UserForm user={user} fetchUser={getUser} />
          </Box>
        ),
      },
      {
        label: 'Change password',
        component: <PasswordForm />,
      },
      {
        label: 'Change email',
        component: <EmailForm />,
      },
    ],
    [user, getUser],
  );

  return (
    <section>
      <ItemTabs path="profile" tabs={tabs} />
    </section>
  );
}

export default Profile;
