import { Box } from '@chakra-ui/react';

import ResetPasswordForm from '../../Components/ResetPasswordForm/ResetPasswordForm';

function ResetPassword() {
  return (
    <section>
      <h1 className="h3">Create new password</h1>
      <Box
        bg="white"
        p={5}
        borderRadius={6}
        boxShadow="lg"
        my={10}
        maxWidth={{ md: '100%', xl: 'calc(50% - 1.25rem)' }}
      >
        <ResetPasswordForm />
      </Box>
    </section>
  );
}

export default ResetPassword;
