/* eslint-disable react/jsx-props-no-spreading */
import { Alert, AlertIcon, AlertTitle, Box, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';

import {
  CFAQ,
  CWorldAudience,
  CWorldCommitment,
  CWorldHomeBanner,
  CWorldMission,
  CWorldProduct,
  CWorldTestimonials,
} from '../../core/components';
import useFetch from '../../core/hooks/useFetch';
import RHForm from '../RHForm/RHForm';

const PAGE_URL = `${process.env.REACT_APP_API_URL}/page?slug=world-home-page&world_page=true`;

function WorldHomePageForm() {
  const { result, doFetch, loading, error } = useFetch(`${PAGE_URL}`);

  const FORM = useMemo(
    () => ({
      endpoint: 'page',
      id: result?.data._id,
      backPath: '/website',
      schema: [
        {
          name: 'body',
          type: 'object',
          schema: [
            CWorldHomeBanner,
            {
              name: 'pageBuilder',
              type: 'component',
              label: 'Page Builder',
              components: [
                CWorldCommitment,
                CWorldMission,
                CWorldProduct,
                CWorldAudience,
                CWorldTestimonials,
                CFAQ,
              ],
            },
          ],
        },
      ],
    }),
    [result],
  );

  useEffect(() => {
    doFetch();
  }, []);

  return (
    <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
      {!loading ? null : <Spinner />}
      {!error ? null : (
        <Alert status="error" my={10}>
          <AlertIcon />
          <AlertTitle>Could not load world home page data</AlertTitle>
        </Alert>
      )}
      {!result ? null : (
        <RHForm
          itemValues={{ body: result.data?.body }}
          updated={result.data?.updated}
          fetchItem={doFetch}
          noDelete
          {...FORM}
        />
      )}
    </Box>
  );
}

export default WorldHomePageForm;
