import { Button, useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import { isEmpty, isValidPassword } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';

const BASE_URL = process.env.REACT_APP_API_URL;

function ResetPasswordForm() {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const id = useMemo(() => searchParams.get('h') || '', [searchParams]);
  const toast = useToast();
  const password = useFormInput('', (value) => isValidPassword(value));
  const repeatPassword = useFormInput('', (value) => !isEmpty(value) && value === password.value);
  const { result, doFetch, loading, error } = useFetch(`${BASE_URL}/user/reset-password/${id}`);

  const handleSubmit = useCallback(() => {
    doFetch({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: password.value,
      }),
    });
  }, [password]);

  useEffect(() => {
    if (!result || result.status !== 'success') return;
    toast({
      title: result.message,
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    navigate('/');
  }, [result]);

  useEffect(() => {
    if (!error) return;
    toast({
      title: error.message,
      position: 'top',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  }, [error]);

  if (!id) return null;

  return (
    <CustomForm inputs={[password, repeatPassword]} onSubmit={handleSubmit}>
      <CustomInput
        id="password"
        type="password"
        name="password"
        placeholder="Enter your new password"
        label="New Password"
        input={password}
        emptyMessage="Please fill in your new password"
        invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
      />
      <CustomInput
        id="repeat-new-password"
        type="password"
        name="repeat-new-password"
        placeholder="Repeat your new password"
        label="Repeat your new password"
        input={repeatPassword}
        emptyMessage="Please repeat your new password"
        invalidMessage="Your passwords don't match"
        autoComplete="off"
      />
      <Button
        isLoading={loading}
        isDisabled={loading}
        loadingText="Submitting"
        colorScheme="messenger"
        type="submit"
      >
        Save
      </Button>
    </CustomForm>
  );
}

export default ResetPasswordForm;
