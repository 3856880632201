import { Badge, Box, Button, Flex, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TableActions from '../../common/TableActions/TableActions';
import TableHeader from '../../common/TableHeader/TableHeader';
import useItemLister from '../../core/hooks/useItemLister';
import { Subscription } from '../../core/models/subscription';

const FILTERS = ['name'];

const COLS: {
  label: string;
  sort?: string;
  isNumeric?: boolean;
}[] = [
  {
    label: 'Name',
    sort: 'name',
  },
  {
    label: 'Description',
  },
  {
    label: 'Type',
  },
  {
    label: 'Active subscribers',
  },
  {
    label: 'Default',
  },
  {
    label: 'Actions',
    isNumeric: true,
  },
];

function SubscriptionLister() {
  const navigate = useNavigate();
  const { items, activeId, deleteItem, deleteLoading, Lister, sort, changeSort, setIsActive } =
    useItemLister({
      endpointSingle: 'subscription',
      endpointPlural: 'subscriptions',
      filters: FILTERS,
    });

  useEffect(() => {
    setIsActive(true);

    return () => {
      setIsActive(false);
    };
  }, []);

  return (
    <>
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
        <h1 className="h3">Subscriptions</h1>
        <Flex gap={2}>
          <Button
            colorScheme="messenger"
            type="button"
            shadow="lg"
            ml="auto"
            onClick={() => navigate(`/subscription/new-free`)}
          >
            Create New Free Subscription
          </Button>
          <Button
            colorScheme="messenger"
            type="button"
            shadow="lg"
            ml="auto"
            onClick={() => navigate(`/subscription/new-paid`)}
          >
            Create New Paid Subscription
          </Button>
        </Flex>
      </Flex>
      {Lister({
        children: (
          <Box my={5}>
            <TableContainer fontSize="1rem" boxShadow="xl">
              <Table variant="striped" colorScheme="facebook">
                <TableHeader cols={COLS} sort={sort} changeSort={changeSort} />
                <Tbody>
                  {items?.map((subscription: Subscription) => (
                    <Tr key={subscription._id}>
                      <Td p={2} verticalAlign="middle">
                        <button
                          style={{ padding: '6px' }}
                          type="button"
                          onClick={() => navigate(`/subscription/${subscription._id}`)}
                        >
                          {subscription.name}
                        </button>
                      </Td>
                      <Td verticalAlign="middle">{subscription.description}</Td>
                      <Td verticalAlign="middle">
                        {subscription.paid ? (
                          <Badge variant="solid" colorScheme="yellow">
                            Paid
                          </Badge>
                        ) : (
                          <Badge variant="solid" colorScheme="blackAlpha">
                            Free
                          </Badge>
                        )}
                      </Td>
                      <Td verticalAlign="middle">{subscription.users.length}</Td>
                      <Td verticalAlign="middle">{subscription.default ? 'Yes' : 'No'}</Td>
                      <Td p={2} verticalAlign="middle">
                        <TableActions
                          item={subscription}
                          path="subscription"
                          activeId={activeId}
                          deleteItem={deleteItem}
                          deleteLoading={deleteLoading}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ),
      })}
    </>
  );
}

export default SubscriptionLister;
