import { Th, Thead, Tr } from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

interface Props {
  cols: {
    label: string;
    sort?: string;
    isNumeric?: boolean;
  }[];
  sort?: {
    property: string;
    order: boolean;
  };
  changeSort?(val: string | undefined): void;
}

function TableHeader({ cols, sort, changeSort }: Props) {
  return (
    <Thead>
      <Tr>
        {cols.map((el) => (
          <Th isNumeric={el.isNumeric} key={el.label}>
            {el.sort ? (
              <button
                className="talbe-btn"
                type="button"
                onClick={() => {
                  if (!changeSort) return;
                  changeSort(el.sort);
                }}
              >
                {el.label}
                {sort?.property === el.sort ? (
                  sort?.order ? (
                    <FiChevronUp />
                  ) : (
                    <FiChevronDown />
                  )
                ) : null}
              </button>
            ) : (
              el.label
            )}
          </Th>
        ))}
      </Tr>
    </Thead>
  );
}

export default TableHeader;
