/* eslint-disable react/jsx-props-no-spreading */
import { Alert, AlertIcon, AlertTitle, Box, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';

import useFetch from '../../core/hooks/useFetch';
import RHForm from '../RHForm/RHForm';

const PAGE_URL = `${process.env.REACT_APP_API_URL}/page?slug=world-general-data&world_page=true`;

function WorldGeneralDataForm() {
  const { result, doFetch, loading, error } = useFetch(`${PAGE_URL}`);

  const FORM = useMemo(
    () => ({
      endpoint: 'page',
      id: result?.data._id,
      backPath: '/website',
      schema: [
        {
          name: 'body',
          type: 'object',
          schema: [
            {
              name: 'logo.img',
              type: 'img',
              label: 'Logo',
            },
            {
              name: 'navigation',
              type: 'object',
              label: 'Navigation',
              schema: [
                {
                  name: 'navItems',
                  type: 'array',
                  label: 'Navigation items',
                  schema: [
                    {
                      name: 'label',
                      type: 'string',
                      label: 'Label',
                      required: true,
                    },
                    {
                      name: 'href',
                      type: 'string',
                      label: 'Page',
                      required: true,
                    },
                  ],
                },
              ],
            },
            {
              name: 'img',
              label: 'Page Background',
              type: 'img',
            },
            {
              name: 'footer',
              type: 'object',
              label: 'Footer',
              schema: [
                {
                  name: 'newsletter',
                  type: 'object',
                  label: 'Newsletter form',
                  schema: [
                    {
                      name: 'portalId',
                      type: 'string',
                      label: 'Portal ID',
                    },
                    {
                      name: 'formId',
                      type: 'string',
                      label: 'Form ID',
                    },
                    {
                      name: 'region',
                      type: 'string',
                      label: 'Region',
                    },
                    {
                      name: 'id',
                      type: 'string',
                      label: 'ID',
                    },
                  ],
                },
                {
                  name: 'navigation',
                  type: 'array',
                  label: 'Navigation',
                  schema: [
                    {
                      name: 'label',
                      type: 'string',
                      label: 'Label',
                      required: true,
                    },
                    {
                      name: 'href',
                      type: 'string',
                      label: 'Page',
                      required: true,
                    },
                  ],
                },
              ],
            },
            {
              name: 'legal',
              type: 'object',
              label: 'Legal',
              schema: [
                {
                  name: 'termsAndConditions',
                  type: 'textarea',
                  label: 'Terms & conditions',
                  required: true,
                  rows: 20,
                },
                {
                  name: 'termsAndConditionsSeo',
                  type: 'object',
                  label: 'Terms & conditions SEO',
                  schema: [
                    {
                      name: 'title',
                      type: 'string',
                      label: 'Title',
                    },
                    {
                      name: 'description',
                      type: 'textarea',
                      label: 'Description',
                    },
                  ],
                },
                {
                  name: 'privacyPolicy',
                  type: 'textarea',
                  label: 'Privacy policy',
                  required: true,
                  rows: 20,
                },
                {
                  name: 'privacyPolicySeo',
                  type: 'object',
                  label: 'Privacy policy SEO',
                  schema: [
                    {
                      name: 'title',
                      type: 'string',
                      label: 'Title',
                    },
                    {
                      name: 'description',
                      type: 'textarea',
                      label: 'Description',
                    },
                  ],
                },
              ],
            },
            {
              name: 'redirects',
              type: 'array',
              label: 'Redirects',
              schema: [
                {
                  name: 'newURL',
                  type: 'string',
                  label: 'New URL',
                },
                {
                  name: 'oldURL',
                  type: 'string',
                  label: 'Old URL',
                },
                {
                  name: 'statusCode',
                  type: 'select',
                  label: 'Status code',
                  options: [
                    {
                      label: '301',
                      value: '301',
                    },
                    {
                      label: '302',
                      value: '302',
                    },
                    {
                      label: '307',
                      value: '307',
                    },
                    {
                      label: '308',
                      value: '308',
                    },
                  ],
                },
              ],
            },
            {
              name: 'seo',
              type: 'object',
              label: 'Default SEO',
              schema: [
                {
                  name: 'title',
                  type: 'string',
                  label: 'Title',
                },
                {
                  name: 'description',
                  type: 'textarea',
                  label: 'Description',
                },
                {
                  name: 'image_id',
                  type: 'img',
                  label: 'Image',
                },
              ],
            },
          ],
        },
      ],
    }),
    [result],
  );

  useEffect(() => {
    doFetch();
  }, []);

  return (
    <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
      {!loading ? null : <Spinner />}
      {!error ? null : (
        <Alert status="error" my={10}>
          <AlertIcon />
          <AlertTitle>Could not load world general data</AlertTitle>
        </Alert>
      )}
      {!result ? null : (
        <RHForm
          itemValues={{ body: result.data?.body }}
          updated={result.data?.updated}
          fetchItem={doFetch}
          noDelete
          {...FORM}
        />
      )}
    </Box>
  );
}

export default WorldGeneralDataForm;
