/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, Box, Button, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RHForm from '../../Components/RHForm/RHForm';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';

const FORM = {
  endpoint: 'user_circle',
  itemPath: 'user_circle',
  backPath: '/content?tab=4',
  schema: [
    {
      name: 'name',
      type: 'string',
      label: 'Name',
      required: true,
    },
    {
      name: 'sequenceSlug',
      type: 'ref',
      entity: 'sequence_all',
      label: 'Sequence slug',
    },
    {
      name: 'tagLine',
      type: 'string',
      label: 'Tag line',
    },
    {
      name: 'startTime',
      type: 'date',
      label: 'Start time',
    },
    {
      name: 'visible',
      type: 'boolean',
      label: 'Visible',
    },
    {
      name: 'isOpen',
      type: 'boolean',
      label: 'Is open?',
    },
    {
      name: 'hosts',
      type: 'array',
      label: 'Hosts',
      schema: [
        {
          name: '',
          type: 'ref',
          entity: 'worldUser',
          label: 'User',
          required: true,
        },
      ],
    },
  ],
};

const CIRCLE_URL = `${process.env.REACT_APP_API_URL}/user_circle`;

function UserCircle() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUserContext();
  const { result, doFetch, loading, error } = useFetch(`${CIRCLE_URL}/${id}`);
  const circle = useMemo(() => result?.data, [result]);

  useEffect(() => {
    if (id === 'new') return;
    doFetch({
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
  }, [id]);

  const values = useMemo(() => {
    if (result) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { _id, ...data } = result.data;
      return data;
    }
    return {};
  }, [result]);

  return (
    <section>
      <Button variant="link" onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon />} p={5}>
        Back
      </Button>
      <Box p={5}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" my={10}>
            <AlertIcon />
            <AlertTitle>Could not load the circle with id: {id}</AlertTitle>
          </Alert>
        ) : !circle ? (
          <>
            <h1 className="h3">Create new circle</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm {...FORM} />
            </Box>
          </>
        ) : (
          <>
            <h1 className="h3">{circle.name}</h1>
            <Box bg="white" p={5} my={10} borderRadius={6} boxShadow="lg" flex="1 1 auto">
              <RHForm itemValues={values} id={circle._id} fetchItem={doFetch} {...FORM} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}

export default UserCircle;
