/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable import/no-cycle */
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Heading,
  IconButton,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import {
  Control,
  FormState,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
} from 'react-hook-form';
import { FiArrowDown, FiArrowUp, FiEye, FiEyeOff } from 'react-icons/fi';

import ArrayField from './ArrayField';
import Field from './Field';

interface IProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  unregister: UseFormUnregister<any>;
  field: any;
  getFieldState: UseFormGetFieldState<any>;
  getValues: UseFormGetValues<any>;
  formState: FormState<any>;
  setValue: UseFormSetValue<any>;
  path?: string;
  index: number;
  move: any;
  remove: any;
  item: any;
  fields: any;
  trigger: any;
}

function ComponentItem({
  item,
  index,
  fields,
  field,
  move,
  remove,
  control,
  register,
  unregister,
  formState,
  setValue,
  getFieldState,
  getValues,
  path,
  trigger,
}: IProps) {
  const [isHidden, setIsHidden] = useState(getValues(`${path}.${index}.hidden`));

  const toggleHide = useCallback(
    (idx: number) => {
      setIsHidden(!getValues(`${path}.${idx}.hidden`));
      setValue(`${path}.${idx}.hidden`, !getValues(`${path}.${idx}.hidden`), {
        shouldDirty: true,
      });
    },
    [path],
  );
  return (
    <li style={{ marginBottom: index < fields.length - 1 ? '20px' : '' }}>
      <Accordion allowToggle opacity={isHidden ? 0.5 : 1}>
        <AccordionItem border="1px solid lightgray" p={2}>
          <Flex width="100%" alignItems="center">
            <AccordionButton>
              <Heading fontSize={20} noOfLines={1}>
                {item.component?.toUpperCase()}
                <span style={{ opacity: 0.5, fontSize: '0.8em' }}>
                  {item.title ? ` - ${item.title}` : null}
                </span>
              </Heading>
              <AccordionIcon ml="auto" />
            </AccordionButton>
            <IconButton
              colorScheme="facebook"
              size="sm"
              fontSize={20}
              ml={3}
              aria-label="Move down"
              icon={isHidden ? <FiEye /> : <FiEyeOff />}
              onClick={() => toggleHide(index)}
            />
            <IconButton
              colorScheme="facebook"
              size="sm"
              fontSize={20}
              ml={3}
              aria-label="Move down"
              icon={<FiArrowDown />}
              onClick={() => move(index, index + 1)}
              isDisabled={index >= fields.length - 1}
            />
            <IconButton
              colorScheme="facebook"
              size="sm"
              fontSize={20}
              ml={3}
              aria-label="Move up"
              icon={<FiArrowUp />}
              onClick={() => move(index, index - 1)}
              isDisabled={index <= 0}
            />
            <IconButton
              colorScheme="red"
              size="sm"
              ml={3}
              aria-label="Delete"
              icon={<DeleteIcon />}
              onClick={() => remove(index)}
            />
          </Flex>
          <AccordionPanel mt={5}>
            {field.components
              .find((el: any) => el.name === item.component)
              ?.schema.map((innerField: any) => {
                const newPath = `${path}.${index}.`;
                if (innerField.type === 'array')
                  return (
                    <ArrayField
                      key={innerField.name}
                      {...{
                        control,
                        register,
                        unregister,
                        formState,
                        setValue,
                        field: innerField,
                        getFieldState,
                        getValues,
                        path: newPath,
                        trigger,
                      }}
                    />
                  );
                return (
                  <Field
                    key={innerField.name}
                    setValue={setValue}
                    {...{
                      control,
                      register,
                      unregister,
                      field: innerField,
                      getFieldState,
                      getValues,
                      formState,
                      path: newPath,
                      trigger,
                    }}
                  />
                );
              })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </li>
  );
}

export default ComponentItem;
