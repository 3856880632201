import AboutUsPageForm from '../../Components/AboutUsPageForm/AboutUsPageForm';
import ArticlePageForm from '../../Components/ArticlePageForm/ArticlePageForm';
import GeneralDataForm from '../../Components/GeneralDataForm/GeneralDataForm';
import HomePageForm from '../../Components/HomePageForm/HomePageForm';
import PageLister from '../../Components/PageLister/PageLister';
import ItemTabs from '../../common/ItemTabs/ItemTabs';

const TABS = [
  { label: 'General data', component: <GeneralDataForm /> },
  { label: 'Home page', component: <HomePageForm /> },
  { label: 'Blog', component: <ArticlePageForm /> },
  { label: 'About Us', component: <AboutUsPageForm /> },
  { label: 'Page', component: <PageLister /> },
];

function Website() {
  return (
    <section>
      <ItemTabs path="website" tabs={TABS} />
    </section>
  );
}

export default Website;
